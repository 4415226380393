import { Box, Typography } from "@mui/material";
import greenGrass from "../assets/images/stadium-background.webp";


export default function StoryPage() {
  const descriptionStyles = {
    color: "var(--color-text)",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "170%",
    letterSpacing: "-0.266px",
    textAlign: "left",
  };

  return (
    <>
    <Box sx={{ minHeight: "calc(100lvh - 64px)" }}>
      <Box
        sx={{
          height: "40vh",
          background: `URL(${greenGrass}) center center/cover no-repeat`,
          minWidth: "calc(100vw - 33px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "var(--color-white)",
            fontSize: { xs: "3.2rem", lg: "5rem" },
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "150%",
            letterSpacing: "-1.52px",
            textAlign: "center",
          }}
          variant="h1"
        >
          OUR STORY
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", lg: "68%" },
          margin: "auto",
          textAlign: "center",
          padding: "2rem 0",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          px: 3,
          maxWidth: 1400,
        }}
      >
        <Typography
          sx={{
            color: "var(--color-black)",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "150%",
            letterSpacing: "-0.87px",
            textAlign: "center",
          }}
          variant="h1"
        >
          Our Roots
        </Typography>
        <Typography sx={descriptionStyles} variant="body1">
          Born and bred with a love for sports, competition is ingrained in our
          DNA. Growing up, sports were more than just games; they were a way of
          life. From battles in the backyard to playing in front of thousands of
          fans, we’ve never stopped chasing the thrill of victory. While our
          playing days look drastically different now, our passion for sports
          (as competitors and fans) still remains.
        </Typography>

        <Typography sx={descriptionStyles} variant="body1">
          We began hosting classic sports pools and soon became inspired to take
          it to the next level by creating new pools and unique gaming
          experiences. What began as competition among our circle of friends
          quickly grew into a mission to share that same excitement and
          camaraderie with others.
        </Typography>

        <Typography
          sx={{
            color: "var(--color-black)",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "150%",
            letterSpacing: "-0.87px",
            textAlign: "center",
          }}
          variant="h1"
        >
          Our Vision
        </Typography>

        <Typography sx={descriptionStyles} variant="body1">
          While the sports gambling industry continues to evolve, sports pools
          have remained rather static. We are here to change that and
          revolutionize the way people engage with sports by offering brand new
          and worthwhile games along with new and innovative formats. We aim to
          create a vibrant community where sports enthusiasts can come together
          to compete and forge lasting connections.
        </Typography>
        <Typography sx={descriptionStyles} variant="body1">
          Whether you're a die-hard or a casual sports fan, we're here to
          elevate and enhance your sports experience. As a young but growing
          entity, we're committed to continuous growth and improvement. We
          welcome your feedback, criticism, and pool ideas, as we aim to
          collaboratively build an extraordinary platform.
        </Typography>
        <Typography
          sx={{
            color: "var(--color-black)",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "150%",
            letterSpacing: "-0.87px",
            textAlign: "center",
          }}
          variant="h1"
        >
          Our Goals
        </Typography>
        <Box
          display="flex"
          sx={{
            flexDirection: { xs: "column", lg: "row" }, // Change flex direction to column for xs (mobile) size
          }}
          p={1}
          m={1}
          justifyContent="space-around"
          alignItems="stretch"
          gap="10px"
        >
          <Box
            p={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{
              color: "#002129",
              borderRadius: "var(--1, 8px)",
              background: "#F0EEEE",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",

              maxWidth: 365,
              padding: "32px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
              }}
            >
              Connect family, friends, and fans around the world, through sports
              and competition.
            </Typography>
          </Box>
          <Box
            p={1}
            sx={{
              maxWidth: 365,
              padding: "32px",
              color: " #002129",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "140%" /* 33.6px */,
              letterSpacing: "-0.456px",
              borderRadius: "var(--1, 8px)",
              background: "#F0EEEE",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",
            }}
            className="about-boxes"
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
              }}
            >
              Elevate your sport’s experiences and excitement by giving you
              actual skin in the game.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    </>
  );
}