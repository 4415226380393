import React, { useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import StaticPoolHeaderBar from "../PoolHeaderBar/StaticPoolHeaderBar";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateToTop from "../../hooks/general/useNavigateToTop"; // Import the navigation hook

const PaymentSuccess = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop(); // Initialize the navigation hook


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <StaticPoolHeaderBar navigationText="Home" headerText="Merchandise" linkForNavigation="/" />

            <img
                src="https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyGamingLogoWide.png"
                alt="Pool Party Gaming"
                style={{
                    width: isMobile ? "300px" : "40%",
                    height: "auto",
                    padding: "32px 0px",
                }}
            />
             <h1>Payment Successful!</h1>
             <p style={{marginBottom:25}}>Thank you for your purchase. Your payment was successful.</p>
             <Button variant="contained" onClick={()=>navigateToTop('/merch')}>Continue Shopping</Button>
        </Box>
    );
}

export default PaymentSuccess;