import React, { useState, useEffect, useMemo } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, Box, TablePagination } from '@mui/material';
import './SuperPickemLeaderboard.scss';
import { amITied } from "../utils/sharedUtils";

const PickemLeaderboard = ({
    leaderboard,
    myUserId,
    hasBracketDeadlinePassed,
    usersInfo,
    truncateUsername,
    canShowPickemRanks,
    pickemRankPlaceholder,
    hostIds,
    hostBackgroundColor,
    hostFilter,
    barBattleParticipants,
    getBarBattleLogo,
    hasFinalStarted,
    isGameOver,
}) => {
    
    const [sortConfig, setSortConfig] = useState({
        key: 'dailyPicksRank', // Default sort by overallRank
        direction: 'asc'
    });

    // Pagination State
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    // Pagination Handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Use Effect to reset page to 0 if host filter changes
    useEffect(() => {
        //console.log("Resetting page to 0");
        setPage(0);
    }, [hostFilter, leaderboard, rowsPerPage]);

    const sortedData = useMemo(() => {
        if (!leaderboard) return [];
    
        return [...leaderboard].sort((a, b) => {
            const rankColumns = ['overallRank', 'bracketRank', 'dailyPicksRank'];
            const pointsColumns = ['totalPoints', 'bracketPoints', 'dailyPicksPoints', 'maxBracketPoints', 'spreadWins', 'overUnderWins'];
    
            const key = sortConfig.key; // Current column being sorted
            const direction = sortConfig.direction; // Sort direction: 'asc' or 'desc'
    
            // Rank Columns Logic
            if (rankColumns.includes(key)) {
                const aValue = a[key];
                const bValue = b[key];
                const aIsMissing = aValue == null; // Check for null or undefined
                const bIsMissing = bValue == null;
    
                if (aIsMissing && bIsMissing) return 0; // Both missing are equal
                if (aIsMissing) return direction === 'asc' ? 1 : -1; // Missing ranks go to bottom in ascending, top in descending
                if (bIsMissing) return direction === 'asc' ? -1 : 1; // Reverse for descending
                return direction === 'asc' ? aValue - bValue : bValue - aValue; // Normal numeric sorting
            }
    
            // Points Columns Logic
            if (pointsColumns.includes(key)) {
                const aValue = a[key];
                const bValue = b[key];
                const aIsMissing = aValue == null; // Check for null or undefined
                const bIsMissing = bValue == null;
    
                if (aIsMissing && bIsMissing) return 0; // Both missing are equal
                if (aIsMissing) return direction === 'desc' ? 1 : -1; // Missing points go to bottom in descending, top in ascending
                if (bIsMissing) return direction === 'desc' ? -1 : 1; // Reverse for ascending
                return direction === 'asc' ? aValue - bValue : bValue - aValue; // Normal numeric sorting
            }
    
            // Fallback for String Columns
            const aValue = a[key];
            const bValue = b[key];
            const aIsMissing = aValue == null || aValue === ''; // Missing or empty
            const bIsMissing = bValue == null || bValue === '';
    
            if (aIsMissing && bIsMissing) return 0; // Both missing are equal
            if (aIsMissing) return direction === 'asc' ? 1 : -1; // Missing goes to bottom in ascending
            if (bIsMissing) return direction === 'asc' ? -1 : 1; // Reverse for descending
            return direction === 'asc'
                ? (aValue || '').localeCompare(bValue || '')
                : (bValue || '').localeCompare(aValue || '');
        });
    }, [leaderboard, sortConfig]);

    const handleSort = (key) => {
        setPage(0); // Reset page to 0 when sorting
        setSortConfig(prevConfig => {
            // If sorting by the same column, toggle direction
            if (prevConfig.key === key) {
                return {
                    key,
                    direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
                };
            }
            
            if (key === 'dailyPicksRank') {
                return {
                    key,
                    direction: 'asc'
                };
            }

            // If sorting by a new column, default to ascending
            return {
                key,
                direction: 'desc'
            };
        });
    };

    const rankHeaderColor = sortConfig.key === 'dailyPicksRank' ? '#FFC60A' : '#FFFFFF';
    const pickemPointsHeaderColor = sortConfig.key === 'dailyPicksPoints' ? '#FFC60A' : '#FFFFFF';
    const spreadRecordHeaderColor = sortConfig.key === 'spreadWins' ? '#FFC60A' : '#FFFFFF';
    const overUnderRecordHeaderColor = sortConfig.key === 'overUnderWins' ? '#FFC60A' : '#FFFFFF';
    const playerHeaderColor = sortConfig.key === 'username' ? '#FFC60A' : '#FFFFFF';
    

    // Slice the sorted data for pagination
    const paginatedData = sortedData?.slice(
        page * rowsPerPage, 
        page * rowsPerPage + rowsPerPage
    );

    return (
        <>
        <Grid item xs={12} sx={{overflow: 'scroll'}}>
            <Box
                sx={{
                    maxWidth: '900px',
                    margin: '0 auto',
                }}
            >
                <TablePagination
                    rowsPerPageOptions={[50, 100]}
                    component="div"
                    count={sortedData?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={Math.min(page, Math.max(0, Math.ceil((leaderboard?.length || 0) / rowsPerPage) - 1))} // Derive a valid page value
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Rows"
                    sx={{
                        backgroundColor: 'white',
                        '& .MuiTablePagination-toolbar': {
                            color: '#002129',
                        },
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                            color: '#002129',
                        }
                    }}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table className="super-pickem-leaderboard-table">
                <TableHead sx={{
                        background: '#002129',
                        color:'#fff'
                    }}>


                {usersInfo ?
                    <TableRow sx={{ background: '#F0F1F1', color: '#002129' }}>
                        <TableCell 
                            sx={{ 
                                textAlign: 'center', 
                                fontSize: '12px', 
                                position: 'sticky', 
                                left: 0, 
                                zIndex: 100, 
                                backgroundColor: '#F0F1F1' // Ensure the sticky cell has a background
                            }}
                        >
                            {!canShowPickemRanks ? (
                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                    T1
                                </Typography>
                            ) : !usersInfo?.dailyPicksRank ? (
                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                    {pickemRankPlaceholder}
                                </Typography>
                            ) :
                            amITied(usersInfo?.dailyPicksRank, 'dailyPicksRanks', leaderboard) ? (
                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                    T{usersInfo?.dailyPicksRank}
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                    {usersInfo?.dailyPicksRank}
                                </Typography>
                            )}
                        </TableCell>

                        <TableCell 
                            sx={{ 
                                textAlign: 'left', 
                                fontSize: '12px', 
                                position: 'sticky', 
                                left: '75px', // Adjust based on the width of the previous sticky cell
                                zIndex: 100, 
                                backgroundColor: '#F0F1F1'// Ensure the sticky cell has a background
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column', // Stack rows vertically
                                    alignItems: 'flex-start', // Align rows to the left
                                }}
                            >
                                {/* Row 1: Username and Bar Logo */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row', // Keep username and logo together
                                        alignItems: 'center',
                                    }}
                                >
                                    {truncateUsername(usersInfo?.username)} <br />
                                    {usersInfo?.barId && barBattleParticipants && (
                                        <img
                                            src={getBarBattleLogo(usersInfo?.barId, barBattleParticipants)}
                                            alt="Bar Logo"
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                marginLeft: '5px',
                                            }}
                                        />
                                    )}
                                </Box>

                                {/* Row 2: Tiebreaker Points */}
                                {hasFinalStarted && (
                                    <Typography
                                        sx={{
                                            fontSize: '10px', // Adjust font size for this row
                                            fontWeight: 500,
                                            color: '#002129',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Tiebreaker Pts: {usersInfo?.tiebreakerPoints || 0}
                                    </Typography>
                                )}

                                {/* Row 3: Margin */}
                                {isGameOver && (
                                    <Typography
                                        sx={{
                                            fontSize: '10px', // Adjust font size for this row
                                            fontWeight: 500,
                                            color: '#002129',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Margin: {usersInfo?.tiebreakerMargin && usersInfo?.tiebreakerMargin !== 5000 ? usersInfo?.tiebreakerMargin : 'N/A'}
                                    </Typography>
                                )}
                            </Box>
                        </TableCell>

                        <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{sm:"45%", md:"25%", lg:"25%"} }}>
                            {usersInfo?.dailyPicksPoints || 0}
                        </TableCell>
                        
                        <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"} }}>
                            {usersInfo?.spreadWins || 0} - {usersInfo?.spreadLosses || 0} - {usersInfo?.spreadPushes || 0}
                        </TableCell>
                        
                        <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"}}}>
                            {usersInfo?.overUnderWins || 0} - {usersInfo?.overUnderLosses || 0} - {usersInfo?.overUnderPushes || 0}
                        </TableCell>
                    </TableRow>
                    :
                    <></>
                  }

                    <TableRow 
                        sx={{
                            color:'#fff'
                        }}>
                                <TableCell
                                    onClick={() => handleSort('dailyPicksRank')}
                                    sx={{
                                        color: rankHeaderColor,
                                        fontWeight: 900,
                                        textAlign: 'center',
                                        // width: "30px",
                                        textDecoration: 'underline',
                                        position: 'sticky',
                                        backgroundColor: '#002129',
                                        zIndex: 100,
                                        left: 0,
                                    }}
                                >
                                    RANK
                                </TableCell>

                                <TableCell 
                                    onClick={() => handleSort('username')}
                                    sx={{ 
                                        color: playerHeaderColor, 
                                        fontWeight: 900, 
                                        textAlign: 'left',
                                        textDecoration: 'underline',
                                        position: 'sticky',
                                        backgroundColor: '#002129',
                                        zIndex: 100,
                                        left: '74px', // Adjust based on the width of the previous sticky cell
                                    }}
                                >
                                    PLAYER
                                </TableCell>
                                
                                <TableCell
                                    onClick={() => handleSort('dailyPicksPoints')}
                                    sx={{
                                        color: pickemPointsHeaderColor,
                                        fontWeight: 900,
                                        textAlign: 'center',
                                        minWidth: '100px',
                                        position: 'relative',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    PICK 'EM PTS
                                </TableCell>

                                <TableCell
                                    onClick={() => handleSort('spreadWins')}
                                    sx={{
                                        color: spreadRecordHeaderColor,
                                        fontWeight: 900,
                                        textAlign: 'center',
                                        position: 'relative',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    ATS RECORD
                                </TableCell>

                                <TableCell
                                    onClick={() => handleSort('overUnderWins')} 
                                    sx={{ 
                                        color: overUnderRecordHeaderColor, 
                                        fontWeight: 900, 
                                        textAlign: 'center', 
                                        position: 'relative',
                                        textDecoration: 'underline',
                                        minWidth: '100px',
                                    }}
                                >
                                   O/U RECORD
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData?.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: 
                                            hostIds?.includes(row?.userId) 
                                                ? hostBackgroundColor 
                                                : row?.userId === myUserId 
                                                    ? '#FFF6DA' 
                                                    : index % 2 === 0 
                                                        ? '#FFF' 
                                                        : '#F0F1F1',
                                    }}
                                > 
                                    <TableCell 
                                        sx={{ 
                                            textAlign: 'center', 
                                            fontSize: '12px',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 100,
                                            backgroundColor: hostIds?.includes(row?.userId) ? hostBackgroundColor : row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                        }}
                                    >
                                        {!canShowPickemRanks ? (
                                            "T1"
                                        ) : !row?.dailyPicksRank ? (
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                {pickemRankPlaceholder}
                                            </Typography>
                                        ) :
                                        amITied(row?.dailyPicksRank, 'dailyPicksRank', leaderboard) ? (
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                T{row?.dailyPicksRank}
                                            </Typography>
                                        ) : (
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                {row?.dailyPicksRank}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    
                                    <TableCell 
                                        sx={{
                                            textAlign:'left', 
                                            fontSize:'12px',
                                            position: 'sticky',
                                            left: '75px',
                                            zIndex: 100,
                                            backgroundColor: hostIds?.includes(row?.userId) ? hostBackgroundColor : row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {truncateUsername(row?.username)} <br />
                                                    {row?.barId && barBattleParticipants && (
                                                        <img
                                                            src={getBarBattleLogo(row?.barId, barBattleParticipants)}
                                                            alt="Bar Logo"
                                                            style={{
                                                                width: '24px',
                                                                height: '24px',
                                                                marginLeft: '5px',
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                                {hasFinalStarted && (
                                                    <Typography
                                                        sx={{
                                                            fontSize: '10px',
                                                            fontWeight: 500,
                                                            color: '#002129',
                                                            textDecoration: 'none',
                                                        }}
                                                    >
                                                        Tiebreaker Pts: {row?.tiebreakerPoints || 0}
                                                    </Typography>
                                                )}
                                                {isGameOver && (
                                                    <Typography
                                                        sx={{
                                                            fontSize: '10px',
                                                            fontWeight: 500,
                                                            color: '#002129',
                                                            textDecoration: 'none',
                                                        }}
                                                    >
                                                        Margin: {row?.tiebreakerMargin && row?.tiebreakerMargin !== 5000 ? row?.tiebreakerMargin : 'N/A'}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    
                                    <TableCell sx={{textAlign:'center', fontSize:'12px',}}>{row?.dailyPicksPoints || 0}</TableCell>
                                    
                                    <TableCell sx={{ textAlign: 'center', fontSize: '12px'}}>
                                        {row?.spreadWins || 0} - {row?.spreadLosses || 0} - {row?.spreadPushes || 0}
                                    </TableCell>
                                    
                                    <TableCell sx={{ textAlign: 'center', fontSize: '12px' }}>
                                        {row?.overUnderWins || 0} - {row?.overUnderLosses || 0} - {row?.overUnderPushes || 0}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                    <Box
                        sx={{
                            maxWidth: '900px',
                            margin: '0 auto',
                        }}
                    >
                        <TablePagination
                            rowsPerPageOptions={[50, 100]}
                            component="div"
                            count={sortedData?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={Math.min(page, Math.max(0, Math.ceil((leaderboard?.length || 0) / rowsPerPage) - 1))} // Derive a valid page value
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Rows"
                            sx={{
                                backgroundColor: 'white',
                                '& .MuiTablePagination-toolbar': {
                                    color: '#002129',
                                },
                                '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                                    color: '#002129',
                                }
                            }}
                        />
                    </Box>
            </Grid>
</>

    )
}

export default PickemLeaderboard;