import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';

const MyBracketModal = ({ showBracketModal, setShowBracketModal }) => {

    const handleClose = () => {
        setShowBracketModal(false);
    }

    return (
        <Modal
            open={showBracketModal}
            onClose={null}
            disableEscapeKeyDown
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '335px',
                    maxHeight: '765px',
                    bgcolor: '#FFF',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    overflow: 'scroll'
                }}
            >
                <Typography
                    sx={{
                        color: '#002129',
                        fontSize: '20px',
                        fontWeight: '700',
                    }}
                >
                    Full Bracket is Coming!
                </Typography>
                <Typography
                    sx={{
                        color: '#002129',
                        fontSize: '16px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginBottom: '8px',
                    }}
                >
                    Bracket selections begin in the Divisional Round (Round 2), due to the NFL re-seeding after Wild Card Weekend (Round 1).
                    <br /> <br />The bracket will be set following Round 1.
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );
}

export default MyBracketModal;
