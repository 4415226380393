import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import '../CardPieces/CardPieces.scss';
import PickStatsChoicesCard from "./PickStatsChoicesCard";


const PickStatsCard = ({ userPicksFromDB, pickState, setPickState, prop, interval, selectedGameId, hasGameStarted, isGameFinal, displayIndex }) => {
    //console.log("INTERVAL: ", interval);
    
    const propText = prop?.propText;
    const actualTotal = prop?.actualTotal;
    const correctOption = prop?.correctOption;
    const points = prop?.points;
    const correctOptions = prop?.correctOptions;
    //console.log("POINTS: ", points);
    
    return (
        <Box className="quick-pick-card-container">
            <Box className="quick-pick-card-top-border" sx={{ backgroundColor: correctOption === 'Void' ? '#000000' : ''}}>
                <Typography className="quick-pick-card-top-border-left-text" sx={{ color: correctOption === 'Void' ? '#fff' : ''}}>
                    Prop #{displayIndex}
                </Typography>
                <Typography className="quick-pick-card-top-border-right-text" sx={{ color: correctOption === 'Void' ? '#fff' : '' }}>
                    {correctOption === 'Void' ? 'VOID' : `${points} Point${points > 1 ? 's' : ''}`}
                </Typography>
            </Box>
            <Box className="quick-pick-card-info">
                <Typography className="quick-pick-card-info-text">
                    {propText}
                </Typography>
            </Box>
            
            <PickStatsChoicesCard 
                hasGameStarted={hasGameStarted}
                isGameFinal={isGameFinal}
                prop={prop}
                selectedGameId={selectedGameId}
                correctOption={correctOption}
                correctOptions={correctOptions}
            />
            
            
            <Box className="quick-pick-card-bottom-border">
                <Typography className="quick-pick-card-bottom-border-text">
                    Result: 
                </Typography>
                <Typography className="quick-pick-card-bottom-border-text">
                    {actualTotal ? actualTotal : correctOption === 'Multiple Answers' ? 'Tie' : correctOption ? correctOption : 'Pending'}
                </Typography>
            </Box>
        </Box>
    )
}

export default PickStatsCard;