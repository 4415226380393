import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const PickEmSubmitPicksModal = ({ 
    modalOpen, 
    setModalOpen, 
    checkerData, 
    roundName, 
    logo, 
    teamsOrNames, 
    schedule,
    sponsorSecondaryLogo,
    hideExtraInfo,
    hideSponsorInPoolHall,
    //gatherPickInfo, 
}) => { 
    //console.log("Teams/NAMES: ", teamsOrNames, "Schedule: ", schedule);
    const findTeamName = (teamId, teamsOrNames) => {
        //console.log("Team ID: ", teamId);
        return teamsOrNames?.find(team => team.GlobalTeamID === teamId)?.ShortDisplayName;
    }

    const findMatchupNames = (gameId, teamsOrNames, schedule) => {
        const game = schedule?.find(game => game.GlobalGameID === gameId);
        const homeTeam = findTeamName(game?.GlobalHomeTeamID, teamsOrNames);
        const awayTeam = findTeamName(game?.GlobalAwayTeamID, teamsOrNames);
        return `${awayTeam} at ${homeTeam}`;
    }
    
    const gatherPickInfo = (pick, teamsOrNames, schedule) => {
        const gameId = pick.gameId;
        const pickType = pick.pickType;
        const line = pick.line;
        //console.log("PICK TYPE: ", pickType);
        let display = '';
        const pickMade = pick.pick;
        if (pickType === 'Spread') {
            const teamName = findTeamName(pickMade, teamsOrNames);
            display = `${teamName} (${line > 0 ? `+${line}` : line === 0 ? 'PK' : line})`;
            //console.log("SPREAD PICK: ", pickMade);
        } else if (pickType === 'Total') {
            //console.log("PICK MADE: ", pickMade);
            const teamsDisplay = findMatchupNames(gameId, teamsOrNames, schedule);
            const overUnderDisplay = pickMade === 'Over' ? `O ${line}` : `U ${line}`;
            display = `${teamsDisplay} (${overUnderDisplay})`;
            //console.log("TOTAL PICK: ", pickMade);
        }
        return display;
    }
    


    const newPicks = checkerData?.newPicks;
    const picksMadeThisInterval = checkerData?.picksMadeThisInterval;
    const howManyPicksThisInterval = checkerData?.howManyPicksThisInterval;
    const picksSaved = checkerData?.picksToSave;
    const gamesThatStarted = checkerData?.gamesThatStarted || [];   
    const gamesThatChanged = checkerData?.gamesThatChanged || [];
    const restrictedGames = checkerData?.restrictedGames || [];

    const picksCount = (newPicks - (gamesThatChanged?.length + gamesThatStarted?.length + restrictedGames?.length));
    const isNaNCount = isNaN(picksCount); // Check if picksCount is NaN

    //console.log("PICKS SAVED: ", picksSaved);
    // const test = gatherPickInfo(picksSaved[0], teamsOrNames, schedule);
    // console.log("TEST: ", test);
    return(
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <div className="modal-container" style={{ height: 'auto', width: '308px' }}>
            {picksSaved?.length > 0 && (
                <>
                    <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', marginBottom: '10px' }}>
                    {isNaNCount 
                        ? "Picks Submitted" 
                        : `${picksCount} Pick${picksCount > 1 ? 's' : ''} Submitted`}
                    </Typography>
                    <CheckIcon style={{ width: '85px', height: '45px', fill: '#00AA72', margin: '0 auto', marginBottom: '10px' }} />    
                </>
            )}


                {(gamesThatStarted > 0 || gamesThatChanged > 0) && (
                    <div style={{ width: '206px', height: '2px', backgroundColor: '#000000', marginBottom: '10px', }}>
                    </div>
                )}

                {(gamesThatStarted?.length > 0 || gamesThatChanged?.length > 0 || restrictedGames?.length > 0) && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', marginBottom: '10px'}}>
                            {(gamesThatStarted?.length + gamesThatChanged?.length + restrictedGames?.length)} Pick{(gamesThatStarted?.length + gamesThatChanged?.length + restrictedGames?.length) > 1 ? 's' : '' } Not Submitted
                        </Typography>
                        <CloseIcon style={{ width: '85px', height: '45px', fill: '#CC293C', margin: '0 auto', marginBottom: '10px' }} />
                    </>
                )}

                {gamesThatStarted?.length > 0 && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', textDecoration: 'underline', marginBottom: '5px' }}>
                            Game{gamesThatStarted?.length > 1 ? 's' : ''} Started
                        </Typography>
                        {gamesThatStarted?.map((pick, index) => {
                            const pickInfo = gatherPickInfo(pick, teamsOrNames, schedule);
                            return (
                                <Typography key={index} variant="body1" style={{ fontSize: '14px', fontWeight: '800', marginBottom: '5px' }}>
                                    {pickInfo}
                                </Typography>
                            );
                        }
                        )}
                    </>
                )}

                {gamesThatChanged?.length > 0 && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', textDecoration: 'underline', marginBottom: '5px' }}>
                            Line Change{gamesThatChanged?.length > 1 ? 's' : ''}
                        </Typography>
                        {gamesThatChanged?.map((pick, index) => {
                            const pickInfo = gatherPickInfo(pick, teamsOrNames, schedule);
                            return (
                                <Typography key={index} variant="body1" style={{ fontSize: '14px', fontWeight: '800', marginBottom: '5px' }}>
                                    {pickInfo}
                                </Typography>
                            );
                        }
                        )}
                    </>
                )}

                {restrictedGames?.length > 0 && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', textDecoration: 'underline', marginBottom: '5px' }}>
                            Restricted Game{restrictedGames?.length > 1 ? 's' : ''}
                        </Typography>
                        <Typography variant="body1" style={{ fontSize: '14px', fontWeight: '800', marginBottom: '5px' }}>
                            The South can CRAM IT! <br/> Sorry we are not picking this game.
                        </Typography>
                        {restrictedGames?.map((pick, index) => {
                            const pickInfo = gatherPickInfo(pick, teamsOrNames, schedule);
                            return (
                                <Typography key={index} variant="body1" style={{ fontSize: '14px', fontWeight: '800', marginBottom: '5px' }}>
                                    {pickInfo}
                                </Typography>
                            );
                        }
                        )}
                    </>
                )}

                {!hideExtraInfo && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', marginTop: (gamesThatChanged?.length > 0 || gamesThatStarted?.length > 0) ? '10px' : '0px' }}>{roundName}</Typography>
                    
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800' }}>
                            <span style={{ fontWeight: '700', color: picksMadeThisInterval === howManyPicksThisInterval ? '#00AA72' : '#CC293C' }}>
                                {picksMadeThisInterval}/{howManyPicksThisInterval}&nbsp;
                            </span> 
                            Picks Submitted
                        </Typography>
                    </>
                )}

                <img
                    src={logo}
                    alt={'Super Pickem Logo'}
                    style={{
                        height: '100px',
                        marginTop: '20px'
                    }}
                />

                {!hideSponsorInPoolHall && (
                    <img 
                        src={sponsorSecondaryLogo} 
                        alt="logo" 
                        style={{ 
                            width: '80px', 
                            margin: '0 auto', 
                            marginTop: '20px' 
                        }} 
                    />
                )}

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button sx={{ width: '115px'}} variant="contained" onClick={() => window.location.href=window.location.href}>OK</Button>
                </div>
            </div>
        </Modal>
    )
}

export default PickEmSubmitPicksModal;