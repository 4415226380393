import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { apiUrl } from "../../config";

// Function to fetch the products from Stripe
const getProducts = async () => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

  const response = await axios.get(`${apiUrl}/user/products`, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json',
    }
  });

  return response.data;
};

// Custom hook for fetching products
export const useGetProducts = () => {
  return useQuery({
    queryKey: ['products'],  // Use a query key for caching
    queryFn: getProducts,
    onError: (error) => {
      console.error('Error fetching products:', error);
    },
  });
};