import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';

const MyPicksBarSingleGameDisplay = ({ 
    gameNumber,
    roundAbbreviation,
    gameCountThisInterval,
    game,
    userPicksFromDB,
    gatherLogo,
    teams,
 }) => {
    // console.log("My Picks Bar Single Game Display, game: ", game);
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    const gameId = game?.GlobalGameID;
    const gameTime = game?.DateTime;
    const gameTimeInNewYork = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
    const hasGameStarted = timeNowInNewYork > gameTimeInNewYork;
    //console.log("My Picks Bar Has Game Started: ", hasGameStarted);
    const userSpreadPick = userPicksFromDB?.find(pick => pick.gameId === gameId && pick.pickType === 'Spread');
    const userTotalPick = userPicksFromDB?.find(pick => pick.gameId === gameId && pick.pickType === 'Total');
    //console.log("My Picks Bar Single Game Display, user spread pick: ", userSpreadPick, "User total pick: ", userTotalPick);

    const userSpreadTeam = userSpreadPick ? userSpreadPick.pick : null;
    //console.log("My Picks Bar Single Game Display, user spread team: ", userSpreadTeam);

    const spreadLogo = userSpreadPick ?
        gatherLogo(userSpreadPick.pick, teams) :
        hasGameStarted ? 
            <CloseIcon sx={{color: '#CC293C', fontSize: isMobile ? '28px' : '44px'}} /> :
            null;
    const spreadLine = userSpreadPick ?
        userSpreadPick.line > 0 ?
            `+${userSpreadPick.line}` :
            userSpreadPick.line === 0 ?
                'PK' :
                userSpreadPick.line :
        hasGameStarted ?
            "Miss" :    
            "ATS";
    const spreadResult = userSpreadPick ?
        userSpreadPick.result :
        hasGameStarted ?
            "Loss" :
            "Pending";

    const totalChoice = userTotalPick
        ? (isMobile
            ? (userTotalPick.pick === 'Over' ? 'O' : 'U')
            : userTotalPick.pick)
        : (hasGameStarted
            ? <CloseIcon sx={{color: '#CC293C', fontSize: isMobile ? '28px' : '44px'}} />
            : null);
        

    const totalLine = userTotalPick ?
        userTotalPick.line :
        hasGameStarted ? 
            "Miss" :
            "TOT";
    const totalResult = userTotalPick ?
        userTotalPick.result :
        hasGameStarted ?
            "Loss" :
            "Pending";

   
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: (gameCountThisInterval > 4 && isSmallScreen) ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: gameCountThisInterval > 2 ? '8px' : '16px',
                    marginBottom: '8px'
                }}
            >
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: isMobile ? '32px' : '48px',
                            width: isMobile ? '32px' : '48px',
                            borderRadius: '4px',
                            border: spreadResult === 'Win' ? '1px solid #00AA72' : spreadResult === "Loss" ? '1px solid #CC293C' : spreadResult === 'Push' ? '1px solid #5A5B5C' : '1px solid #002129',
                            backgroundColor: spreadResult === 'Win' ? 'rgba(0, 170, 114, 0.2)' : spreadResult === "Loss" ? 'rgba(204, 41, 60, 0.2)' : spreadResult === 'Push' ? 'rgba(90, 91, 92, 0.20)' : 'transparent',
                        }}
                    >
                        {userSpreadPick ?
                            <img src={spreadLogo} alt={userSpreadPick.pick} style={{ width: '100%', height: '100%', objectFit: 'contain' }} /> :
                            spreadLogo
                        }
                    </Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            color: spreadLine === "Miss" ? '#CC293C' : '#002129'
                        }}
                    > 
                        {spreadLine}
                    </Typography>
                </Box>

                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: isMobile ? '32px' : '48px',
                            width: isMobile ? '32px' : '48px',
                            borderRadius: '4px',
                            border: totalResult === 'Win' ? '1px solid #00AA72' : totalResult === "Loss" ? '1px solid #CC293C' : '1px solid #002129',
                            backgroundColor: totalResult === 'Win' ? 'rgba(0, 170, 114, 0.2)' : totalResult === "Loss" ? 'rgba(204, 41, 60, 0.2)' : 'transparent',
                        }}
                    >
                        {totalChoice ?
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '16px' : '14px',
                                    fontWeight: '700',
                                }}
                            >
                                {totalChoice}
                            </Typography>
                            :
                            totalChoice
                        }
                    </Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            color: totalLine === "Miss" ? '#CC293C' : '#002129'
                        }}
                    > 
                        {totalLine}
                    </Typography>
                </Box>
            </Box>
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: '700',
                    color: 'black',
                }}
            >
                {roundAbbreviation} {gameNumber}
            </Typography>
        </Box>
    );
}

export default MyPicksBarSingleGameDisplay;