import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as TikTok } from '../../assets/images/tiktok.svg';

import { SOCIAL_LINKS } from './footer.constants';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import navbarLogo from '../../assets/images/navbar-logo.svg';

export default function Footer() {
  return (
    <Box component={'footer'}
      sx={{
        display: 'flex',
        minHeight: 'var(--footer-height)',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '8px solid var(--secondary-color)',
        gap: '20px',
        width: '100%',
        padding: '16px',
        backgroundColor: 'var(--primary-color)',
      }}
    >
      <Box component={'ul'}
        sx={{
          listStyleType: 'none',
          display: 'flex',
          alignItems: 'flex-start',
          gap: '72px',
          '@media (max-width: 600px)': {
            gap: '25px',
          },
        }}
      >
        {SOCIAL_LINKS.map((socialLink) => (
          <li key={socialLink.id}>
            <Box component='a'
              href={socialLink.link}
              target='_blank'
              sx={{
                textDecoration: 'none',
                color: 'var(--color-white)',
                fontSize: '16px',
                fontStyle: 'normal',
                width:'21px',
                fontWeight: '500',
                lineHeight: 'normal',
              }}
            >
              {socialLink.text === "Twitter / X"? <XIcon/>:<></>}
              {socialLink.text === "Instagram"? <InstagramIcon/>:<></>}
              {socialLink.text === "TikTok"? <div style={{width:'21px'}}><TikTok /></div>
:<></>}
              {/* {socialLink.text} */}
            </Box>
          </li>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '16px',
        }}
      >
          <>
            <a
              href="https://poolpartyfilestorage.blob.core.windows.net/documents/Pool_Party_Terms_of_Service_2024.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Terms of Service
              </Typography>
            </a>

            <a
              href="https://poolpartyfilestorage.blob.core.windows.net/documents/Pool_Party_LLC_Privacy_Policy_2024.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Privacy Policy
              </Typography>
            </a>
        </>
      </Box>
      <a href="https://sportsdata.io" target="_blank" rel="noopener noreferrer">
        <img 
          src="https://sportsdata.io/assets/images/badges/sportsdataio_light_ss_300.png" 
          alt="Powered by SportsDataIO" 
          style={{ maxWidth: '150px', height: 'auto', display: 'block' }}
        />
      </a>
      <Typography
        variant='body1'
        sx={{
          color: 'var(--color-white)',
          fontFamily: 'Inter',
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: '800',
          lineHeight: 'normal',
        }}
      >
        ©Pool Party Gaming, LLC - 2024
      </Typography>
    </Box>
  );
}