import React, { useState, useEffect } from 'react';
import { Typography, Box, MenuItem, Select, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./SuperPickemMyPicksNavBar.scss";
import PicksNotSaved from "../../PicksNotSaved/PicksNotSaved";

const SuperPickemMyPicksNavBar = ({ 
  gameAddress, 
  mainGameId, 
  interval, 
  setInterval, 
  highestInterval, 
  currentInterval, 
  unsavedPicks, 
  userPicksFromDB, 
  setPickState, 
  rounds,
  showMyPicksBar,
}) => {
    //console.log("Interval NAV BAR:", interval, "Rounds:", rounds);
    const lowerBound = 1;
    const upperBound = highestInterval;

    const [picksNotSavedModal, setPicksNotSavedModal] = useState(false);
    const [pendingInterval, setPendingInterval] = useState(null);

    const navigate = useNavigate();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleIntervalSelectChange = (event) => {
        const newInterval = event.target.value;
        if (!unsavedPicks) {
          setInterval(newInterval);
          navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newInterval}`);
        } else {
          setPicksNotSavedModal(true);
          setPendingInterval(newInterval);
        }
    };

    const handleIntervalChange = (direction) => {
        let newInterval = interval;
        if (direction === 'prev') {
            newInterval--;
            if (!unsavedPicks) {
              setInterval(newInterval);
              navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newInterval}`);
            } else {
              setPicksNotSavedModal(true);
              setPendingInterval(newInterval);
            }
        } else {
            newInterval++;
            if (!unsavedPicks) {
              setInterval(newInterval);
              navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newInterval}`);
            } else {
              setPicksNotSavedModal(true);
              setPendingInterval(newInterval);
            }
        }
    }

    return (
      <>
      <Box className={showMyPicksBar ? "super-pickem-my-picks-navigation-flipped" : "super-pickem-my-picks-navigation"}>
        <Box className={`${showMyPicksBar ? "super-pickem-my-picks-navigation-flipped" : "super-pickem-my-picks-navigation"}__content`}>
          <div className={`${showMyPicksBar ? "super-pickem-my-picks-navigation-flipped" : "super-pickem-my-picks-navigation"}__game-date-bar`}>
            <button 
              className={`${showMyPicksBar ? "super-pickem-my-picks-navigation-flipped" : "super-pickem-my-picks-navigation"}__icon`}
              onClick={() => handleIntervalChange('prev')} 
              disabled={interval <= lowerBound}
            >
              <ArrowBackIosNewIcon />
            </button>
            <div className={`${showMyPicksBar ? "super-pickem-my-picks-navigation-flipped" : "super-pickem-my-picks-navigation"}__week-select-container`}>
              <Select
                value={interval}
                onChange={handleIntervalSelectChange}
                variant="outlined"
                className={`${showMyPicksBar ? "super-pickem-my-picks-navigation-flipped" : "super-pickem-my-picks-navigation"}__week-select`}
              >
                {Array.from({ length: upperBound - lowerBound + 1 }, (_, index) => {
                  const intervalNumber = index + lowerBound;
                  const round = rounds.find(round => round.round === intervalNumber);
                  const displayName = round ? round.name : `Week ${intervalNumber}`;
                  return (
                    <MenuItem key={intervalNumber} value={intervalNumber}>
                      {displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <button 
              className={`${showMyPicksBar ? "super-pickem-my-picks-navigation-flipped" : "super-pickem-my-picks-navigation"}__icon`}
              onClick={() => handleIntervalChange('next')} 
              disabled={interval >= upperBound}
            >
              <ArrowBackIosNewIcon style={{ transform: 'rotate(180deg)' }} />
            </button>
          </div>
        </Box>
      </Box>
  
      <Modal
        open={picksNotSavedModal}
        onClose={() => setPicksNotSavedModal(false)}
        aria-labelledby="picks-not-saved-modal-title"
        aria-describedby="picks-not-saved-modal-description"
      >
        <Box>
          <PicksNotSaved>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Button
                variant="contained"
                onClick={() => setPicksNotSavedModal(false)}
              >
                GO BACK
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setPickState([]);
                  setInterval(pendingInterval);
                  setPicksNotSavedModal(false);
                }}
              >
                LEAVE PAGE
              </Button>
            </Box>
          </PicksNotSaved>
        </Box>
      </Modal>
      </>
  );
}

export default SuperPickemMyPicksNavBar;
