import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useNBATeams = () => {
    return useMutation({
        mutationFn: nbaTeamsRequest,
        onError: (error) => {
        console.error('Error getting NBA teams:', error);
        },
        // onSuccess: (data) => {
        // console.log('Get NBA Teams:', data);
        // }
    });
}

const nbaTeamsRequest = async () => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-nba-teams`, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });
    return response.data;
};