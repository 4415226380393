import React, { useState, useEffect } from 'react';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import  { DateTime } from "luxon";
import '../../NFLPickEm/NFLPickEmStyles/PickEmStyles.scss';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSaveSurvivorPicks } from '../../../hooks/pools/useSaveSurvivorPicks';
import DuplicatePickModal from '../../GameModals/SurvivorModals/DuplicatePickModal';
import CFBSurvivorSubmittedPickModal from '../../GameModals/SurvivorModals/CFBSurvivorSubmittedPickModal';
import SurvivorSubmitErrorModal from '../../GameModals/SurvivorModals/SurvivorSubmitErrorModal';
import CFBMoreInfoModal from '../../GameModals/MoreInfoModals/Football/CFBMoreInfoModal';
import InfoIcon from '@mui/icons-material/Info';
import SurvivorCantSubmitPick from '../../GameModals/SurvivorModals/SurvivorCantSubmitPick';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';


const CFBSurvivorCard = ({ moreInfoOpen, setMoreInfoOpen, selectedGame, setSelectedGame, thisGamesId, userPicks, deadline, thisWeeksPickLocked, doesUserHaveALoss, lossWeek, teamsToInclude }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    // Finds and returns the name for a CFB team given its GlobalTeamID
    // @param {Array} CFBTeams - The list of CFB teams
    // @param {number} globalTeamID - The GlobalTeamID of the team
    // @returns {string|null} The name of the team or null if not found
    const cfbTeamName = (globalTeamID) => {
        const team = CFBTeams?.find(team => team.GlobalTeamID === globalTeamID);
        const teamSchool = team?.School;
        const teamShortDisplayName = team?.ShortDisplayName;
        const teamKey = team?.Key;
        return teamSchool;
    };

    const cfbShortDisplayName = (globalTeamID) => {
        const ShortDisplayName = CFBNames?.find(team => team.GlobalTeamID === globalTeamID)?.ShortDisplayName;
        return ShortDisplayName;
    }


    /**
     * Formats a date string to the format "THURS OCT 26" in EST.
     * @param {string} dateString - The date string in ISO format (e.g., "2024-10-26T20:20:00").
     * @returns {string} - The formatted date string.
     */
    function formatDate(dateString) {
        // Create a DateTime object in EST
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

        // Format the date part and convert to uppercase
        return date.toFormat('EEE MMM d').toUpperCase();
    }

    /**
     * Formats a date string to the format "THURS OCT 26 - 8:20 PM EST" or "THURS OCT 26 - TBD" in EST.
     *
     * @param {string} dateString - The date string in ISO format (e.g., "2024-10-26T20:20:00").
     * @returns {string} - The formatted date and time string.
     */
    function formatDateTime(dateString) {
        // Create a DateTime object in EST
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

        // Format the date part and convert to uppercase
        const formattedDate = date.toFormat('EEE MMM d').toUpperCase();

        // Format the time part
        let formattedTime = date.toFormat('h:mm a');

        // Check if the time is midnight
        if (formattedTime === '12:00 AM') {
            formattedTime = 'TBD'; // Set to TBD if midnight
        }

        // Combine the date and time parts
        let dateTimeString = `${formattedDate} - ${formattedTime}`;

        // Append EST only if time is not TBD
        if (formattedTime !== 'TBD') {
            dateTimeString += ' EST';
        }

        // Return the formatted date and time string
        return dateTimeString;
    }

    /**
     * Formats a date string to the format "THURS OCT 26 @ 8:20pm ET" in EST.
     *
     * @param {string} dateString - The date string in ISO format (e.g., "2024-10-26T20:20:00").
     * @returns {string} - The formatted date and time string with "@" symbol.
     */
    const formatDateStringWithAt = (dateString) => {
        // Create a DateTime object in EST
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

        // Format the date part
        const formattedDate = date.toFormat('ccc M/d');

        // Format the time part, replacing AM/PM with lowercase
        const formattedTime = date.toFormat("h:mma 'ET'").replace('AM', 'am').replace('PM', 'pm');

        // Combine the date and time parts with an "@" symbol
        return `${formattedDate} @ ${formattedTime}`;
    };


    const { 
        CFBTeams,
        CFBNames,
        CFBConferenceHierarchy,
        CFBStadiums,
        CFBGamesByWeek,
        CFBGameOddsByWeek,
        pickEmState, 
        setPickEmState,
        teamFilter,
        week,
        startingWeekState,
        rankingsToUse, 
        userPicksFromDB,
        myUserId,
        mainGameId, 
        lockedBackgroundColor,
        winBackgroundColor,
        lossBackgroundColor,
        pushBackgroundColor,
        winNotSelectedBackgroundColor,
        pushNotSelectedBackgroundColor,
        finalizedMyPickBorderColor,
        finalizedPushBorderColor,
        finalizedWinBorderColor,
        lockedPickFontColor,
        notSelectedStartedFontColor,
        notSelectedPushFontColor,
        topLeftLiveColor,
        topLeftNotSelectedColor,
        topLeftSelectedColor,
        doesUserHaveACCLoss,
        lossWeekACC,
        doesUserHaveBig12Loss,
        lossWeekBig12,
        doesUserHaveBigTenLoss,
        lossWeekBigTen,
        doesUserHaveSECLoss,
        lossWeekSEC,
    } = useCFBSurvivorContext();

    const { mutate, isLoading, isError, error, isSuccess } = useSaveSurvivorPicks();
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [submittedPickData, setSubmittedPickData] = useState([]);
    const [submitError, setSubmitError] = useState(null);
    const [picksSaved, setPicksSaved] = useState(false);
    const [removeTooLateMessage, setRemoveTooLateMessage] = useState('');
    //console.log("SUBMITTED PICK DATA:", submittedPickData);

    const submittedGame = submittedPickData?.find(pick => pick.week === week && pick.gameId === thisGamesId);
    //console.log("SUBMITTED GAME:", submittedGame);
    const submittedLogo = submittedGame?.teamId ? CFBTeams?.find(team => team.GlobalTeamID === submittedGame?.teamId)?.TeamLogoUrl : null;
    const submittedTeamName = submittedGame?.teamId ? cfbTeamName(submittedGame?.teamId) : null;
    //console.log("SUBMITTED PICK DATA:", submittedPickData);
    const submittedGameFromSchedule = CFBGamesByWeek?.find(game => game.GlobalGameID === submittedGame?.gameId);
    const isSubmittedPickHome = submittedGameFromSchedule?.GlobalHomeTeamID === submittedGame?.teamId;
    const opponentKey = isSubmittedPickHome ? submittedGameFromSchedule?.AwayTeam : submittedGameFromSchedule?.HomeTeam;
    const restOfDisplay = isSubmittedPickHome ? `vs ${opponentKey}` : `@ ${opponentKey}`;
    const submittedGameTime = submittedGame?.dateTime;
    //console.log("SUBMITTED GAME TIME:", submittedGameTime);
    const submittedGameBeforeDeadline = DateTime.fromISO(submittedGameTime) < DateTime.fromISO(deadline);
    let submittedChangeTime = null;
    if (submittedGameBeforeDeadline) {
        submittedChangeTime = formatDateStringWithAt(submittedGameTime);
    } else {
        submittedChangeTime = formatDateStringWithAt(deadline);
    }

    const handleSubmit = async (data, newPick) => {
        //console.log("SUBMITTING PICKS:", data);
        const picksData ={
            picks: data,
            userID: myUserId,
            gameType: "Survivor",
            gameId: mainGameId,
            league: "CFB",
            newPick: newPick
        }
        try {
            await mutate(picksData, {
                onSuccess: async (response) => {
                    //console.log("Response:", response);
                    const { invalidGames, savedPicks, deadlinePassed } = response;
                    //console.log("Invalid Game:", invalidGames, "Saved Picks:", savedPicks, "Deadline Passed:", deadlinePassed);
                    //console.log("Picks Submitted Successfully");
                    if (savedPicks && savedPicks.length > 0) {
                        setSubmittedPickData(data);
                        setSubmitModalOpen(true);
                        setPicksSaved(true);
                    } else if (invalidGames && invalidGames.length > 0) {
                        setSubmitModalOpen(true);
                        setPicksSaved(false);
                        setRemoveTooLateMessage('The schedule gametime has passed.');
                    } else if (deadlinePassed) {
                        setSubmitModalOpen(true);
                        setPicksSaved(false);
                        setRemoveTooLateMessage('The pick deadline has passed.');
                    }
                },
                onError: (error) => {
                    // Check if the error status is 401
                    if (error.response && error.response.status === 401) {
                        dispatch(userLogoutAction({
                            logoutType: 'token-expired'
                        }));
                    } else {
                        console.log("Error Submitting Picks:", error);
                        setSubmitError(error);
                        setSubmitModalOpen(true);
                    }
                }
            });
        } catch (error) {
            console.log("Error Submitting Picks:", error);
            setSubmitError(error);
            setSubmitModalOpen(true);
        }
    };
    
    
    const gameInfo = CFBGamesByWeek?.find(game => game.GlobalGameID === thisGamesId);
   
    //console.log("GAME INFO:", gameInfo, bettingGameInfo, consensusOdds);

    const [topSpreadClicked, setTopSpreadClicked] = useState(false);
    const [bottomSpreadClicked, setBottomSpreadClicked] = useState(false);
    const [duplicatePickModalOpen, setDuplicatePickModalOpen] = useState(false);
    const [duplicatePickId, setDuplicatePickId] = useState(null);

    const gameTime = gameInfo?.DateTime || gameInfo?.Day;
    //console.log("Day:", gameInfo?.Day, "DateTime:", gameInfo?.DateTime);
    const gameDateTime = DateTime.fromISO(gameInfo?.DateTime, { zone: 'America/New_York' });
    const nowInNY = DateTime.now().setZone('America/New_York');
    const formattedGameDate = formatDate(gameTime);
    const formattedGameDateTime = formatDateTime(gameTime);

    const hasGameStarted = nowInNY >= gameDateTime;
    const gameStatus = gameInfo?.Status;
    //const gameClosed = gameInfo?.IsClosed;
    const gameFinalized = (gameStatus === "Final" || gameStatus === "F/OT");
    //console.log("GAME TIME:", gameTime, "GAME DATE TIME:", gameDateTime, "NOW IN NY:", nowInNY, "HAS GAME STARTED?", hasGameStarted);
    
    const homeId = gameInfo?.GlobalHomeTeamID;
    const isHomeInConference = teamsToInclude?.includes(homeId);
    const homeInfo = CFBTeams?.find(team => team.GlobalTeamID === homeId);
    const homeLogo = homeInfo?.TeamLogoUrl;
    const homeWins = homeInfo?.Wins || 0;
    const homeLosses = homeInfo?.Losses || 0;
    const homeRecord = `${homeWins}-${homeLosses}`;
    let homeRank = homeInfo?.ApRank;
    if (rankingsToUse === "PlayoffRank") {
        homeRank = homeInfo?.PlayoffRank;
    }
    const homeName = cfbTeamName(homeId);
    //console.log("HOME NAME:", homeName);
    //console.log("GameInfo:", gameInfo);
    const homeAbbreviation = gameInfo?.HomeTeam;
    const homeScore = gameInfo?.HomeTeamScore;
    const awayId = gameInfo?.GlobalAwayTeamID;
    const isAwayInConference = teamsToInclude?.includes(awayId);
    const awayInfo = CFBTeams?.find(team => team.GlobalTeamID === awayId);
    const awayLogo = awayInfo?.TeamLogoUrl;
    const awayWins = awayInfo?.Wins || 0;
    const awayLosses = awayInfo?.Losses || 0;
    const awayRecord = `${awayWins}-${awayLosses}`;
    let awayRank = awayInfo?.ApRank;

    const homeSpread = gameInfo?.PointSpread;
    const homeKey = cfbShortDisplayName(homeId);
    const awayKey = cfbShortDisplayName(awayId);

    let spreadDisplay = null;
    if (homeSpread === 0)   {
        spreadDisplay = "PK";
    } else if (homeSpread > 0) {
        spreadDisplay = `${awayKey} (-${homeSpread})`;
    } else if (homeSpread < 0) {
        spreadDisplay = `${homeKey} (${homeSpread})`;
    } else {
        spreadDisplay = "No Line";
    }


    if (rankingsToUse === "PlayoffRank") {
        awayRank = awayInfo?.PlayoffRank;
    }
    const awayName = cfbTeamName(awayId);
    const awayAbbreviation = gameInfo?.AwayTeam;
    //console.log("HOME AND AWAY SPREAD:", homeSpread, awaySpread);
    const awayScore = gameInfo?.AwayTeamScore;

    const topAlreadySelected = pickEmState?.find(pick => pick.teamId === awayId  && pick.week !== week);
    //console.log("Top Already Selected:", topAlreadySelected, awayAbbreviation, topAlreadySelected?.week);
    const topSelectedCurrentWeek = pickEmState?.find(pick => pick.teamId === awayId && pick.week === startingWeekState);
    const topSelectedCurrentWeekTime = topSelectedCurrentWeek?.dateTime;
    const topSelectedCurrentWeekTimeInNY = DateTime.fromISO(topSelectedCurrentWeekTime, { zone: 'America/New_York' });
    const topSelectedCurrentWeekGameStarted = nowInNY >= topSelectedCurrentWeekTimeInNY;
    const topSelectedInWeekThatsOver = pickEmState?.find(pick => pick.teamId === awayId && pick.week < startingWeekState);
    const topAlreadyLocked = topSelectedCurrentWeekGameStarted || topSelectedInWeekThatsOver;
    //console.log("TOP ALREADY LOCKED:", topAlreadySelected, awayAbbreviation, topAlreadyLocked);
    const bottomAlreadySelected = pickEmState?.find(pick => pick.teamId === homeId && pick.week !== week);
    const bottomSelectedCurrentWeek = pickEmState?.find(pick => pick.teamId === homeId && pick.week === startingWeekState);
    const bottomSelectedCurrentWeekTime = bottomSelectedCurrentWeek?.dateTime;
    const bottomSelectedCurrentWeekTimeInNY = DateTime.fromISO(bottomSelectedCurrentWeekTime, { zone: 'America/New_York' });
    const bottomSelectedCurrentWeekGameStarted = nowInNY >= bottomSelectedCurrentWeekTimeInNY;
    const bottomSelectdInWeekThatsOver = pickEmState?.find(pick => pick.teamId === homeId && pick.week < startingWeekState);
    const bottomAlreadyLocked = bottomSelectedCurrentWeekGameStarted || bottomSelectdInWeekThatsOver;
    // if (topAlreadySelected) {
    //     console.log("TOP ALREADY SELECTED:", topAlreadySelected, awayAbbreviation, topAlreadyLocked);
    // }
    // if (bottomAlreadySelected) {
    //     console.log("BOTTOM ALREADY SELECTED:", bottomAlreadySelected, homeAbbreviation, bottomAlreadyLocked);
    // }

    const channel = gameInfo?.Channel;
    const stadiumID = gameInfo?.StadiumID;
    const stadiumInfo = gameInfo?.Stadium;
    const stadiumName = stadiumInfo?.Name;
    const stadiumCity = stadiumInfo?.City;
    const stadiumState = stadiumInfo?.State;
    const stadiumCapacity = stadiumInfo?.Capacity;
    const stadiumSurface = stadiumInfo?.PlayingSurface;
    const stadiumType = stadiumInfo?.Type;
    //console.log("STADIUM INFO:", stadiumID, stadiumInfo);

    const handleInfoClick = () => {

    const gameInfoForModal = {
        homeLogo: homeLogo,
        awayLogo: awayLogo,
        homeName: homeKey,
        awayName: awayKey,
        homeRecord: homeRecord,
        awayRecord: awayRecord,
        homeScore: homeScore,
        awayScore: awayScore,
        homeRank: homeRank,
        awayRank: awayRank,
        gameLine: spreadDisplay,
        gameTime: gameTime,
        gameDateTime: gameDateTime,
        status: gameStatus,
        gameId: thisGamesId,
        homeId: homeId,
        awayId: awayId,
    };

    //console.log("GAME INFO FOR MODAL:", gameInfoForModal);

    setSelectedGame(gameInfoForModal);
    setMoreInfoOpen(true);
    };

    
    // Use Effect to set picks on page load
    useEffect(() => {
        const isAwayInState = userPicksFromDB?.find(pick => pick.teamId === awayId && pick.week === week && pick.gameId === thisGamesId && pick.conference === teamFilter);
        const isHomeInState = userPicksFromDB?.find(pick => pick.teamId === homeId && pick.week === week && pick.gameId === thisGamesId && pick.conference === teamFilter);
        const isTopClicked = topSpreadClicked;
        const isBottomClicked = bottomSpreadClicked;

        if (doesUserHaveALoss && lossWeek < week) {
            setTopSpreadClicked(false);
            setBottomSpreadClicked(false);
        } else {

        if (isAwayInState && !isTopClicked) {
            setTopSpreadClicked(true);
        }
        if (!isAwayInState && isTopClicked) {
            setTopSpreadClicked(false);
        }
        if (isHomeInState && !isBottomClicked) {
            setBottomSpreadClicked(true);
        }
        if (!isHomeInState && isBottomClicked) {
            setBottomSpreadClicked(false);
        }
    }
    },   [userPicksFromDB, awayId, homeId, doesUserHaveALoss, lossWeek]);



    let whoWon = null;
    if (gameFinalized) {
        whoWon = (homeScore === awayScore) ? "Push" : (homeScore > awayScore) ? homeId : awayId;
    }
    let myResult = null;
    if (gameFinalized) {    
        myResult = ((whoWon === awayId && topSpreadClicked) || (whoWon === homeId && bottomSpreadClicked)) ? "Win" : (whoWon === "Push" ? "Loss" : "Loss");
        //console.log("MY RESULT:", myResult, awayAbbreviation, homeAbbreviation);
    }


    let topLeftText = formattedGameDateTime;
    if (gameStatus === "InProgress") {
        topLeftText = "LIVE";
    } else if (gameFinalized) {
        topLeftText = formattedGameDate;
    }
    let topRightText = channel;
    if (gameFinalized) {
        topRightText = gameStatus;
    }
    let bottomLeftText = stadiumState ? `${stadiumCity}, ${stadiumState}` : `${stadiumCity}`;
    if (gameFinalized && (topSpreadClicked || bottomSpreadClicked)) {
        if (myResult === "Win") {
            bottomLeftText = "My Result: WIN";
        } else if (myResult === "Loss") {
            bottomLeftText = "My Result: LOSS";
        } else if (myResult === "Push") {
            bottomLeftText = "My Result: PUSH";
        }
    }
    let bottomRightText = spreadDisplay;
    



    let topButtonBackgroundColor = null;
    let bottomButtonBackgroundColor = null;
    let topButtonBorderColor = null;
    let bottomButtonBorderColor = null;
    let topButtonFontColor = null;
    let bottomButtonFontColor = null;
    let buttonBackgroundColor = null;
        if ((gameStatus === "InProgress") && topSpreadClicked) {  //Should this be: hasGameStarted && !gameFinalized && topSpreadClicked
            topButtonBackgroundColor = lockedBackgroundColor;
            topButtonBorderColor = finalizedMyPickBorderColor;
            topButtonFontColor = "#FFF";
        } else if ((gameStatus === "InProgress") && bottomSpreadClicked) {  //Should this be: hasGameStarted && !gameFinalized && bottomSpreadClicked
            bottomButtonBackgroundColor = lockedBackgroundColor;
            bottomButtonBorderColor = finalizedMyPickBorderColor;
            bottomButtonFontColor = "#FFF";
        } else if (myResult === "Win") {
            if (topSpreadClicked) {
                topButtonBackgroundColor = winBackgroundColor;
                topButtonBorderColor = winBackgroundColor;
                topButtonFontColor = "#FFF";
                bottomButtonBackgroundColor = "#FFF";
                bottomButtonBorderColor = "#FFF";
            }
            if (bottomSpreadClicked) {
                bottomButtonBackgroundColor = winBackgroundColor;
                bottomButtonBorderColor = winBackgroundColor;
                bottomButtonFontColor = "#FFF";
                topButtonBackgroundColor = "#FFF";
                topButtonBorderColor = "#FFF";
            }
        } else if (myResult === "Loss") {
            if (topSpreadClicked) {
                topButtonBackgroundColor = lossBackgroundColor;
                topButtonBorderColor = lossBackgroundColor;
                topButtonFontColor = "#FFF";
                bottomButtonBackgroundColor = "#FFF";
                bottomButtonBorderColor = "#FFF";
            }
            if (bottomSpreadClicked) {
                bottomButtonBackgroundColor = lossBackgroundColor;
                bottomButtonBorderColor = lossBackgroundColor;
                bottomButtonFontColor = "#FFF";
                topButtonBackgroundColor = "#FFF";
                topButtonBorderColor = "#FFF";
            }
        } 

        if (gameFinalized && !topSpreadClicked && !bottomSpreadClicked) {
            if (whoWon === awayId) {
                topButtonBackgroundColor = winNotSelectedBackgroundColor;
                topButtonBorderColor = winBackgroundColor;
                bottomButtonBackgroundColor = "#FFF";
                bottomButtonBorderColor = "#FFF";
                } else if (whoWon === homeId) {
                bottomButtonBackgroundColor = winNotSelectedBackgroundColor;
                bottomButtonBorderColor = winBackgroundColor;
                topButtonBackgroundColor = "#FFF";
                topButtonBorderColor = "#FFF";
                } else if (whoWon === "Push") {
                topButtonBackgroundColor = pushNotSelectedBackgroundColor;
                topButtonBorderColor = "#002129";
                bottomButtonBackgroundColor = pushNotSelectedBackgroundColor;
                bottomButtonBorderColor = "#002129";
            }
        }


    let topBorderColor = "#E5E5E5";
    if (topSpreadClicked || bottomSpreadClicked) {
        if (gameStatus === "InProgress") {  //SHOULD THIS BE: (hasGameStarted && !gameFinalized) 
            topBorderColor = lockedBackgroundColor;
        } else if (myResult === "Win") {
            topBorderColor = winBackgroundColor;
        } else if (myResult === "Loss") {
            topBorderColor = lossBackgroundColor;
        } else if (myResult === "Push") {
            topBorderColor = pushBackgroundColor;
        }  else if (!hasGameStarted) {
            topBorderColor = "#00AAD1";
        }
    }
    


    let topLeftTextColor = "#232425";
    if (doesUserHaveALoss && lossWeek < week) {
        topLeftTextColor = "#CC293C";
    } else if (gameStatus === "InProgress") {
        topLeftTextColor = topLeftLiveColor;
    } else if (topSpreadClicked || bottomSpreadClicked) {
        topLeftTextColor = topLeftSelectedColor;
    } else if (gameFinalized && (!topSpreadClicked || !bottomSpreadClicked)) {
        topLeftTextColor = topLeftNotSelectedColor;
    }

    let topRightTextColor = "#232425";
    if (doesUserHaveALoss && lossWeek < week) {
        topRightTextColor = "#CC293C";
    } else if (topSpreadClicked || bottomSpreadClicked) {
        topRightTextColor = topLeftSelectedColor;
    } else if (gameFinalized && (!topSpreadClicked || !bottomSpreadClicked)) {
        topRightTextColor = topLeftNotSelectedColor;
    } 

    let bottomLeftTextColor = null;
    if (doesUserHaveALoss && lossWeek < week) {
        bottomLeftTextColor = "#CC293C";
    } else if (gameFinalized && (topSpreadClicked || bottomSpreadClicked)) {
        if (myResult === "Win") {
            bottomLeftTextColor = winBackgroundColor;
        } else if (myResult === "Loss") {
            bottomLeftTextColor = lossBackgroundColor;
        } else if (myResult === "Push") {
            bottomLeftTextColor = pushBackgroundColor;
        }
    } 

    let bottomRightTextColor = null;
    if (doesUserHaveALoss && lossWeek < week) {
        bottomRightTextColor = "#CC293C";
    }

    


    const handleTopClick = () => {
        if (hasGameStarted || gameStatus === "InProgress" || gameFinalized || topSpreadClicked) {
            return;
        }
        if (topAlreadySelected) {
            // console.log('Top Spread Clicked in Future.  Add Modal Here');
            setDuplicatePickModalOpen(true);
            setDuplicatePickId(awayId);
            return;
        }
        const pick = {
            gameId: thisGamesId,
            teamId: awayId,
            dateTime: gameInfo.DateTime || DateTime.fromISO(gameInfo.Day).set({ hour: 12 }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
            week: gameInfo.Week,
            isHomeSelected: false,
            result: "Pending",
            conference: teamFilter
        };
        const existingPickIndex = pickEmState.findIndex(p => p.gameId === pick.gameId && p.teamId === pick.teamId && p.week === pick.week);
        const otherPickIndex = pickEmState.findIndex(p => p.teamId !== pick.teamId && p.week === pick.week && p.conference === teamFilter);
        let picksToSave = [];
        if (otherPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(otherPickIndex, 1);
            setPickEmState([...updatedPickEmState, pick]);
            picksToSave = [...updatedPickEmState, pick];
            setBottomSpreadClicked(false);
        } else if (existingPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(existingPickIndex, 1);
            setPickEmState(updatedPickEmState);
            picksToSave = updatedPickEmState;
        } else {
            setPickEmState([...pickEmState, pick]);
            picksToSave = [...pickEmState, pick];
        }
        setTopSpreadClicked(!topSpreadClicked);
        handleSubmit(picksToSave, pick);
    };

    const handleBottomClick = () => {
        if (hasGameStarted || gameStatus === "InProgress" || gameFinalized || bottomSpreadClicked) {
            //console.log('Game Has Started');
            return;
        }
        if (bottomAlreadySelected) {
            //console.log('Bottom Spread Clicked in Future.  Add Modal Here');
            setDuplicatePickModalOpen(true);
            setDuplicatePickId(homeId);
            return;
        }
        //console.log('Bottom Spread Clicked');
        const pick = {
            gameId: thisGamesId,
            teamId: homeId,
            dateTime: gameInfo.DateTime || DateTime.fromISO(gameInfo.Day).set({ hour: 12 }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
            week: gameInfo.Week,
            isHomeSelected: true,
            result: "Pending",
            conference: teamFilter
        };
        const existingPickIndex = pickEmState?.findIndex(p => p.gameId === pick.gameId && p.teamId === pick.teamId && p.week === pick.week);
        const otherPickIndex = pickEmState?.findIndex(p => p.teamId !== pick.teamId && p.week === pick.week && p.conference === teamFilter);
        let picksToSave = [];
        if (otherPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(otherPickIndex, 1);
            setPickEmState([...updatedPickEmState, pick]);
            picksToSave = [...updatedPickEmState, pick];
            setTopSpreadClicked(false);
        } else if (existingPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(existingPickIndex, 1);
            setPickEmState(updatedPickEmState);
            picksToSave = updatedPickEmState;
        } else {
            setPickEmState([...pickEmState, pick]);
            picksToSave = [...pickEmState, pick];
        }
        setBottomSpreadClicked(!bottomSpreadClicked);
        handleSubmit(picksToSave, pick);
    };

    const handleModalAccept = (homeOrAway) => {
        const pick = {
            gameId: thisGamesId,
            teamId: duplicatePickId,
            dateTime: gameInfo.DateTime || DateTime.fromISO(gameInfo.Day).set({ hour: 12 }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
            week: gameInfo.Week,
            isHomeSelected: homeOrAway === "home" ? true : false,
            result: "Pending",
            conference: teamFilter
        };
        //console.log("MODAL Pick:", pick);
    
        let updatedPickEmState = [...pickEmState];
    
        // Find and remove existing pick for the same team in a different week
        const existingPickOtherWeekIndex = updatedPickEmState.findIndex(p => p.teamId === duplicatePickId && p.week !== gameInfo.Week);
        if (existingPickOtherWeekIndex !== -1) {
            updatedPickEmState.splice(existingPickOtherWeekIndex, 1);
        }
    
        // Find and remove existing pick for the current week with a different team
        const otherPickSameWeekIndex = updatedPickEmState.findIndex(p => p.teamId !== duplicatePickId && p.week === gameInfo.Week && p.conference === teamFilter);
        if (otherPickSameWeekIndex !== -1) {
            updatedPickEmState.splice(otherPickSameWeekIndex, 1);
        }
    
        // Add the new pick
        updatedPickEmState.push(pick);
        setPickEmState(updatedPickEmState);
    
        // Update the UI state
        if (homeOrAway === "home") {
            setBottomSpreadClicked(true);
            setTopSpreadClicked(false);
        } else {
            setTopSpreadClicked(true);
            setBottomSpreadClicked(false);
        }
    
        setDuplicatePickModalOpen(false);
    
        // Submit the picks
        handleSubmit(updatedPickEmState, pick);
    };
    

    return (

        <>
        <div className={`pickem-card${!isMobile ? '-desktop' : ''}`}>
        <div className={`top-border${!isMobile ? '-desktop' : ''}`} style={{ backgroundColor: topBorderColor }}>
            <div 
                className={`info-icon${!isMobile ? '-desktop' : ''}`} 
                onClick={() => {handleInfoClick()}}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}
            >
                <InfoIcon sx={{ fontSize: isMobile ? '15px' : '20px', color: topLeftTextColor}}/>
            </div>
            <div className={`top-left-text${!isMobile ? '-desktop' : ''}`} style={{ color: topLeftTextColor, width: isMobile ? '167px' : '175px', marginLeft: isMobile ? '13px' : '-24px', whiteSpace: 'nowrap' }}>
                {topLeftText}
            </div>
            <div className={`top-right-text${!isMobile ? '-desktop' : ''}`} style={{ color: topRightTextColor }}>
                {topRightText}
            </div>
        </div>

            <div className="middle-box">
                <div className={`team-row${!isMobile ? '-desktop' : ''}`}>
                    <div className={`logo${!isMobile ? '-desktop' : ''}`}><img src={awayLogo} alt="Top Team Logo" /></div>
                    <div className={`name-and-record${!isMobile ? '-desktop' : ''}`} style={{ fontWeight: whoWon === awayId ? 'bold' : '' }}>
                        {awayRank ? `(${awayRank}) ${awayName} (${awayWins}-${awayLosses})` : `${awayName} (${awayWins}-${awayLosses})`}
                        {(topAlreadySelected && !topAlreadyLocked && isAwayInConference) && (
                            <span style={{ fontStyle: 'italic', color: '#757575', fontSize: '10px',  }}>
                                &nbsp;- Wk {topAlreadySelected?.week}
                            </span>
                        )}
                    </div>
                    {
                        doesUserHaveALoss && lossWeek < week && !gameFinalized ? (
                            <div className={`out-text${!isMobile ? '-desktop' : ''}`} style={{ height: isMobile ? '21px' : '27px', width: isMobile ? '53px' : '68px', fontSize: '12px', color: '#CC293C', fontWeight: '700', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                OUT
                            </div>
                        ) : (thisWeeksPickLocked && !gameFinalized && !topSpreadClicked) || (!gameFinalized && !isAwayInConference) || ((hasGameStarted || gameStatus === "InProgress") && !gameFinalized && !bottomSpreadClicked && !topSpreadClicked)  ? (
                            <div className={`pick-button${!isMobile ? '-desktop' : ''}`} style={{ backgroundColor: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`}>
                                    <LockIcon style={{ fontSize: 18, color: '#757575' }} />
                                </div>
                            </div>
                        ) : !hasGameStarted && !thisWeeksPickLocked && topAlreadyLocked && !topSpreadClicked ? (
                            <div className={`empty-button${!isMobile ? '-desktop' : ''}`} style={{ fontSize: '12px', color: '#757575', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                Wk {topAlreadySelected?.week}
                            </div>
                        ) : (
                            <div className={`pick-button${!isMobile ? '-desktop' : ''} ${topSpreadClicked ? 'clicked' : ''}`} onClick={handleTopClick} style={{ backgroundColor: topButtonBackgroundColor, border: `1px solid ${topButtonBorderColor}`, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`} style={{ color: topButtonFontColor, fontSize: '12px', fontWeight: whoWon === awayId ? "bold" : '500' }}>
                                    {gameFinalized ? awayScore : awayKey}
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className={`team-row${!isMobile ? '-desktop' : ''}`}>
                    <div className={`logo${!isMobile ? '-desktop' : ''}`}><img src={homeLogo} alt="Bottom Team Logo" /></div>
                    <div className={`name-and-record${!isMobile ? '-desktop' : ''}`} style={{ fontWeight: whoWon === homeId ? 'bold' : '' }}>
                        {homeRank ? `(${homeRank}) ${homeName} (${homeWins}-${homeLosses})` : `${homeName} (${homeWins}-${homeLosses})`}
                        {(bottomAlreadySelected && !bottomAlreadyLocked && isHomeInConference) && (
                            <span style={{ fontStyle: 'italic', color: '#757575', fontSize: '10px' }}>
                                &nbsp;- Wk {bottomAlreadySelected?.week}
                            </span>
                        )}
                    </div>                
                    {
                        doesUserHaveALoss && lossWeek < week && !gameFinalized ? (
                            <div className={`out-text${!isMobile ? '-desktop' : ''}`} style={{ height: isMobile ? '21px' : '27px', width: isMobile ? '53px' : '68px', fontSize: '12px', color: '#CC293C', fontWeight: '700', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                OUT
                            </div>
                        ) : (thisWeeksPickLocked && !gameFinalized && !bottomSpreadClicked) || (!gameFinalized && !isHomeInConference) || ((hasGameStarted || gameStatus === "InProgress") && !gameFinalized && !bottomSpreadClicked && !topSpreadClicked) ? (
                            <div className={`pick-button${!isMobile ? '-desktop' : ''}`} style={{ backgroundColor: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`}>
                                    <LockIcon style={{ fontSize: 18, color: '#757575' }} />
                                </div>
                            </div>
                        ) : !hasGameStarted && !thisWeeksPickLocked && bottomAlreadyLocked && !bottomSpreadClicked ? (
                            <div className={`empty-button${!isMobile ? '-desktop' : ''}`} style={{ fontSize: '12px', color: '#757575', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                Wk {bottomAlreadySelected?.week}
                            </div>
                        ) : (
                            <div className={`pick-button${!isMobile ? '-desktop' : ''} ${bottomSpreadClicked ? 'clicked' : ''}`} onClick={handleBottomClick} style={{ backgroundColor: bottomButtonBackgroundColor, border: `1px solid ${bottomButtonBorderColor}`, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`} style={{ color: bottomButtonFontColor, fontSize: '12px', fontWeight: whoWon === homeId ? "bold" : '500' }}>
                                    {gameFinalized ? homeScore : homeKey}
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>

            <div className={`bottom-border${!isMobile ? '-desktop' : ''}`}>
                <div className={`bottom-left-text${!isMobile ? '-desktop' : ''}`} style={{ color: bottomLeftTextColor }}>{bottomLeftText}</div>
                <div className={`bottom-right-text${!isMobile ? '-desktop' : ''}`} style={{ color: bottomRightTextColor }}>{bottomRightText}</div>
            </div>
        </div>

        <div>
                {duplicatePickModalOpen && (
                    <DuplicatePickModal
                        duplicatePickModalOpen={duplicatePickModalOpen}
                        setDuplicatePickModalOpen={setDuplicatePickModalOpen}
                        duplicatePickId={duplicatePickId}
                        handleModalAccept={handleModalAccept}
                        homeId={homeId}
                        homeName={homeName}
                        homeLogo={homeLogo}
                        awayName={awayName}
                        awayLogo={awayLogo}
                        topAlreadySelected={topAlreadySelected}
                        bottomAlreadySelected={bottomAlreadySelected}
                    />
                )}
            
            {submitModalOpen && !submitError && picksSaved && (
                <CFBSurvivorSubmittedPickModal
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                    week={week}
                    submitError={submitError}
                    submittedTeamName={submittedTeamName}
                    submittedLogo={submittedLogo}
                    restOfDisplay={restOfDisplay}
                    userPicksFromDB={userPicksFromDB}
                    doesUserHaveACCLoss={doesUserHaveACCLoss}
                    lossWeekACC={lossWeekACC}
                    doesUserHaveBig12Loss={doesUserHaveBig12Loss}
                    lossWeekBig12={lossWeekBig12}
                    doesUserHaveBigTenLoss={doesUserHaveBigTenLoss}
                    lossWeekBigTen={lossWeekBigTen}
                    doesUserHaveSECLoss={doesUserHaveSECLoss}
                    lossWeekSEC={lossWeekSEC}
                    teamFilter={teamFilter}
                />
            )}
            {submitModalOpen && !submitError && !picksSaved && (
                 <SurvivorCantSubmitPick 
                    submitModalOpen={submitModalOpen}
                    week={week}
                    removeTooLateMessage={removeTooLateMessage}
                 />
            )}
            {submitModalOpen && submitError && (
                <SurvivorSubmitErrorModal
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                    submitError={submitError}
                    setSubmitError={setSubmitError}
                />
            )}
            {moreInfoOpen && (
                <CFBMoreInfoModal
                    moreInfoOpen={moreInfoOpen}
                    setMoreInfoOpen={setMoreInfoOpen}
                    selectedGame={selectedGame}
                    setSelectedGame={setSelectedGame}
                    CFBTeams={CFBTeams}
                    CFBNames={CFBNames}
                    CFBGamesByWeek={CFBGamesByWeek}
                />
            )}
        </div>
    </>



    );


  };

export default CFBSurvivorCard;