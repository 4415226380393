import React from 'react';
import './SuperPickemWinnersDisplay.scss';
import { Box, Typography } from '@mui/material';
import trophyImage from '../../assets/images/Layer_1.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SuperPickemWinnersDisplay = ({ 
    superPickemWinners,
    bracketWinners,
    pickemWinners,
    partyName, 
    gameName,
    partyId,
    mainGameId,
    titleColor, 
}) => {
    console.log("Party Name", partyName, "game Name", gameName, "PartyId", partyId, "Main Game Id", mainGameId, "Title Color", titleColor);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Utility function to render usernames with character count checks
  const renderUsernames = (usernames, isSecondary = false) => {
    return usernames.map((username, index) => {
      const characterCount = username.length;
      const baseClass = isSecondary ? 'super-pickem-champ-display-text__title--secondary' : 'super-pickem-champ-display-text__title';
      return (
        <Typography
          key={index}
          className={`${baseClass} ${isMobile
            ? characterCount > 17
              ? 'super-pickem-champ-display-text__title--small-mobile'
              : 'super-pickem-champ-display-text__title--mobile'
            : baseClass
          }`}
        >
          {username}
        </Typography>
      );
    });
  };

  return (
    <Box className={`super-pickem-champ-display ${isMobile ? 'super-pickem-champ-display--mobile' : ''}`}>
      {/* Header */}
      <Box className="super-pickem-champ-display-header">
        <Typography
          className={`super-pickem-champ-display-header__text ${isMobile ? 'super-pickem-champ-display-header__text--mobile' : ''}`}
          sx={{ marginBottom: isMobile ? '16px' : '0px', color: titleColor ? titleColor : '#fff' }}
        >
          {partyId === mainGameId ? `${gameName} Champions` : `${partyName} Champions`}
        </Typography>
      </Box>

      {/* 1st Place */}
      {superPickemWinners?.length > 0 && (
        <Box className="super-pickem-champ-display-body">
          <Box className="super-pickem-champ-display-content">
            <Box component="img" src={trophyImage} alt="Trophy Image" className="super-pickem-champ-display-image" />
            <Box className="super-pickem-champ-display-text">
              <Typography
                className={`super-pickem-champ-display-text__title super-pickem-champ-display-text__title--champion ${isMobile
                  ? 'super-pickem-champ-display-text__title--mobile'
                  : ''
                }`}
              >
                Super Pick 'Em Champ{superPickemWinners.length > 1 ? 's' : ''}
              </Typography>
              {renderUsernames(superPickemWinners)}
            </Box>
            <Box component="img" src={trophyImage} alt="Trophy Image" className="super-pickem-champ-display-image" />
          </Box>
        </Box>
      )}

      {/* 2nd Place */}
      {bracketWinners?.length > 0 && (
        <Box className="super-pickem-champ-display-body">
          <Box className="super-pickem-champ-display-content">
            <Box component="img" src={trophyImage} alt="Trophy Image" className="super-pickem-champ-display-image super-pickem-champ-display-image--secondary" />
            <Box className="super-pickem-champ-display-text">
              <Typography
                className="super-pickem-champ-display-text__title--secondary super-pickem-champ-display-text__title--champion"
              >
                Bracket Champ{bracketWinners.length > 1 ? 's' : ''}
              </Typography>
              {renderUsernames(bracketWinners, true)}
            </Box>
            <Box component="img" src={trophyImage} alt="Trophy Image" className="super-pickem-champ-display-image super-pickem-champ-display-image--secondary" />
          </Box>
        </Box>
      )}

      {/* 3rd Place */}
      {pickemWinners?.length > 0 && (
        <Box className="super-pickem-champ-display-body--third">
          <Box className="super-pickem-champ-display-content">
            <Box component="img" src={trophyImage} alt="Trophy Image" className="super-pickem-champ-display-image super-pickem-champ-display-image--secondary" />
            <Box className="super-pickem-champ-display-text">
              <Typography
                className="super-pickem-champ-display-text__title--secondary super-pickem-champ-display-text__title--champion"
              >
                Pick 'Em Champ{pickemWinners.length > 1 ? 's' : ''}
              </Typography>
              {renderUsernames(pickemWinners, true)}
            </Box>
            <Box component="img" src={trophyImage} alt="Trophy Image" className="super-pickem-champ-display-image super-pickem-champ-display-image--secondary" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SuperPickemWinnersDisplay;