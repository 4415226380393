import React from "react";
import useNavigateToTop from "../hooks/general/useNavigateToTop";
import CFBSuperPickemContainer from "../components/SuperPickem/CFB/CFBSuperPickemContainer";
import CFBSuperPickemProvider from "../components/SuperPickem/CFB/CFBSuperPickemContext";
import NFLSuperPickemContainer from "../components/SuperPickem/NFL/NFLSuperPickemContainer";
import NFLSuperPickemProvider from "../components/SuperPickem/NFL/NFLSuperPickemContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const SuperPickemGamesPage = ({ sport }) => {
    const queryClient = new QueryClient();
    const navigateToTop = useNavigateToTop();

    // Function to conditionally render the appropriate provider and container
    const renderQuickPickContainer = () => {
        switch (sport) {
            case "cfb": // College Football
                return (
                    <CFBSuperPickemProvider>
                        <CFBSuperPickemContainer />
                    </CFBSuperPickemProvider>
                );
            case "nfl": // National Football League
                return (
                    <NFLSuperPickemProvider>
                        <NFLSuperPickemContainer />
                    </NFLSuperPickemProvider>
                );
            default:
                navigateToTop('/404/pool-hall'); // Navigate to custom 404 page if sport is not supported
                return null; // Return null since we're navigating away
        }
    };

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                {renderQuickPickContainer()}
            </QueryClientProvider>
        </div>
    );
}

export default SuperPickemGamesPage;