import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

// Function to handle the POST request
export const useSaveActiveInactivePool = () => {
    return useMutation({
        mutationFn: submitActiveInactivePoolRequest,
        onError: (error) => {
        console.error('Error submitting active/inactive pool:', error);
        },
        // onSuccess: (data) => {
        // console.log('Active/inactive pool submitted successfully:', data);
        // }
    });
}

const submitActiveInactivePoolRequest = async (data) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.post(`${apiUrl}/games/save-active-inactive-pool`, data, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });  
    //console.log('Active/inactive pool submitted successfully:', response.data);
    return response.data.data;
};