import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useNFLTeams } from "../../../hooks/nfl";
import { useNBATeams } from "../../../hooks/nba";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MatchUpDisplay = ({ 
    league, 
    homeTeamId, 
    awayTeamId, 
    middleDisplay,
    customBorderColor, 
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { mutate: fetchNFLTeam, data: nflTeams } = useNFLTeams();
    const { mutate: fetchNBATeam, data: nbaTeams } = useNBATeams();

    const [hasFetchedData, setHasFetchedData] = useState(false);

    useEffect(() => {
        if (!hasFetchedData) {
            fetchNFLTeam();
            fetchNBATeam();
            setHasFetchedData(true);
        }
    }, [hasFetchedData, fetchNFLTeam, fetchNBATeam]);

    console.log("NFL Teams:", nflTeams, "NBA Teams:", nbaTeams);
    console.log("League:", league, "Home Team ID:", homeTeamId, "Away Team ID:", awayTeamId);

    const logoUrl = (teamId) => {
        if (league === 'NFL') {
            const team = nflTeams?.find((team) => team.GlobalTeamID === teamId);
            console.log("Team:", team);
            return team?.WikipediaLogoURL;
        } else if (league === 'NBA') {
            const team = nbaTeams?.find((team) => team.GlobalTeamID === teamId);
            return team?.WikipediaLogoUrl;
        }
    };

    const teamName = (teamId) => {
        if (league === 'NFL') {
            const team = nflTeams?.find((team) => team.GlobalTeamID === teamId);
            return team?.Key;
        } else if (league === 'NBA') {
            const team = nbaTeams?.find((team) => team.GlobalTeamID === teamId);
            return team?.Key;
        }
    }


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                borderRight: customBorderColor ? `1px solid ${customBorderColor}` : '1px solid #002129',
                borderLeft: customBorderColor ? `1px solid ${customBorderColor}` : '1px solid #002129',
                padding: '10px 8px 0px 8px',
            }}
        >
            {awayTeamId &&
                <>
                    <Box>
                        <img 
                            src={logoUrl(awayTeamId)} 
                            alt="Home Team Logo" 
                            loading="lazy"
                            style={{ 
                                width: isMobile ? '32px' : '40px', 
                                height: isMobile ? '32px' : '40px', 
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: 700,
                            marginLeft: '8px',
                        }}
                    >
                        {teamName(awayTeamId)}
                    </Typography>
                </>
            }
            <Typography
                sx={{
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: 700,
                    marginRight: '8px',
                    marginLeft: '8px',
                }}
            >
                {middleDisplay}
            </Typography>
            {homeTeamId &&
                <>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: 700,
                            marginRight: '8px',
                        }}
                    >
                        {teamName(homeTeamId)}
                    </Typography>
                    <Box>
                        <img 
                            src={logoUrl(homeTeamId)} 
                            alt="Home Team Logo"
                            loading="lazy" 
                            style={{ 
                                width: isMobile ? '32px' : '40px', 
                                height: isMobile ? '32px' : '40px', 
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                </>
            }
        </Box>
    );
};

export default MatchUpDisplay;