import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGetUserData, useGetGameHook, useCreateSubgroup } from '../../BirdiePool/hooks/index';
import { useGetSuperPickemPlayoffs } from '../../../hooks/superpickem/useGetSuperPickemPlayoffs';
import { useCFBTeams, useCFBNames, useGetCFBStadiums, useCFBGamesByWeek } from '../../../hooks/cfb/index';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { useGetPoolLeaderboard } from '../../../hooks/pools/useGetPoolLeaderboard';
import { useGetGameReferrals } from '../../../hooks/pools/useGetGameReferrals';
import { DateTime } from 'luxon';
import { getWeeklyRound } from '../utils/getWeeklyRound';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const CFBSuperPickemContext = createContext();

export const useCFBSuperPickemContext = () => {
    const context = useContext(CFBSuperPickemContext);
    if (!context) {
        throw new Error('useCFBSuperPickemContext must be used within a CFBSuperPickemProvider');
    }
    return context;
}

const CFBSuperPickemProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    //console.log('myUserId', myUserId);

    const { mutate: fetchUserData, data: userData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchCFBTeams, data: cfbTeams } = useCFBTeams();
    const { mutate: fetchCFBNames, data: cfbNames } = useCFBNames();
    const { mutate: fetchCFBStadiums, data: cfbStadiums } = useGetCFBStadiums();
    const { mutate: fetchCFBGamesByWeek, data: cfbGamesByWeek } = useCFBGamesByWeek();
    const { mutate: fetchLeaderboardData, data: leaderboardData, isLoading, leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetPoolLeaderboard();
    const { mutate: fetchSuperPickemPlayoffs, data: superPickemPlayoffsData } = useGetSuperPickemPlayoffs();
    const { mutate: fetchGameReferrals, data: gameReferrals } = useGetGameReferrals();
    const { mutate: createSubgroup, data: createSubgroupData } = useCreateSubgroup();

    const { gameId, component, pageState } = useParams();

    const gameType = "SuperPickem";
    const league = "CFB";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateToTop = useNavigateToTop();
    const location = useLocation();

    const [loading, setLoading] = useState(true);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);
    //console.log("userData: ", userData);

     // Fetch game data second
     useEffect(() => {
        if (gameType && gameId) {
            fetchGame ( {gameType, gameID: gameId });
        }
    }, [fetchGame, gameType, gameId]);
    //console.log("gameData: ", gameData);
    const season = gameData?.season;

    // If there is Game Data, fetch leaderboard
    useEffect(() => {
        if (gameData) {
            fetchLeaderboardData({ gameID: gameId });
            fetchGameReferrals({ gameID: gameId });
        }
    }, [fetchLeaderboardData, fetchGameReferrals, gameId, gameData])

    //console.log("LEADERBOARD", leaderboardData, "GAME REFERRALS", gameReferrals);

    //Error Handling for Game Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (gameError) {
            console.error("Error fetching game data: ", gameErrorData);
            if (gameErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                  }));
                } else {
                  navigateToTop('/404/pool-hall');
            }
        }
    }, [gameError, gameErrorData, dispatch, navigateToTop]);  
      

    // Error Handling for Leaderboard Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (leaderboardError) {
            console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
            if (leaderboardErrorData.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
            } else {
                navigateToTop('/404/pool-hall');
            }
        }
    }, [leaderboardData, leaderboardError, navigateToTop, leaderboardErrorData,dispatch]);

    const [hasFetchedData, setHasFetchedData] = useState(false);

    // Use Effect to fetch all CFB Data
    useEffect(() => {
        if (gameData && !hasFetchedData && season) {
            fetchCFBTeams();
            fetchCFBNames();
            fetchCFBStadiums();
            fetchCFBGamesByWeek();
            fetchSuperPickemPlayoffs({ league, season });
            setHasFetchedData(true);
        }
    }, [fetchCFBTeams, fetchCFBNames, fetchSuperPickemPlayoffs, fetchCFBStadiums, fetchCFBGamesByWeek, gameData, hasFetchedData, league, season]);
    //console.log("CFB Teams: ", cfbTeams, "CFB Names: ", cfbNames, "CFB Stadiums:", cfbStadiums, "Super Pickem Playoffs: ", superPickemPlayoffsData);

    const schedule = superPickemPlayoffsData?.schedule;
    const info = superPickemPlayoffsData?.info;
    const games = info?.games;
    const seeds = info?.seeds;
    const rounds = info?.rounds;
    const odds = superPickemPlayoffsData?.odds;
    const bowlGames = info?.bowlGames;
    const bowlInfo = info?.bowlInfo;
    
    let currentRound = getWeeklyRound(rounds);
    const highestRound = rounds?.length;

    const gameParameters = gameData?.gameParameters[0];
    const isGameOver = gameParameters?.isGameOver;
    const countdownText = gameParameters?.countdownText;
    const countdownBackground = gameParameters?.countdownBackground;
    const playoffPicks = gameParameters?.playoffPicks;
    const bowlPicks = gameParameters?.bowlPicks;
    const firstBowlTime = gameParameters?.firstBowlTime;
    const tiebreakerInfo = gameParameters?.tiebreakerInfo;
    const picking = gameParameters?.picking;
    const gameDescription = gameParameters?.gameDescription;
    const picksByInterval = gameParameters?.picksByInterval;
    const countdownDeadline = gameParameters?.countdownDeadline;
    const countdownTimer = gameParameters?.countdownTimer;
    const countdownMessage = gameParameters?.countdownMessage;
    const selectionShowTime = gameParameters?.selectionShowTime;
    const selectionShowText = gameParameters?.selectionShowText;
    const bracketLogo = gameParameters?.bracketLogo;
    const pickemLogo = gameParameters?.pickemLogo;
    const bracketTiebreakerInfo = gameParameters?.bracketTiebreakerInfo;
    const hostIds = gameParameters?.hostIds || [];
    const hostBackgroundColor = gameParameters?.hostBackgroundColor;
    const maxBracketPoints = gameParameters?.maxBracketPoints;
    const bracketPicksToMake = gameParameters?.bracketPicks;
    const tiebreakerGameInfo = gameParameters?.tiebreakerGameInfo || [];
    const timeToShowTiebreaker = gameParameters?.timeToShowTiebreaker;

    // Function to get the value of cfbNationalChampionshipModel from localStorage
    function getCFBChampsLocalStorage() {
        const value = localStorage.getItem('cfbNationalChampionshipModel');
        return value === 'true';  // returns boolean true or false
    }

    // Get the value of cfbNationalChampionshipModel from localStorage
    const hasUserSeenTiebreakerModal = getCFBChampsLocalStorage();    
    //console.log("Has User Seen Tiebreaker Modal?", hasUserSeenTiebreakerModal);

    //console.log("Bowl Games", bowlGames, "Bowl Info", bowlInfo, "Playoff Picks", playoffPicks, "Bowl Picks", bowlPicks);

    // STATES
    const [pickState, setPickState] = useState([]);
    const [bracketState, setBracketState] = useState([]);
    const [clickState, setClickState] = useState(null);
    const [interval, setInterval] = useState(0);
    const [signUpState, setSignUpState] = useState(false);
    const [partyState, setPartyState] = useState(gameId); // Use Effect this too?
    const [partyId, setPartyId] = useState(null);
    const [defaultPartyId, setDefaultPartyId] = useState(null);
    const [defaultPartyName, setDefaultPartyName] = useState(null);
    const [joinOpen, setJoinOpen] = useState(false);
    //console.log("Pick State", pickState, "Bracket State", bracketState);

    let doesUserBelong = null;
    const thisGame = userData?.Games?.find(game => game.gameID === gameId);
    if (thisGame) {
        doesUserBelong = true;
    } else {
        doesUserBelong = false;
    }
    //console.log("Does User Belong", doesUserBelong, "This Game", thisGame);

    // Use Effect to set interval once currentRound is set
    useEffect(() => {
        //console.log("Setting Interval");
        if (currentRound) {
            setInterval(currentRound);
        }
    }, [currentRound]);


    useEffect(() => {
        //console.log("Setting Pick State and Bracket State");
        if (doesUserBelong && thisGame) {
            const bracketPicks = thisGame.pool.bracketPicks;
            setBracketState(bracketPicks || []);
        }
    }, [doesUserBelong, thisGame]);

    //console.log("This Game", thisGame);

    const userSubgroups = thisGame?.subGroups || [];
    const referrals = userData?.referrals;
    const thisGamesReferrals = referrals?.find(referral => referral.gameId === gameId);
    //console.log("Referrals: ", referrals, "This Game's Referral: ", thisGamesReferrals);

    // const getReferralCounts = (gameReferrals) => {
    //     // Check if the input has the necessary structure
    //     if (!gameReferrals || !gameReferrals.referrals) {
    //         return { totalReferrals: 0, newSignUps: 0 };
    //     }
    
    //     const totalReferrals = gameReferrals?.referrals?.length;
    //     const newSignUps = gameReferrals?.referrals?.filter(referral => referral.newSignUp).length;
    
    //     return {
    //         totalReferrals,
    //         newSignUps
    //     };
    // };

    // const referralCounts = getReferralCounts(thisGamesReferrals);
    // const { totalReferrals, newSignUps } = referralCounts;
    // console.log("Referral Counts: ", totalReferrals, "New Sign Ups: ", newSignUps);
   

    const gameAddress = gameData?.gameAddress;
    const gameName = gameData?.gameName;
    const gameStartTime = gameData?.gameStartTime;
    const formattedGameStartTime = DateTime.fromISO(gameStartTime, { zone: 'America/New_York' });
    const lastDayToJoin = gameData?.lastDayToJoin;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const gameRewards = gameData?.rewards || [];
    const gameLogoDisplayInfo = gameData?.gameLogoDisplayInfo;
    const referralPrizes = gameData?.referralPrizes;
    const textOptIn = gameData?.textOptIn;
    const amIOptedInToTexts = textOptIn?.includes(myUserId);
    //console.log("AM I OPTED IN TO TEXTS?", amIOptedInToTexts);

    const myUsername = userData?.user_name;
    const userPicksFromDB = thisGame?.pool?.picks || [];
    const userBracketPicksFromDB = thisGame?.pool?.bracketPicks || [];
    const stopPicksWarning = thisGame?.pool?.stopPicksWarning || false;
    const rewards = thisGame?.rewards || [];
    const joinReward = rewards?.find(reward => reward.interval === 0);
    //console.log("Join Reward for User?", joinReward, "Game Rewards", gameRewards);

    //console.log("USer Picks From DB", userPicksFromDB, "User Bracket Picks From DB", userBracketPicksFromDB);

    // Utility function to check if defaultPartyIdInDB is in userSubgroups
    const isDefaultPartyInUserSubgroups = (partyId, subgroups) => {
        //console.log("Default Party ID in DB", partyId, "User Subgroups", subgroups);
        return subgroups?.some(subgroup => subgroup?.subGroupID === partyId);
    };
    
    // Get the default party ID with the additional check
    let defaultPartyIdInDB = (thisGame?.pool?.defaultParty?.length > 0)
        ? thisGame?.pool?.defaultParty
        : gameId;

    // Log both values to check consistency
    //console.log("Default Party Id in DB", thisGame?.pool?.defaultParty, defaultPartyIdInDB);

    

    //console.log("Default Party Id in DB", thisGame?.pool?.defaultParty);
    if (defaultPartyIdInDB !== gameId && !isDefaultPartyInUserSubgroups(thisGame?.pool?.defaultParty, thisGame?.subGroups || [])) {
    defaultPartyIdInDB = gameId;
    }
    //console.log("Default Party Id", defaultPartyIdInDB, "Is Default Party in User Subgroups", isDefaultPartyInUserSubgroups(defaultPartyIdInDB, thisGame?.subGroups || []));


    //console.log("User Subgroups in Context Party", userSubgroups);
    const firstSubgroupId = userSubgroups[0]?.subGroupID || "";
    const firstSubgroupName = userSubgroups[0]?.name || "";
    // Get the last subgroup if there are any subgroups
    const lastIndex = userSubgroups.length - 1;
    const lastSubgroupId = lastIndex >= 0 ? userSubgroups[lastIndex]?.subGroupID || "" : "";
    const lastSubgroupName = lastIndex >= 0 ? userSubgroups[lastIndex]?.name || "" : "";
    //console.log("First Subgroup ID", firstSubgroupId, "First Subgroup Name", firstSubgroupName, "Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);


    let defaultPartyNameInDB = sponsorName ? sponsorName : "Pool Party";
    //console.log("SponsorName", sponsorName);
    if (defaultPartyIdInDB !== gameId) {
    const foundSubGroup = userSubgroups.find(subgroup => subgroup.subGroupID === defaultPartyIdInDB);
    defaultPartyNameInDB = foundSubGroup ? foundSubGroup.name : sponsorName ? sponsorName : "Pool Party";
    }
    //console.log("Default Party Name in DB", defaultPartyNameInDB);

    useEffect(() => {
        //console.log("Setting Default Party ID and Name");
        if (defaultPartyIdInDB && defaultPartyNameInDB) {
          setDefaultPartyId(defaultPartyIdInDB);
          setDefaultPartyName(defaultPartyNameInDB);
        }
      }, [defaultPartyIdInDB, defaultPartyNameInDB]);

    

    //Use effect for setting the interval and party state based on the component and page state
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        //console.log("Use Effect for Setting Interval and Party State");
        if (userData && gameData && component) {
            if (component === 'MY_PICKS') {
                if (pageState !== 'CURRENT') {
                    setInterval(Number(pageState));
                } else {
                    setInterval(currentRound);
                }

                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }

            if (component === 'LEADERBOARD' || component === 'POOL_PICKS') {
                const tabParam = searchParams.get('tab');
                if (pageState !== gameId) {
                    const pageStateInUserSubgroups = userSubgroups.find(subgroup => subgroup.subGroupID === pageState);
                    if (pageStateInUserSubgroups) {
                        setPartyId(pageState);
                        setPartyState(pageStateInUserSubgroups.name);
                    } else {
                        setPartyId(gameId);
                        setPartyState(sponsorName ? sponsorName : "Pool Party");
                        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}${tabParam ? `?tab=${tabParam}` : ''}`);
                    } 
                } else {
                    setPartyId(gameId);
                    setPartyState(sponsorName ? sponsorName : "Pool Party");
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}${tabParam ? `?tab=${tabParam}` : ''}`);
                }
            }

            if (component === "HOME") {
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }
        
            if (component === "MY_PARTY") {
                if (pageState === "MY_PARTY") {
                    setPartyId(defaultPartyId);
                    setPartyState(defaultPartyName);
                    setSignUpState(true);
                } else if (pageState === "NEW") {
                    //console.log("Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);
                    setPartyId(lastSubgroupId);
                    setPartyState(lastSubgroupName);
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(lastSubgroupId)}`);
                } else {
                    const subgroupName = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState)?.name;
                    setPartyId(pageState);
                    setPartyState(subgroupName || "Unnamed Subgroup");
                }
            }
        }
    }, [userData, gameData, component, pageState, currentRound, defaultPartyId, firstSubgroupId, lastSubgroupId, defaultPartyName, lastSubgroupName, userSubgroups, sponsorName, gameId, gameAddress, navigate, location.search]);

    const handleCreateSubgroup = (subgroupData) => {
        createSubgroup(subgroupData);
    };

    const nowInNewYork = DateTime.now().setZone('America/New_York');

    const lastDayToJoinDateTime = DateTime.fromISO(lastDayToJoin, { zone: 'America/New_York' });

    const hasJoinDeadlinePassed = nowInNewYork > lastDayToJoinDateTime;

    //Figure Out When Game is Over!!!!!!!!!!!!!!!!!
    const finalGame = games?.find(game => game.round === highestRound);
    console.log("FINAL GAME", finalGame);
    let finalGameTime = null;
    if (finalGame && finalGame.DateTime) {
        finalGameTime = DateTime.fromISO(finalGame.DateTime, { zone: 'America/New_York' });
    }

    const hasFinalStarted = finalGameTime ? nowInNewYork > finalGameTime : false;
    console.log("Has Final Started?", hasFinalStarted);
    

    //console.log("Has Join Deadline Passed? CONTEXT", hasJoinDeadlinePassed, "Now In NY", nowInNewYork, "Last Day to Join", lastDayToJoinDateTime);

    //Use Effect to set loading to false (WHAT INFO GOES IN HERE?)
    useEffect(() => {
        //console.log("Game Data", gameData, "CFB Teams", cfbTeams, "CFB Names", cfbNames, "Super Pickem Playoffs Data", superPickemPlayoffsData, "Interval", interval, "Leaderboard Data", leaderboardData?.length, "Game Referrals", gameReferrals, "Pickem Logo", pickemLogo, "Bracket Logo", bracketLogo);
        if (gameData && cfbTeams && cfbNames && superPickemPlayoffsData && interval !== 0 && leaderboardData && gameReferrals && pickemLogo && bracketLogo && cfbGamesByWeek) {
            setLoading(false);
        }
    }, [gameData, cfbTeams, cfbNames, superPickemPlayoffsData, leaderboardData, interval, gameReferrals, pickemLogo, bracketLogo, cfbGamesByWeek]);

    const CFBSuperPickemContextValues = {
        myUserId,
        mainGameId: gameId,
        gameType,
        league,
        gameData,
        leaderboardData,
        cfbTeams,
        cfbNames,
        cfbStadiums,
        schedule,
        games,
        seeds,
        odds,
        rounds,
        highestRound,
        picking,
        gameDescription,
        pickState,
        setPickState,
        bracketState,
        setBracketState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        joinOpen,
        setJoinOpen,
        doesUserBelong,
        userSubgroups,
        //totalReferrals,
        //newSignUps,
        gameAddress,
        gameName,
        gameStartTime,
        formattedGameStartTime,
        sponsorName,
        gameRewards,
        myUsername,
        userPicksFromDB,
        userBracketPicksFromDB,
        rewards,
        joinReward,
        handleCreateSubgroup,
        picksByInterval,
        season,
        userData,
        currentRound,
        hasJoinDeadlinePassed,
        isGameOver,
        stopPicksWarning,
        countdownDeadline,
        countdownTimer,
        countdownMessage,
        firstSubgroupId,
        firstSubgroupName,
        tiebreakerInfo,
        selectionShowText,
        selectionShowTime,
        gameLogoDisplayInfo,
        gameReferrals,
        referralPrizes,
        bracketLogo,
        pickemLogo,
        bracketTiebreakerInfo,
        amIOptedInToTexts,
        bowlGames,
        bowlInfo,
        playoffPicks,
        bowlPicks,
        firstBowlTime,
        countdownBackground,
        countdownText,
        hostIds,
        hostBackgroundColor,
        maxBracketPoints,
        cfbGamesByWeek,
        bracketPicksToMake,
        tiebreakerGameInfo,
        timeToShowTiebreaker,
        hasUserSeenTiebreakerModal,
        hasFinalStarted,
    }

    // ADD LOADING SCREEN HERE
    if (loading) {
        return <LoadingScreen />
    }


    return (
        <CFBSuperPickemContext.Provider value={CFBSuperPickemContextValues}>
            {children}
        </CFBSuperPickemContext.Provider>
    )
};

export default CFBSuperPickemProvider;