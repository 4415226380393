import React from "react";
import { Box } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PaymentSuccess from "../components/Merch/PaymentSuccess";
import MerchProvider from "../components/Merch/context/MerchContext";

const PaymentSuccessPage = () => {
    const queryClient = new QueryClient();

    return (
        <Box>
            <QueryClientProvider client={queryClient}>
                <MerchProvider>
                    <PaymentSuccess />
                </MerchProvider>
            </QueryClientProvider>
        </Box>
    );
}

export default PaymentSuccessPage;