import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import MyPicksBarSingleGameDisplay from "../SuperPickemComponents/MyPicksBarSingleGameDisplay";

const MyPicksBar = ({ 
    thisIntervalsSchedule, 
    userPicksFromDB, 
    interval,
    teams,
    gatherLogo, 
    rounds,
}) => {
    // console.log("My Picks Bar, this Intervals Schedule: ", thisIntervalsSchedule);
    // console.log("My Picks Bar, user picks from DB: ", userPicksFromDB);
    // console.log("My Picks Bar, interval: ", interval);
    // console.log("My Picks Bar, rounds: ", rounds);

    const gameCountThisInterval = Number(rounds?.find(round => round.round === interval)?.games);
    const roundAbbreviation = rounds?.find(round => round.round === interval)?.abbreviation;
   //console.log("My Picks Bar, game count this interval: ", gameCountThisInterval, "Round Abbreviation: ", roundAbbreviation);
    




    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                backgroundColor: '#E8E8E8',
                padding: '8px'
            }}
        >
            {Array.from({ length: gameCountThisInterval || 0 }, (_, index) => (
                <MyPicksBarSingleGameDisplay
                    key={index}
                    gameNumber={index + 1}
                    roundAbbreviation={roundAbbreviation}
                    gameCountThisInterval={gameCountThisInterval}
                    game={thisIntervalsSchedule?.[index] || {}}
                    userPicksFromDB={userPicksFromDB}
                    gatherLogo={gatherLogo}
                    teams={teams}
                />
            ))}
        </Box>
    );
}

export default MyPicksBar;