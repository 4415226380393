import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';

const MerchModal = ({ 
    merchModalOpen, 
    setMerchModalOpen,
    setJoinOpen, 
    logo, 
    sponsorAbbreviation,
    selectedGameName, 
    selectedGameReward,
    tShirtReward,
    selectedGameShirt,
    tShirt,
    countdownBackground,
}) => {

    const handleShirtClick = () => {
        console.log("Shirt Clicked");
        window.location.href = '/merch';
    }

    const handleClose = () => {
        setJoinOpen(false);
        setMerchModalOpen(false);
        window.location.reload();
    }


    return (
        <Modal
            open={merchModalOpen}
            onClose={null}
            disableEscapeKeyDown
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{ 
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    width: '335px',
                    maxHeight: '765px',
                    bgcolor: '#FFF', 
                    borderRadius: '8px', 
                    boxShadow: 24, 
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2, 
                    overflow: 'scroll'
                }}
            >
                <Typography
                    sx={{
                        color: '#002129',
                        fontSize: '20px',
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    The $20 Tee for Free
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <img 
                        src={selectedGameShirt ? selectedGameShirt : tShirt} 
                        alt="logo" 
                        style={{
                            height: '200px',
                            //border: countdownBackground ? `4px solid ${countdownBackground}` : '4px solid #002129',
                            // borderRadius: '4px',
                        }}
                    />
                </Box>

                <Typography
                    sx={{
                        color: '#002129',
                        fontSize: '12px',
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    Purchase a {selectedGameName ? selectedGameName : sponsorAbbreviation} T-Shirt and Get {selectedGameReward ? selectedGameReward : tShirtReward}!
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '16px',
                    }}
                >
                    <Button
                        onClick={handleShirtClick}
                        variant="contained"
                    >
                        GO TO T-SHIRTS
                    </Button>

                    <Button 
                        onClick={handleClose}
                        variant="contained"

                    >
                        CONTINUE TO POOL
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default MerchModal;