import React from "react";

const PickStatsChoicesCard = ({ hasGameStarted, prop, selectedGameId, correctOption, correctOptions = [] }) => {
    const counts = prop?.counts;
    const options = prop?.options || [];
    const totalPicks = Object.values(counts).reduce((acc, count) => acc + count, 0);
    const line = prop?.line;

    const getPercentage = (option) => {
        const optionCount = counts[option] || 0;
        const percentage = (optionCount / totalPicks) * 100;
        return totalPicks > 0 ? percentage.toFixed(1) : "0.0";
    };

    const isCorrectAnswer = (option) => {
        if (correctOption === 'Multiple Answers') {
            return correctOptions?.includes(option);
        }
        return correctOption === option;
    };

    return (
        <div className="quick-pick-card-multiple-choice-container">
            {options?.map((option, index) => {
                const isCorrect = isCorrectAnswer(option);
                const optionLength = option?.length;
                const percentage = getPercentage(option);

                return (
                    <div
                        key={index}
                        className="quick-pick-card-multiple-choice-button"
                        style={{
                            border: isCorrect ? `2px solid #00AA72` : '2px solid #00212980',
                            backgroundColor: correctOption === 'Void' 
                                ? '#000000' 
                                : isCorrect ? '#CCFFEF' : '#fff',
                            marginBottom: '8px',
                            cursor: 'auto',
                        }}
                    >
                        <span
                            className="quick-pick-card-multiple-choice-answers-text"
                            style={{ 
                                color: correctOption === 'Void' 
                                    ? '#fff' 
                                    : isCorrect ? '#002129' : '#00212980',
                                fontSize: optionLength > 14 ? '16px' : '20px',
                            }}
                        >
                            {option} {line ? ` ${line}` : ''} {correctOption !== 'Void' ? `- ${percentage}%` : ''}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default PickStatsChoicesCard;