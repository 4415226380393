import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useQuickPickNFLContext } from "./context/QuickPickNFLContext";
import GameRulesPage from "../../GameRulesPage/GameRulesPage";
import GameHomePage from "../../GameHomePage/GameHomePage";
import GameSponsorHeader from "../../PoolHeaderBar/GameSponsorHeader";
import DynamicPoolHeaderBar from "../../PoolHeaderBar/DynamicPoolHeaderBar";
import MyPicks from "../MyPicks/MyPicks";
import QuickPickNav from "../QuickPickNav/QuickPickNav";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import MyParty from "../../MyParty/MyParty";
import SponsorTab from "../../SponsorTab/SponsorTab";
import QuickPickLeaderboard from "../Leaderboard/QuickPickLeaderboard";
import QuickPickUserDash from "../UserDash/QuickPickUserDash";
import QuickPickPickStats from "../PickStats/QuickPickPickStats";
import QuickPickRewards from "../Rewards/QuickPickRewards";
import RewardsRedeemHeader from "../Rewards/RewardsRedeemHeader";
import TestPropBuilder from "./TestPropBuilder";
import { DateTime } from "luxon";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import TiebreakerGameButtons from "../TiebreakerGameButtons/TiebreakerGameButtons";
import TiebreakerPartyDropdown from "../../PartyDropdown/TiebreakerPartyDropdown";


const QuickPickNFLContainer = () => {
    const {
        signUpState,
        setSignUpState, 
        gameAddress, 
        userData, 
        gameData,
        interval, 
        setInterval, 
        currentInterval, 
        firstInterval,
        lastInterval,
        userSubgroups, 
        doesUserBelong,
        myUserId,
        myUsername,
        userPicksFromDB,
        pickState,
        setPickState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        setDefaultPartyId,
        setDefaultPartyName,
        gameType,
        mainGameId,
        joinOpen,
        setJoinOpen,
        isGameOver,
        hasJoinDeadlinePassed,
        clickState,
        setClickState,
        gameName,
        nflStandings,
        nflScoresBySeason,
        nflTeams,
        quickPickNFLGames,
        gatherGameInfo,
        gatherDisplayBarInfo,
        teamsToInclude,
        gamePropsToUse,
        selectedTeamPropsToUse,
        opponentTeamPropsToUse,
        leaderboardInfo,
        defaultPartyId,
        defaultPartyName,
        firstSubgroupId,
        firstSubgroupName,
        handleCreateSubgroup,
        picksPerInterval,
        usingTiebreaker,
        league,
        intervalType,
        currentPropIds,
        propIdsToUse,
        rewards,
        joinReward,
        gameRewards,
        formattedGameStartTime,
        howManyWinners,
        oneWeekEvent,
        tiebreakerInfo,
        joinModalText,
        byeWeek,
        countdownDeadline,
        countdownTimer,
        countdownMessage,
        customBackgroundColor,
        customTextColor,
        customBorderColor,
        myPicksMobileImage,
        myPicksDesktopImage,
        amIOptedInToTexts,
        referralPrizes,
        countdownBackground,
        countdownText,
        isTiebreakerGame,
        tiebreakerGamesInfo,
        myTiebreakerGames,
        gameMembersData,
    } = useQuickPickNFLContext();
    //console.log("CONTAINER USER DATA", userData, "has Join Passed", hasJoinDeadlinePassed, "Does User Belong: ", doesUserBelong);
    
    const { component, pageState, optionalState } = useParams();
    //console.log("PAGE AND OPTIONAL STATE", pageState, optionalState);
    const navigateToTop = useNavigateToTop();
    const location = useLocation();

    const [referralId, setReferralId] = useState(null);
    const [newSignUp, setNewSignUp] = useState(false);

    let gameFilters = gameMembersData || [];

    const isPartyInGameFilters = gameFilters?.some(filter => filter.id === partyId);
    console.log("Is Page State in Game Filters", isPartyInGameFilters, gameFilters, partyId);

    useEffect(() => {
      //console.log("Running Referral Use Effect")
      const queryParams = new URLSearchParams(location.search);
      const referralId = queryParams.get('referralId');
      const newSignUp = queryParams.get('signUp');
      if (referralId) {
        setReferralId(referralId);
      }
      if (newSignUp) {
        setNewSignUp(true);
      }
    }, [location.search]);

    //console.log("Rewards in Container:", rewards);
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const anyUnredeemedRewards = rewards?.some(reward => 
        !reward.redeemed && DateTime.fromISO(reward.expiration) > timeNowInNewYork
    );
    
    //console.log("Any Unredeemed Rewards in Container: ", anyUnredeemedRewards);

    const [leaderboardForRanking, setLeaderboardForRanking] = useState([]);
    const [leaderboardData, setLeaderboardData] = useState(leaderboardInfo);

    useEffect(() => {
      console.log("Leaderboard Setting")
      if (leaderboardData && partyId === mainGameId) {
        console.log("Setting Leaderboard Data")
        setLeaderboardData(leaderboardInfo);
      } else if (leaderboardData && partyId !== mainGameId) {
        console.log("Setting Leaderboard Data for Party")
        const partyMembers = gameMembersData?.find(filter => filter.gameId === partyId)?.members;
        console.log("Party Members", partyMembers);
        const partyLeaderboardData = leaderboardInfo?.filter(user => partyMembers?.includes(user.userId));
        setLeaderboardData(partyLeaderboardData);
      }
    }, [leaderboardInfo, partyId, mainGameId, gameMembersData]);

    const lowestRankOnLeaderboardOverall = leaderboardData?.reduce((lowest, data) => {
      // If the current data.rank is lower than the lowest.rank, update the lowest object
      if (data.rank > lowest.rank) {
        return { rank: data.rank, totalPoints: data.totalPoints };
      }
      return lowest;
    }, { rank: 0, totalPoints: 0 });
    
    console.log("Lowest Rank on Leaderboard", lowestRankOnLeaderboardOverall);
    const howManyUsersWithLowestRankOverall = leaderboardData?.filter(
      data => data.rank === lowestRankOnLeaderboardOverall?.rank
    )?.length;
    
    console.log("How Many Users with Lowest Rank", howManyUsersWithLowestRankOverall);
  
    const rankForUsersWithoutRankOverall = lowestRankOnLeaderboardOverall?.totalPoints === 0 ? lowestRankOnLeaderboardOverall?.rank : lowestRankOnLeaderboardOverall?.rank + howManyUsersWithLowestRankOverall;
    console.log("Rank for Users Without Rank", rankForUsersWithoutRankOverall, "Lowest Points on Leaderboard", lowestRankOnLeaderboardOverall?.totalPoints);

    //console.log("RANKED DATA in Conttainer", leaderboardForRanking);
    const myRank = leaderboardInfo?.find(user => user.userId === myUserId)?.rank || rankForUsersWithoutRankOverall;
    const amITied = leaderboardInfo?.some(user => user.rank === myRank && user.userId !== myUserId); 
    const totalEntries = leaderboardInfo?.length;
    //console.log("My Rank in Container", myRank, "Am I tied:", amITied, "Total Entries in Container", totalEntries);

    const whosInFirst = leaderboardData?.[0]?.username || null;
    const whosInFirstCharacterCount = whosInFirst?.length || null;
    const whosInSecond = leaderboardData?.[1]?.username || null;
    const whosInSecondCharacterCount = whosInSecond?.length || null;
    const whosInThird = leaderboardData?.[2]?.username || null;
    const whosInThirdCharacterCount = whosInThird?.length || null;

    const homePageProps = {gameAddress, userData, gameData, setInterval, currentInterval, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, whosInSecond, whosInSecondCharacterCount, whosInThird, whosInThirdCharacterCount, isGameOver, hasJoinDeadlinePassed, howManyWinners, referralId, countdownTimer, countdownMessage, oneWeekEvent, timerDateTime: countdownDeadline}
    
    useEffect(() => {
      // Update the clickState whenever the URL changes
      if (component) {
        setClickState(component);
      }
    }, [component, location]);

    const uriEncoded = (link) => {
      return encodeURI(`${encodeURIComponent(link)}`);
    }

    const logo = gameData?.clubLogo;
    const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
    const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
    const sponsorHeaderLogo = gameData?.sponsorInfo?.sponsorHeaderLogo;
    //console.log("Sponsor Header Logo", sponsorHeaderLogo);
    const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;

    const selectedGames = nflScoresBySeason?.filter(game => game.Week === interval);
    const selectedQuickPickGames = quickPickNFLGames?.filter(game => game.Week === interval);
    
    function getVoidedPicksIds(games) {
      const voidedIds = [];
  
      games?.forEach(game => {
          const propCategories = ['tiebreakers', 'gameProps', 'homeProps', 'awayProps'];
  
          propCategories?.forEach(category => {
              if (game?.props[category]) {
                  game?.props[category].forEach(prop => {
                      if (prop?.correctOption === 'Void') {
                          voidedIds.push(prop.id);
                      }
                  });
              }
          });
      });
  
      return voidedIds;
    }
  
    const voidedPicksIds = getVoidedPicksIds(selectedQuickPickGames);
    const voidedPicksCount = voidedPicksIds?.length;
    //console.log("Voided Picks Ids: ", voidedPicksIds, "Length: ", voidedPicksCount);
    
    const [unsavedPicks, setUnsavedPicks] = useState(false);
    //console.log("Unsaved Picks", unsavedPicks);

    useEffect(() => {
      //console.log("Running Use Effect, resetting unsaved Picks");
      if (mainGameId) {
      setUnsavedPicks(false);
      }
    }, [mainGameId]);

   
  const handleClickState = (click) => {
    setClickState(click);
    let pageStateToUse = partyId;
    if (click === 'MY_PICKS') {
      pageStateToUse = 'CURRENT';
      setInterval(currentInterval);
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    } else if (click === 'HOME') {
      pageStateToUse = 'HOME';
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    }
    // if (click === 'MY_PARTY' && userSubgroups.length === 0) {
    //   pageStateToUse = 'NO_PARTY';
    // } else if (click === 'MY_PARTY' && partyId === mainGameId) {
    //   if (defaultPartyName !== 'Pool Party') {
    //     pageStateToUse = defaultPartyId;
    //     setPartyState(defaultPartyName);
    //     setPartyId(defaultPartyId);
    //   } else {
    //     pageStateToUse = firstSubgroupId;
    //     setPartyState(firstSubgroupName);
    //     setPartyId(firstSubgroupId);
    //   }
    // }
    if (click === 'REWARDS') {
      pageStateToUse = 'ELIGIBLE';
    }

    if (click === 'PRESENTED_BY') {
      pageStateToUse = `${gameData?.sponsorInfo?.sponsorAbbreviation} Story`;
    }
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
  }



  const getLowestRankFromRankings = (leaderboardData, partyId) => {
    return leaderboardData?.reduce((lowest, data) => {
      const userRanking = data.rankings?.find(r => r.gameId === partyId);
      const currentRank = userRanking?.currentRank;
      
      // Skip if no ranking found
      if (!currentRank) return lowest;
  
      // If this is the first valid rank or if it's lower than current lowest
      if (lowest.rank === 0 || currentRank > lowest.rank) {
        return { 
          rank: currentRank, 
          totalPoints: data.totalPoints 
        };
      }
      return lowest;
    }, { rank: 0, totalPoints: 0 });
  };
  
  const lowestRankOnLeaderboard = getLowestRankFromRankings(leaderboardData, partyId);
  
  console.log("Lowest Rank on Leaderboard", lowestRankOnLeaderboard);
  
  const howManyUsersWithLowestRank = leaderboardData?.filter(data => {
    const userRanking = data.rankings?.find(r => r.gameId === partyId);
    return userRanking?.currentRank === lowestRankOnLeaderboard?.rank;
  })?.length || 0;
  
  console.log("How Many Users with Lowest Rank", howManyUsersWithLowestRank);
  
  const rankForUsersWithoutRank = lowestRankOnLeaderboard?.totalPoints === 0 
    ? lowestRankOnLeaderboard?.rank 
    : lowestRankOnLeaderboard?.rank + howManyUsersWithLowestRank;
  
  console.log("Rank for Users Without Rank", rankForUsersWithoutRank, "Lowest Points on Leaderboard", lowestRankOnLeaderboard.totalPoints);
  

  return (
    <div>
      {sponsorLogo && 
          <GameSponsorHeader 
            sponsorLogo={sponsorHeaderLogo} 
            sponsorBannerText={sponsorBannerText} 
            sponsorName={sponsorName}
            mainGameId={mainGameId}
            gameAddress={gameAddress}
            myUserId={myUserId}
            setJoinOpen={setJoinOpen}
            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
            unsavedPicks={unsavedPicks}
            setUnsavedPicks={setUnsavedPicks}
            userPicksFromDB={userPicksFromDB}
            setPickState={setPickState}
            sponsorAbbreviation={sponsorAbbreviation}
          />
      }

      {anyUnredeemedRewards &&
        <RewardsRedeemHeader
          gameAddress={gameAddress}
          mainGameId={mainGameId}
        />
      }

      <DynamicPoolHeaderBar
        clickState={clickState}
        navigationText={`Home`}
        headerText={gameName}
        gameAddress={gameAddress}
        gameId={mainGameId}
        unsavedPicks={unsavedPicks}
        setUnsavedPicks={setUnsavedPicks}
        userPicksFromDB={userPicksFromDB}
        setPickState={setPickState}
        setPicksToEmpty={false}
        backgroundColor={customBorderColor}
        bottomLineColor={customTextColor}
      />

      {myUsername &&
        <QuickPickUserDash
          currentInterval={currentInterval}
          userPicksFromDB={userPicksFromDB}
          doesUserBelong={doesUserBelong}
          myUsername={myUsername}
          gameData={gameData}
          setJoinOpen={setJoinOpen}
          picksPerInterval={picksPerInterval}
          usingTiebreaker={usingTiebreaker}
          anyUnredeemedRewards={anyUnredeemedRewards}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          hasJoinDeadlinePassed={hasJoinDeadlinePassed}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          formattedGameStartTime={formattedGameStartTime}
          myRank={myRank}
          amITied={amITied}
          totalEntries={totalEntries}
          oneWeekEvent={oneWeekEvent}
          voidedPicksCount={voidedPicksCount}
          voidedPicksIds={voidedPicksIds}
          isGameOver={isGameOver}
          myUserId={myUserId}
        />
      }

      <QuickPickNav
        setClickState={handleClickState}
        clickState={clickState}
        setSignUpState={setSignUpState}
        doesUserBelong={doesUserBelong}
        isGameOver={isGameOver}
        userSubgroups={userSubgroups}
        sponsorAbbreviation={sponsorAbbreviation}
        unsavedPicks={unsavedPicks}
        setUnsavedPicks={setUnsavedPicks}
        userPicksFromDB={userPicksFromDB}
        setPickState={setPickState}
        gameRewards={gameRewards}
      />

      {clickState === 'HOME' && <GameHomePage homePageProps={homePageProps} /> }
        
      {clickState === 'RULES' && <GameRulesPage gameData={gameData} />}

      {clickState === 'PRESENTED_BY' && <SponsorTab gameData={gameData} />}

      {clickState === 'POOL_PICKS' &&
        <QuickPickPickStats
          leaderboardInfo={leaderboardData}
          teams={nflTeams}
          schedule={nflScoresBySeason}
          quickPickGames={quickPickNFLGames}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          currentInterval={currentInterval}
          intervalType={intervalType}
          propIdsToUse={propIdsToUse}
          teamsToInclude={teamsToInclude}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          sponsorName={sponsorName}
          league={league}
          myUserId={myUserId}
          oneWeekEvent={oneWeekEvent}
          byeWeek={byeWeek}
          userPicksFromDB={userPicksFromDB}
          partyId={partyId}
          rankForUsersWithoutRank={rankForUsersWithoutRank}
        />    
      }

      {clickState === 'LEADERBOARD' &&
        <QuickPickLeaderboard
          mainGameId={mainGameId}
          userPicksFromDB={userPicksFromDB}
          myUserId={myUserId}
          isGameOver={isGameOver}
          currentInterval={currentInterval}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          intervalType={intervalType}
          handleClickState={handleClickState}
          leaderboardData={leaderboardData}
          partyState={partyState}
          teams={nflTeams}
          schedule={nflScoresBySeason}
          gameAddress={gameAddress}
          teamsToInclude={teamsToInclude}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          sponsorName={sponsorName}
          league={league}
          oneWeekEvent={oneWeekEvent}
          tiebreakerInfo={tiebreakerInfo}
          gameName={gameName}
          quickPickGames={quickPickNFLGames}
          byeWeek={byeWeek}
          propIdsToUse={propIdsToUse}
          partyId={partyId}
          rankForUsersWithoutRank={rankForUsersWithoutRank}
        />
      }

      {/* {clickState === 'MY_PARTY' && 
        <MyParty
          gameData={gameData}
          myUserId={myUserId}
          handleCreateSubgroup={handleCreateSubgroup}
          userSubgroups={userSubgroups} 
          signUpState={signUpState}
          setSignUpState={setSignUpState}
          gameAddress={gameAddress}
          leaderboardData={leaderboardData}
          partyState={partyState}
          partyId={partyId}
          defaultPartyId={defaultPartyId}
          fullLeaderboard={fullLeaderboard}
          doesUserBelong={doesUserBelong}
          joinOpen={joinOpen}
          setJoinOpen={setJoinOpen}
          isGameOver={isGameOver}
        />
      } */}

      {clickState === 'REWARDS' &&
        <QuickPickRewards
          rewards={rewards}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          sponsorHeaderLogo={sponsorHeaderLogo}
          sponsorName={sponsorName}
          intervalType={intervalType}
          schedule={nflScoresBySeason}
          myUserId={myUserId} 
        />
      }

      {clickState === 'MY_PICKS' && 
        <MyPicks
          myUserId={myUserId}
          gameAddress={gameAddress} 
          mainGameId={mainGameId} 
          gameData={gameData} 
          userData={userData} 
          interval={interval} 
          setInterval={setInterval} 
          firstInterval={firstInterval} 
          lastInterval={lastInterval} 
          currentInterval={currentInterval}
          schedule={nflScoresBySeason}
          teams={nflTeams}
          standings={nflStandings}
          quickPickInfo={quickPickNFLGames}
          gatherGameInfo={gatherGameInfo}
          selectedGames={selectedGames}
          currentPropIds={currentPropIds}
          selectedQuickPickGames={selectedQuickPickGames}
          userPicksFromDB={userPicksFromDB}
          pickState={pickState}
          setPickState={setPickState}
          teamsToInclude={teamsToInclude}
          gamePropsToUse={gamePropsToUse}
          selectedTeamPropsToUse={selectedTeamPropsToUse}
          opponentTeamPropsToUse={opponentTeamPropsToUse}
          picksPerInterval={picksPerInterval}
          usingTiebreaker={usingTiebreaker}
          league={league}
          gameType={gameType}
          intervalType={intervalType}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          rewards={rewards}
          joinReward={joinReward}
          gameRewards={gameRewards}
          unsavedPicks={unsavedPicks}
          setUnsavedPicks={setUnsavedPicks}
          oneWeekEvent={oneWeekEvent}
          voidedPicksCount={voidedPicksCount}
          voidedPicksIds={voidedPicksIds}
          customBackgroundColor={customBackgroundColor}
          customTextColor={customTextColor}
          customBorderColor={customBorderColor}
          myPicksMobileImage={myPicksMobileImage}
          myPicksDesktopImage={myPicksDesktopImage}
        />
      }

      {joinOpen && (
        <JoinGameModal
            joinOpen={joinOpen}
            setJoinOpen={setJoinOpen}
            gameName={gameName}
            gameAddress={gameAddress}
            gameId={mainGameId}
            gameType={gameType}
            logo={logo}
            myUserId={myUserId}
            setSignUpState={setSignUpState}
            sponsorSecondaryLogo={sponsorSecondaryLogo}
            sponsorName={sponsorName}
            sponsorLogo={sponsorLogo}
            mainGameId={mainGameId}
            propIdsToUse={propIdsToUse}
            joinModalText={joinModalText}
            referralId={referralId}
            newSignUp={newSignUp}
        />
      )}

      {/* <TestPropBuilder /> */}
    </div>
  );
};

export default QuickPickNFLContainer;