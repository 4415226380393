import React, { createContext, useContext, useState, useEffect } from "react";
import { useGetUserData, useGetGameHook, useCreateSubgroup } from "../../BirdiePool/hooks/index";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useGetPoolLeaderboard } from "../../../hooks/pools/useGetPoolLeaderboard";
import { useNFLOddsByWeek, useNFLTeams } from "../../../hooks/nfl/index";
import { useCFBGameOddsByWeek, useCFBTeams } from "../../../hooks/cfb/index";
import { useNFLSportsbookOdds } from "../../../hooks/nfl/index";
import { DateTime } from "luxon";

const FantasySportsbookContext = createContext();

export const useFantasySportsbookContext = () => {
  const context = useContext(FantasySportsbookContext);
  if (!context) {
    throw new Error("useFantasySportsbookContext must be used within a FantasySportsbookProvider");
  }
  return context;
};

const FantasySportsbookProvider = ({ children }) => {
    //Getting the Users Id from Local Storage
    const myUserId = getUserDataFromLocalStorage()?.user_id;

    // Hooks to Get User Data, Game Data, Leaderboard Data, and SportsDataIO data 
    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchNFLGameOddsByWeek, data: NFLGameOddsByWeek, isLoading: NFLGameOddsByWeekLoading, isError: NFLGameOddsByWeekError, error: NFLGameOddsByWeekErrorData } = useNFLOddsByWeek();
    const { mutate: fetchNFLSportsbookOdds, data: NFLSportsbookOddsData, isLoading: NFLSportsbookOddsLoading, isError: NFLSportsbookOddsError, error: NFLSportsbookOddsErrorData } = useNFLSportsbookOdds();
    const { mutate: fetchNFLTeams, data: NFLTeams, isLoading: NFLTeamsLoading, isError: NFLTeamsError, error: NFLTeamsErrorData } = useNFLTeams();
    const { mutate: fetchCFBGameOddsByWeek, data: CFBGameOddsByWeekData, isLoading: CFBGameOddsByWeekLoading, isError: CFBGameOddsByWeekError, error: CFBGameOddsByWeekErrorData } = useCFBGameOddsByWeek();
    const { mutate: fetchCFBTeams, data: CFBTeams, isLoading: CFBTeamsLoading, isError: CFBTeamsError, error: CFBTeamsErrorData } = useCFBTeams();

    //Dispatch for log out on expired token, Navigate to Top for navigating users
    const dispatch = useDispatch();

    const navigateToTop = useNavigateToTop();
    
    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);
    console.log("USER DATA!!!!!!!!", userData);

    const gameId = "1d1bf80b-8fdf-4f3a-aae5-ca55779c97e7"; // Hardcoded for now MAKE THIS DYNAMIC USING PARAMS/CONTEXT LATER!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const gameType = "Sportsbook"; // Hardcoded for now MAKE THIS DYNAMIC USING PARAMS/CONTEXT LATER!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    
    // Finding the current game from the User's DB & then their specific picks
    const thisGame = userData?.Games?.find(game => game.gameID === gameId);
    const userPicksFromDB = thisGame?.pool?.picks;
    console.log("THIS GAME!!!!!!", thisGame, "USER PICKS FROM DB!!!!!!", userPicksFromDB);
    

    // Fetch Game Data
    useEffect(() => {
        if (gameType && gameId) {
            fetchGame({gameType, gameID: gameId});
        }
    }, [fetchGame, gameType, gameId]);
    console.log("GAME DATA!!!!!!", gameData);

    // Error Handling for Game Data, will LOG OUT on expired token, otherwise take to the 404 page
    useEffect(() => {
        if (gameError) {
            console.error("Error fetching game data:", gameErrorData);
            if (gameErrorData.response.status === 401) {
              dispatch(userLogoutAction({
                logoutType: 'token-expired'
              }));
            } else {
              navigateToTop('/404/pool-hall');
          }
        }
    }, [gameError, gameErrorData, dispatch]);


    // Pick State to hold users bets they want to submit
    const [pickState, setPickState] = useState([]);
    console.log("BETS PICK STATE", pickState);
    //console.log("NFL Game Odds By Week", NFLGameOddsByWeek, "NFL SPortsbook Odds", NFLSportsbookOdds);

    // Stop Picks Warning to show/NOT show the warning modal about this being fake money
    const stopPicksWarning = thisGame?.pool?.stopPicksWarning || false;
    console.log("STOP PICKS WARNING!!!!!!", stopPicksWarning);
    
    // Convert the Leauge string (from the Games DB) into a valid array
    const parsedLeagues = gameData?.league
    ? gameData.league.replace(/[\[\]']/g, "").split(", ").map(item => item.trim())
    : [];


    console.log("LEAGUES!!!!!!", parsedLeagues);

    // Loading State and then use effect to conditionally go out to redis and get the correct odds/teams/info for this specific game
    const [hasFetchedData, setHasFetchedData] = useState(false);
    console.log("DATA FETCHED????????????????:", hasFetchedData)

    useEffect(() => {
        console.log("RUnning Use Effect")
        if (!hasFetchedData && parsedLeagues?.length > 0) {
          console.log("Parsed Leages", parsedLeagues)
            if (parsedLeagues.includes("NFL")) {
                console.log("Fetching NFL Data")
                fetchNFLGameOddsByWeek();
                fetchNFLTeams();
                fetchNFLSportsbookOdds();
            }

            if (parsedLeagues.includes("CFB")) {
                console.log("Fetching CFB Data")
                fetchCFBGameOddsByWeek();
                fetchCFBTeams();
            }

            setHasFetchedData(true);  // Mark that the data has been fetched
        }
    }, [parsedLeagues, hasFetchedData, fetchNFLGameOddsByWeek, fetchNFLTeams, fetchNFLSportsbookOdds, fetchCFBGameOddsByWeek, fetchCFBTeams]);


    const warningModalText = {
      header: "Friendly Reminder", 
      lines: [
          "You are betting with <span class='modal-warning-highlight'>Pool Party Units</span>. These are not real money bets. You will not win or lose actual money.",
          "Once submitted, all wagers are FINAL and CANNOT be changed.",
          "Enjoy the fun and games!"
      ] 
    };  // Make this dynamic from the Game Object

    const [currentInterval, setCurrentInterval] = useState(null);
    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);
    console.log("CURRENT INTERVAL", currentInterval, "FILTER START DATE", filterStartDate, "FILTER END DATE", filterEndDate);

    
    // Get potential team filters from the Game DB and apply them if necessary
    const teamFilters = gameData?.gameParameters[0]?.teamFilters;
    console.log("TEAM FILTERS", teamFilters);


    const getSegmentForToday = (segmentInfo, todaysDateInNYC) => {
      //console.log("Current Segment Info", segmentInfo, "Todays Date in NYC", todaysDateInNYC);
  
      // Iterate over each segment and check if today's date falls within the range
      for (const segment of segmentInfo) {
          // Parse the start and end dates from the segment into Luxon DateTime objects
          const startDate = DateTime.fromISO(segment.startDate, { zone: 'America/New_York' });
          const endDate = DateTime.fromISO(segment.endDate, { zone: 'America/New_York' });
          
          //console.log("Current Start Date", startDate.toISO(), "End Date", endDate.toISO());
  
          // Check if today's date is between the startDate and endDate (inclusive)
          if (todaysDateInNYC >= startDate && todaysDateInNYC <= endDate) {
              //console.log("Current Segment", segment.segment, "Start Date", startDate, "End Date", endDate);
              return {
                  segment: segment.segment, // Return the segment number
                  startDate: startDate.toISODate(), // Return start date in ISO format
                  endDate: endDate.toISODate() // Return end date in ISO format
              };
          }
      }
  
      return null; // Return null if no segment matches today's date
  };
  
  // Getting segment Info, Today's Date, and setting the current interval and filter dates
  const segmentInfo = gameData?.gameParameters[0]?.segmentInfo;
  const todaysDateInNYC = DateTime.now().setZone('America/New_York');
  //console.log("Segment Info", segmentInfo, "Todays Date in NYC", todaysDateInNYC);
  
  useEffect(() => {
      if (segmentInfo && todaysDateInNYC) {
          const currentSegment = getSegmentForToday(segmentInfo, todaysDateInNYC);
          
          if (currentSegment) {
              const currentSegmentStartDate = DateTime.fromISO(currentSegment.startDate, { zone: 'America/New_York' });
              const currentSegmentEndDate = DateTime.fromISO(currentSegment.endDate, { zone: 'America/New_York' });
              setCurrentInterval(currentSegment.segment);
              setFilterStartDate(currentSegmentStartDate);
              setFilterEndDate(currentSegmentEndDate);
          }
      }
  }, [segmentInfo]);
  

    let NFLSportsbookOdds = NFLSportsbookOddsData;
    let CFBGameOddsByWeek = CFBGameOddsByWeekData;
    console.log("NFL SPORTSBOOK ODDS", NFLSportsbookOdds, "CFB GAME ODDS BY WEEK", CFBGameOddsByWeek);

    if (parsedLeagues.includes("NFL")) {

      if (filterStartDate && filterEndDate) {
        // Always filter by the date range first
        NFLSportsbookOdds = NFLSportsbookOddsData?.filter(game => {
          const gameDate = DateTime.fromISO(game.Day, { zone: 'America/New_York' });
          return gameDate >= filterStartDate && gameDate <= filterEndDate;
        });
      }
      

      
      // Find the NFL league in the teamFilters array
      const nflFilter = teamFilters.find(filter => filter.league === "NFL");
  
      // Extract the teamsToInclude for NFL, or set to an empty array if not found
      const filterIds = nflFilter ? nflFilter.teamsToInclude : [];
  
      console.log("FILTER IDS", filterIds);
      if (filterIds.length > 0) {
        // Filter NFLSportsbookOdds based on GlobalHomeTeamId or GlobalAwayTeamId
        NFLSportsbookOdds = NFLSportsbookOddsData?.filter(game => 
            filterIds?.includes(game.GlobalHomeTeamId) || filterIds?.includes(game.GlobalAwayTeamId)
        );
      }
    }

    if (parsedLeagues.includes("CFB")) {
      // Always filter by the date range first
      if (filterStartDate && filterEndDate) {
        CFBGameOddsByWeek = CFBGameOddsByWeekData?.filter(game => {
          const gameDate = DateTime.fromISO(game.Day, { zone: 'America/New_York' });
          return gameDate >= filterStartDate && gameDate <= filterEndDate;
        });
      }

      // Find the CFB league in the teamFilters array
      const cfbFilter = teamFilters.find(filter => filter.league === "CFB");

      // Extract the teamsToInclude for CFB, or set to an empty array if not found
      const filterIds = cfbFilter ? cfbFilter.teamsToInclude : [];

      console.log("FILTER IDS", filterIds);
      if (filterIds.length > 0) {
        // Filter CFBGameOddsByWeek based on GlobalHomeTeamId or GlobalAwayTeamId
        CFBGameOddsByWeek = CFBGameOddsByWeekData?.filter(game =>
            filterIds?.includes(game.GlobalHomeTeamId) || filterIds?.includes(game.GlobalAwayTeamId)
        );
      }
    }

  
    console.log("NFL SPORTSBOOK ODDS AFTER FILTER", NFLSportsbookOdds, "CFB GAME ODDS BY WEEK AFTER FILTER", CFBGameOddsByWeek);


    // Finding the TOTAL Payouts and Wagers from users picks, to be used to help determine available bankroll to be risked
    const currentSegmentPayouts = userPicksFromDB?.reduce((totalPayout, pick) => {
      if (pick.gameInterval === currentInterval) {
      return totalPayout + pick.payout;
      }
      return totalPayout;
    }, 0);

    const currentSegmentWagers = userPicksFromDB?.reduce((totalWager, pick) => {
      if (pick.gameInterval === currentInterval) {
      return totalWager + pick.wager;
      }
      return totalWager;
    }, 0);
    console.log("CURRENT SEGMENT PAYOUTS!!!!!!", currentSegmentPayouts, "CURRENT SEGMENT WAGERS!!!!!!", currentSegmentWagers, "Current Interval", currentInterval);
    
    // The starting bankroll (for each interval) from the Games DB
    const startingBankroll = gameData?.gameParameters?.[0]?.startingBankroll;
    console.log("Starting Bankroll!!!!!!!!!", startingBankroll);
    
    
    // Bet Styles, Max Parlay Leg, Max Teaser Legs, Can users re-invest winnings, all from Games DB (Add any new params (Max bet, etc here))
    const betStyles = gameData?.gameParameters[0]?.betStyles;
    const maxParlayLegs = gameData?.gameParameters[0]?.maxParlayLegs;
    const maxTeaserLegs = gameData?.gameParameters[0]?.maxTeaserLegs;
    const canWinningsBeReinvested = gameData?.gameParameters[0]?.canWinningsBeReinvested;

    console.log("Bet Styles", betStyles, "Max Parlay Legs", maxParlayLegs, "Max Teaser Legs", maxTeaserLegs, "Can Winnings Be Reinvested", canWinningsBeReinvested);    


     // Available Bankroll.  Accounts for both IF winnings can be reinvested or not
     const availableBankroll = canWinningsBeReinvested ? (startingBankroll + currentSegmentPayouts - currentSegmentWagers) : (startingBankroll - currentSegmentWagers);
     console.log("CURRENT SEGMENT PAYOUTS!!!!!!", currentSegmentPayouts, "CURRENT SEGMENT WAGERS!!!!!!", currentSegmentWagers, "AVAILABLE BANKROLL!!!!!!", availableBankroll);
  
     const FantasySportsbookContextValues = {
        myUserId,
        pickState,
        setPickState,
        NFLGameOddsByWeek,
        NFLSportsbookOdds,
        NFLTeams,
        CFBGameOddsByWeek,
        CFBTeams,
        currentInterval,
        stopPicksWarning,
        warningModalText,
        bankroll: availableBankroll,
        betStyles,
        maxParlayLegs,
        maxTeaserLegs,
        leagues: parsedLeagues,
        userPicksFromDB,
    };
    console.log("Fantasy Sportsbook Context Values", FantasySportsbookContextValues);

  // Render loading screen while fetching data
 
  if (NFLGameOddsByWeekLoading || NFLTeamsLoading || userLoading || NFLSportsbookOddsLoading || gameLoading || CFBGameOddsByWeekLoading || CFBTeamsLoading || isNaN(availableBankroll) || !hasFetchedData) {
    return <LoadingScreen />;
  }

  return (
    <FantasySportsbookContext.Provider value={FantasySportsbookContextValues}>
      {children}
    </FantasySportsbookContext.Provider>
  );
};

export default FantasySportsbookProvider;