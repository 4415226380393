import React from "react";
import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const MyPicksStatus = ({interval, userPicksFromDB, sortedProps, usingTiebreaker, picksPerInterval }) => {

    const picksThisInterval = userPicksFromDB?.filter((pick) => pick.interval === interval && !pick.tiebreaker);
    const tiebreakerThisInterval = userPicksFromDB?.filter((pick) => pick.interval === interval && pick.tiebreaker);

    const allPicksMade = usingTiebreaker ? picksThisInterval?.length + tiebreakerThisInterval?.length === picksPerInterval + 1 : picksThisInterval?.length === picksPerInterval;

    //console.log("Picks This Interval", picksThisInterval, "Tiebreaker This Interval", tiebreakerThisInterval, "All Picks Made", allPicksMade);

    return (
        <Box
            sx={{
                borderBottom: '2px solid #002129',
            }}
        >
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: '700',
                    color: allPicksMade ? '#00AA72' : '#CC293C',
                    backgroundColor: '#fff',
                    padding: '4px',
                }}
            >
                My Picks: {allPicksMade ? 'Entered' : 'Incomplete'} {allPicksMade ? <CheckIcon sx={{ color: '#00AA72' }} /> : <CloseIcon sx={{ color: '#CC293C' }} />}
            </Typography>
        </Box>
    );
}

export default MyPicksStatus;