// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin: 20px;
}

.products:hover {
  cursor: pointer;
}

.shipping-label {
  font-weight: bold;
  font-size: 24px;
}

.product-name {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 5px;
}

.product-description {
  font-size: 15px;
  margin-bottom: 10px;
}

.product-image {
  display: block;
  margin: 0 auto 25px auto;
  box-shadow: 0 0 18px #888;
}

.product {
  margin-bottom: 35px;
}`, "",{"version":3,"sources":["webpack://./src/components/Merch/styles/MerchStyles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,4DAAA;EACA,cAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,wBAAA;EACA,yBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".products{\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  grid-gap: 20px;\n  margin: 20px;\n}\n\n.products:hover{\n  cursor: pointer;\n}\n\n.shipping-label{\n  font-weight: bold;\n  font-size: 24px;\n}\n\n.product-name{\n  font-size: 15px;\n  font-weight: bold;\n  text-decoration: underline;\n  margin-bottom: 5px;\n}\n\n.product-description{\n  font-size: 15px;\n  margin-bottom: 10px;\n}\n\n.product-image{\n  display: block;\n  margin: 0 auto 25px auto;\n  box-shadow: 0 0 18px #888;\n}\n\n.product{\n  margin-bottom: 35px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
