import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import SuperPickemTwoPickCard from '../SuperPickemComponents/SuperPickemTwoPickCard';
import BowlGamesNavBar from './BowlGamesNavBar';
import SuperPickemSubmitBar from './SuperPickemSubmitBar';
import BowlGamesSubmitBar from './BowlGamesSubmitBar';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';
import '../Leaderboard/SuperPickemLeaderboard.scss';
import LockIcon from '@mui/icons-material/Lock';


const BowlGamesMyPicks = ({
    myUserId,
    mainGameId,
    gameAddress,
    gameType,
    teams,
    names,
    stadiums,
    schedule,
    games,
    seeds,
    odds,
    rounds,
    highestRound,
    picking,
    gameDescription,
    pickState,
    setPickState,
    bracketState,
    setBracketState,
    interval,
    setInterval,
    userPicksFromDB,
    userBracketPicksFromDB,
    unsavedPicks,
    setUnsavedPicks,
    league,
    gameRewards,
    joinReward,
    picksThisInterval,
    picksThisIntervalInDB,
    picksThisIntervalInState,
    season,
    logo,
    gatherLogo,
    gatherStadiumInfo,
    gatherDisplayInfo,
    gatherScores,
    gatherLineDisplay,
    stopPicksWarning,
    picksNotSavedModal,
    setPicksNotSavedModal,
    setPendingInterval,
    pendingInterval,
    pickemLogo,
    sponsorSecondaryLogo,
    bowlGames,
    bowlInfo,
    hideSponsorInPoolHall,
    regularSeasonSchedule,
    gatherModalInfo,
}) => {
    //console.log("PickState:", pickState);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const round = bowlInfo?.find(round => round.interval === interval);
    const roundName = round?.dates;
    //console.log("Round:", round, "Round Name:", roundName);

    const [selectedTab, setSelectedTab] = useState('current');

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

   // Filter the games for the current interval and get their IDs.
  
    const thisIntervalsGameIds = bowlGames?.map(game => game.GlobalGameID);
    //console.log("This Intervals Game Ids:", thisIntervalsGameIds);

    // Filter the schedule for the current interval using the game IDs, then sort by DateTime.
    // Today's games at top, then future games, then past games.
    let thisIntervalsSchedule = schedule
        ?.filter(game => thisIntervalsGameIds?.includes(game.GlobalGameID))
        .sort((a, b) => {
            const today = DateTime.now().setZone('America/New_York').startOf('day');
            const dateA = DateTime.fromISO(a.DateTime, { zone: 'America/New_York' });
            const dateB = DateTime.fromISO(b.DateTime, { zone: 'America/New_York' });

            // Categorize games
            const isAToday = dateA >= today && dateA < today.plus({ days: 1 });
            const isBToday = dateB >= today && dateB < today.plus({ days: 1 });
            const isAFuture = dateA > today;
            const isBFuture = dateB > today;

            // Get if we're in past tab by checking if both games are in the past
            const inPastTab = !isAFuture && !isBFuture && !isAToday && !isBToday;

            // For past games tab, sort most recent first
            if (inPastTab) {
            return dateB - dateA; // Reverse chronological order
            }

            // Original sorting for current/upcoming games
            if (isAToday && !isBToday) return -1;
            if (!isAToday && isBToday) return 1;
            
            if (isAFuture && isBFuture) return dateA - dateB;
            if (!isAFuture && !isBFuture) return dateA - dateB;
            
            if (isAFuture && !isBFuture) return -1;
            if (!isAFuture && isBFuture) return 1;

            return 0;
        });
    //console.log("This Intervals Schedule (sorted):", thisIntervalsSchedule);

    // Filter the odds for the current interval using the game IDs.
    let thisIntervalsOdds = odds?.filter(game => thisIntervalsGameIds?.includes(game.GlobalGameId));
    //console.log("This Intervals Odds:", thisIntervalsOdds);


    // Map over thisIntervalsSchedule to add `homeSpread` and `total` if they exist in `thisIntervalsOdds`.
    thisIntervalsSchedule = thisIntervalsSchedule?.map(scheduleGame => {
        const matchingGame = thisIntervalsOdds?.find(game => game.GlobalGameId === scheduleGame.GlobalGameID);
        //console.log("Matching Game:", matchingGame);
        // If matchingGame is found and has `homeSpread` or `total`, add them to scheduleGame.
        if (matchingGame) {
            let pregameOdds = matchingGame.PregameOdds?.find(odds => odds.Sportsbook === 'DraftKings'  && odds.Unlisted === null);
            if (!pregameOdds) {
                pregameOdds = matchingGame.PregameOdds?.find(
                  odds => odds.Sportsbook === 'FanDuel' && odds.Unlisted === null
                );
              }
            //console.log("Pregame Odds:", pregameOdds);
            const HomePointSpread = pregameOdds?.HomePointSpread;
            const OverUnder = pregameOdds?.OverUnder;
            // Check in bowlGames for a cramIt key
            const cramIt = bowlGames?.find(game => game.GlobalGameID === scheduleGame.GlobalGameID)?.cramIt;

            return {
                ...scheduleGame,
                homeSpread: HomePointSpread,
                total: OverUnder,
                cramIt: cramIt,
            };
        }
        return scheduleGame;
    });

    //console.log("This Intervals Schedule with spreads and totals:", thisIntervalsSchedule);

     // Filter games based on selected tab
     const todayInNY = DateTime.now().setZone('America/New_York').startOf('day');
     const filteredSchedule = thisIntervalsSchedule?.filter(game => {
         const gameDate = DateTime.fromISO(game.DateTime, { zone: 'America/New_York' });
         
         if (selectedTab === 'current') {
             // Current games are todayInNY or in the future
             return gameDate >= todayInNY;
         } else if (selectedTab === 'past') {
             // Past games are before todayInNY
             return gameDate < todayInNY;
         }
         
         return true;
     });
     //console.log("Filtered Schedule:", filteredSchedule);


    useEffect(() => {
        //console.log("Checking if picks are the same...");
        if (pickState?.length === 0) {
            setUnsavedPicks(false);
        } else {
            setUnsavedPicks(true);
        }
    }, [pickState]);

    //console.log("Picks This Interval in DB:", picksThisIntervalInDB, "Picks This Interval in State:", picksThisIntervalInState);

    const gatherPickEmCardTopLeftInfo = (gameDescription, roundName, game) => {
        return game?.Title;
    };

    // Use Effect, on page load, if filteredSchedule is empty, set selectedTab to 'past'
    useEffect(() => {
        if (filteredSchedule?.length === 0) {
            setSelectedTab('past');
        }
    }, []);

    return (
        <div>
            <Box className="super-pickem-leaderboard-button-container">
                <Button
                    className={`super-pickem-leaderboard-button ${selectedTab === 'current' ? 'active' : ''}`}
                    sx={{ width: isMobile ? '125px' : '250px'}}
                    onClick={() => handleTabChange('current')}
                >
                    Today & Upcoming
                </Button>

                <Button
                    className={`super-pickem-leaderboard-button ${selectedTab === 'past' ? 'active' : ''}`}
                    sx={{ width: isMobile ? '125px' : '250px'}}
                    onClick={() => handleTabChange('past')}
                >
                    Past Games
                </Button>
            </Box>

            {filteredSchedule?.length === 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '10px',
                        marginBottom: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: '700',
                            color: '#002129',
                        }}
                    >
                        {selectedTab === 'current' ? 'No Current Games' : 'No games have finished yet.'}
                    </Typography>
                </Box>
            )}

            {selectedTab === 'past' && (
                <Box sx={{ height: '32px' }} />
            )}

            {filteredSchedule?.length > 0 && selectedTab === 'current' && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '10px',
                        marginBottom: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: '700',
                            color: '#CC293C',
                        }}
                    >
                        2 Picks per Game:
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            color: '#CC293C',
                        }}
                    >
                        1. Against the Spread (ATS) <br />
                        2. Over/Under (O/U)
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            color: '#002129',
                            marginTop: '8px',
                        }}
                    >
                        <LockIcon sx={{ fontSize: isMobile ? '16px' : '20px', color: '#002129'  }} /> = Line currently Off The Board
                    </Typography>
                </Box>
            )}

            {filteredSchedule?.map(game => (
                <SuperPickemTwoPickCard
                    key={game.GlobalGameID}
                    gameDescription={gameDescription}
                    roundName={roundName}
                    game={game}
                    teams={teams}
                    names={names}
                    stadiums={stadiums}
                    seeds={seeds}
                    picking={picking}
                    pickState={pickState}
                    setPickState={setPickState}
                    userPicksFromDB={userPicksFromDB}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    interval={interval}
                    gatherLogo={gatherLogo}
                    gatherStadiumInfo={gatherStadiumInfo}
                    gatherDisplayInfo={gatherDisplayInfo}
                    gatherScores={gatherScores}
                    gatherLineDisplay={gatherLineDisplay}
                    teamsOrNames={names}
                    gatherPickEmCardTopLeftInfo={gatherPickEmCardTopLeftInfo}
                    regularSeasonSchedule={regularSeasonSchedule}
                    gatherModalInfo={gatherModalInfo}
                    league={league}
                />
            ))}

            {pickemLogo && (
                <img 
                    src={pickemLogo}
                    alt="Bracket Logo"
                    style={{
                        width: isMobile ? '185px' : '300px',
                        padding: '32px',
                        margin: '0 auto',
                    }}
                />
            )}

            {pickState?.length > 0  && (
                <div>
                    <BowlGamesSubmitBar
                        myUserId={myUserId}
                        gameType={gameType}
                        mainGameId={mainGameId}
                        picksThisInterval={picksThisInterval}
                        howManyPickMadeThisInterval={picksThisIntervalInState?.length}
                        league={league}
                        pickState={pickState}
                        setPickState={setPickState}
                        userPicksFromDB={userPicksFromDB}
                        interval={1}
                        gameRewards={gameRewards}
                        joinReward={joinReward}
                        season={season}
                        roundName={roundName}
                        logo={logo}
                        names={names}
                        schedule={schedule}
                        stopPicksWarning={stopPicksWarning}
                        sponsorSecondaryLogo={sponsorSecondaryLogo}
                        hideSponsorInPoolHall={hideSponsorInPoolHall}
                    />
                </div>
            )}
            
        </div>
    )
}

export default BowlGamesMyPicks;