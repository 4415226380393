import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import "./CardPieces.scss";

const TwoChoiceCard = ({ 
    pickState, 
    setPickState, 
    isPropSelected, 
    interval, 
    hasGameStarted, 
    isGameFinal, 
    prop, 
    selectedGameId, 
    result,
    customBorderColor, 
}) => {
    // Props - in real usage, these should be dynamic
    // const week = interval;
    
    let id = prop?.id;
    const globalGameId = selectedGameId;
    const propChoice = prop?.propChoice;
    const optionOne = prop?.options[0];
    const optionTwo = prop?.options[1];
    //console.log("Option One: ", optionOne, "Option Two: ", optionTwo);
    const line = prop?.line;
    //console.log("LINE:", line);
    //const line = 225.5;
    const correctOption = prop?.correctOption;
    //console.log("Correct Option", correctOption);
    // const hasGameStarted = false;
    // const isGameFinal = true;
    // const result = "Pending";

    function findLongestWordLength(input) {
        // Split the string into words using spaces as the delimiter
        const words = input.split(/\s+/);
    
        // Find the longest word by filtering out single words (including hyphenated ones)
        let longestWord = words.reduce((longest, word) => {
            // Remove non-alphanumeric characters except hyphens
            const cleanedWord = word.replace(/[^\w-]/g, '');
            return cleanedWord.length > longest.length ? cleanedWord : longest;
        }, '');
    
        // Return the length of the longest word
        return longestWord.length;
    }

    const [isOptionOneSelected, setIsOptionOneSelected] = useState(false);
    const [isOptionTwoSelected, setIsOptionTwoSelected] = useState(false);

    // Centralized color scheme for easier maintenance
    const colors = {
        loss: "#CC293C",
        lossBackground: "#CC293C33",
        win: "#00AA72",
        winBackgroundColor: "#CCFFEF",
        pendingNotLocked: "#00AAD1",
        pendingNotLockedBackground: "#00AAD133",
        pendingLocked: "#002129",
        pendingLockedBackground: "#00212933",
        notSelected: "#00212980",
        winNotSelected: "#00AA72",
        defaultBackground: "#fff",
        textColor: "#002129",
        voidedBackground: "#000000",
        voidedText: "#fff",

    };

    // Function to dynamically generate styles based on game state
    const getButtonStyles = () => {
        let leftStyles = {};
        let rightStyles = {};

        if (correctOption === "Void") { 
            leftStyles = {
                borderColor: colors.voidedBackground,
                backgroundColor: colors.voidedBackground,
                textColor: colors.voidedText,
            };
            rightStyles = {
                borderColor: colors.voidedBackground,
                backgroundColor: colors.voidedBackground,
                textColor: colors.voidedText,
            };
        } else if (isOptionOneSelected && !hasGameStarted) {
            // Option One selected, game not started
            leftStyles = {
                borderColor: colors.pendingNotLocked,
                backgroundColor: colors.pendingNotLockedBackground,
                textColor: colors.textColor,
            };
            rightStyles = {
                borderColor: colors.notSelected,
                backgroundColor: colors.defaultBackground,
                textColor: colors.notSelected,
            };
        } else if (isOptionTwoSelected && !hasGameStarted) {
            // Option Two selected, game not started
            leftStyles = {
                borderColor: colors.notSelected,
                backgroundColor: colors.defaultBackground,
                textColor: colors.notSelected,
            };
            rightStyles = {
                borderColor: colors.pendingNotLocked,
                backgroundColor: colors.pendingNotLockedBackground,
                textColor: colors.textColor,
            };
        } else if (!isOptionOneSelected && !isOptionTwoSelected && !hasGameStarted) {
            // No option selected, game not started
            leftStyles = {
                borderColor: '#000',
                backgroundColor: colors.defaultBackground,
                textColor: '#000',
            };
            rightStyles = {
                borderColor: '#000',
                backgroundColor: colors.defaultBackground,
                textColor: '#000',
            };
        } else if (isOptionOneSelected && hasGameStarted && result === "Pending") {
            // Option One selected, game started, result pending
            leftStyles = {
                borderColor: colors.pendingLocked,
                backgroundColor: colors.pendingLockedBackground,
                textColor: colors.textColor,
            };
            rightStyles = {
                borderColor: colors.notSelected,
                backgroundColor: colors.defaultBackground,
                textColor: colors.notSelected,
            };
        } else if (isOptionTwoSelected && hasGameStarted && result === "Pending") {
            // Option Two selected, game started, result pending
            leftStyles = {
                borderColor: colors.notSelected,
                backgroundColor: colors.defaultBackground,
                textColor: colors.notSelected,
            };
            rightStyles = {
                borderColor: colors.pendingLocked,
                backgroundColor: colors.pendingLockedBackground,
                textColor: colors.textColor,
            };
        } else if (isOptionOneSelected && result !== "Pending") {
            // Option One selected, game result known
            rightStyles = {
                borderColor: correctOption === 'Multiple Answers' ? colors.winNotSelected : colors.notSelected,
                backgroundColor: colors.defaultBackground,
                textColor: correctOption === 'Multiple Answers' ? colors.winNotSelected : colors.notSelected,
            };
            leftStyles = {
                textColor: colors.textColor,
                borderColor: result === "Win" ? colors.win : colors.loss,
                backgroundColor: result === "Win" ? colors.winBackgroundColor : colors.lossBackground,
            };
        } else if (isOptionTwoSelected && result !== "Pending") {
            // Option Two selected, game result known
            leftStyles = {
                borderColor: correctOption === 'Multiple Answers' ? colors.winNotSelected : colors.notSelected,
                backgroundColor: colors.defaultBackground,
                textColor: correctOption === 'Multiple Answers' ? colors.winNotSelected : colors.notSelected,
            };
            rightStyles = {
                textColor: colors.textColor,
                borderColor: result === "Win" ? colors.win : colors.loss,
                backgroundColor: result === "Win" ? colors.winBackgroundColor : colors.lossBackground,
            };
        } else if (!isPropSelected) {
            if (!correctOption) {
                leftStyles = {
                    borderColor: colors.notSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.notSelected,
                };
                rightStyles = {
                    borderColor: colors.notSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.notSelected,
                };
            } else if (correctOption === 'Multiple Answers') {
                leftStyles = {
                    borderColor: colors.winNotSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.winNotSelected,
                };
                rightStyles = {
                    borderColor: colors.winNotSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.winNotSelected,
                };
            } else if (correctOption === optionOne) {
                leftStyles = {
                    borderColor: colors.winNotSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.winNotSelected,
                };
                rightStyles = {
                    borderColor: colors.notSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.notSelected,
                };
            } else if (correctOption === optionTwo) {
                rightStyles = {
                    borderColor: colors.winNotSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.winNotSelected,
                };
                leftStyles = {
                    borderColor: colors.notSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.notSelected,
                };
            }
        }

        return { leftStyles, rightStyles };
    };

    const { leftStyles, rightStyles } = getButtonStyles();

    useEffect(() => {
        const isOptionOneSelected = pickState?.find(pick => pick.gameId === globalGameId && pick.id === id && pick.option === optionOne);
        setIsOptionOneSelected(!!isOptionOneSelected);

        const isOptionTwoSelected = pickState?.find(pick => pick.gameId === globalGameId && pick.id === id && pick.option === optionTwo);
        setIsOptionTwoSelected(!!isOptionTwoSelected);

    }, [pickState]);

    // handleClick function with logic to add/remove or switch picks
    const handleClick = (option) => {
        const newPick = {
            id: id,
            propChoice: propChoice,
            option: option,
            gameId: globalGameId,
            interval: interval,
        };

        const existingPickIndex = pickState?.findIndex(
            (pick) => pick.propChoice === propChoice && pick.gameId === globalGameId && pick.id === id
        );

        if (existingPickIndex !== -1) {
            const existingPick = pickState[existingPickIndex];
            // If the same option is selected, remove it
            if (existingPick.option === option) {
                const updatedPicks = pickState.filter((_, index) => index !== existingPickIndex);
                setPickState(updatedPicks);
            } else {
                // If another option is selected, replace it with the new one
                const updatedPicks = [...pickState];
                updatedPicks[existingPickIndex] = newPick;  // Update the existing pick
                setPickState(updatedPicks);
            }
        } else {
            // If no pick exists for this propChoice, add the new pick
            setPickState([...pickState, newPick]);  // Add the new pick to the state
        }
    };

    return (
        <Box 
            className="quick-pick-card-two-choice-container"
            sx={{
                borderRight: customBorderColor ? `1px solid ${customBorderColor}` : '',
                borderLeft: customBorderColor ? `1px solid ${customBorderColor}` : '',
            }}
        >
            <Box
                className="quick-pick-card-two-choice-button"
                onClick={() => !hasGameStarted && correctOption !== 'Void' && handleClick(optionOne)} 
                sx={{
                    border: `2px solid ${leftStyles.borderColor}`,
                    backgroundColor: leftStyles.backgroundColor,
                    pointerEvents: hasGameStarted || correctOption === 'Void' ? "none" : "auto",
                }}
            >
                <Typography
                    className="quick-pick-card-two-choice-button-text"
                    sx={{ color: leftStyles.textColor, fontSize: findLongestWordLength(optionOne) < 9 ? '24px' : findLongestWordLength(optionOne) <= 12 ? '20px' : '16px' }}
                >
                    {optionOne}
                </Typography>
                {line &&
                    <Typography
                        className="quick-pick-card-two-choice-button-text"
                        sx={{ color: leftStyles.textColor, fontSize: '24px' }}
                    >
                        {line}
                    </Typography>
                }
            </Box>
            <Box
                className="quick-pick-card-two-choice-button"
                onClick={() => !hasGameStarted && correctOption !== 'Void' && handleClick(optionTwo)}
                sx={{
                    border: `2px solid ${rightStyles.borderColor}`,
                    backgroundColor: rightStyles.backgroundColor,
                    pointerEvents: hasGameStarted || correctOption === 'Void' ? "none" : "auto",
                }}
            >
                <Typography
                    className="quick-pick-card-two-choice-button-text"
                    sx={{ color: rightStyles.textColor, fontSize: findLongestWordLength(optionTwo) < 9 ? '24px' : findLongestWordLength(optionTwo) <= 12 ? '20px' : '16px' }}
                >
                    {optionTwo}
                </Typography>
                {line &&
                    <Typography
                        className="quick-pick-card-two-choice-button-text"
                        sx={{ color: rightStyles.textColor, fontSize: '24px' }}
                    >
                        {line}
                    </Typography>
                }
            </Box>
        </Box>
    );
};

export default TwoChoiceCard;
