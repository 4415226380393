import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import QuickPicksMyPicksNavBar from "./QuickPicksMyPicksNavBar";
import GameInfoRow from "../CardPieces/GameInfoRow";
import PickDeadline from "../CardPieces/PickDeadline";
import QuickPickCard from "../CardPieces/QuickPickCard";
import QuickPickSubmitBar from "./QuickPickSubmitBar";
import TeamNameDisplayBar from "./TeamNameDisplayBar";
import QuickPickNoGamesCard from "../CardPieces/QuickPickNoGamesCard";
import MyPicksStatus from "./MyPicksStatus";
import { DateTime } from "luxon";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import PropIdeaText from "./PropIdeaText";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const QuickPickMyPicks = ({ 
    myUserId,
    gameAddress, 
    mainGameId, 
    gameData, 
    userData, 
    interval, 
    setInterval, 
    firstInterval, 
    lastInterval, 
    currentInterval,
    schedule,
    teams,
    names,
    standings,
    quickPickInfo,
    gatherGameInfo,
    gatherDisplayBarInfo,
    selectedGames,
    selectedQuickPickGames,
    userPicksFromDB,
    pickState,
    setPickState,
    teamsToInclude,
    gamePropsToUse,
    selectedTeamPropsToUse,
    opponentTeamPropsToUse,
    picksPerInterval,
    usingTiebreaker,
    league,
    gameType,
    intervalType,
    currentPropIds,
    rewards,
    joinReward,
    gameRewards,
    unsavedPicks,
    setUnsavedPicks,
    oneWeekEvent,
    voidedPicksCount,
    voidedPicksIds,
    customBackgroundColor,
    customTextColor,
    customBorderColor,
    myPicksMobileImage,
    myPicksDesktopImage,
}) => {
    //console.log("Teams", teams, "Quick PIck CFB Games", quickPickInfo);
    //console.log("Selected Games", selectedGames);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    if (!myUserId) {
        navigateToTop(`/login/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/HOME/HOME`);
    }

    //console.log("QuickPick Info: ", quickPickInfo, "Selected Quick Pick Games: ", selectedQuickPickGames);
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const sponsorName = gameData?.sponsorInfo?.sponsorName;

    let findTeamName;

    if (teamsToInclude && teamsToInclude.length > 0) {
        findTeamName = gatherDisplayBarInfo(teamsToInclude[0], teams);
    }    
    const teamName = findTeamName?.teamNickname;
    // console.log("FindTeamName:", findTeamName, "SponsorName", sponsorName);

    //console.log("User Picks from DB MY Picks:", userPicksFromDB, "Pick State My Picks:", pickState);
    const picksThisIntervalInDB = userPicksFromDB?.filter(pick => pick.interval === interval && !pick.tiebreaker && !voidedPicksIds?.includes(pick.id));
    const tiebreakerThisIntervalInDB = userPicksFromDB?.filter(pick => pick.interval === interval && pick.tiebreaker);
    const picksThisIntervalInState = pickState?.filter(pick => pick.interval === interval && !pick.tiebreaker && !voidedPicksIds?.includes(pick.id));
    const tiebreakerThisIntervalInState = pickState?.filter(pick => pick.interval === interval && pick.tiebreaker);
    const howManyPicksThisIntervalInDB = picksThisIntervalInDB?.length;
    const howManyPicksThisIntervalInState = picksThisIntervalInState?.length;
    const dbPicks = picksThisIntervalInDB?.map(pick => ({ id: pick.id, option: pick.option }));
    const statePicks = picksThisIntervalInState?.map(pick => ({ id: pick.id, option: pick.option }));

    // Compare dbPicks and statePicks
    const samePicks = dbPicks?.length === statePicks?.length && dbPicks?.every(dbPick =>
        statePicks?.some(statePick => statePick.id === dbPick.id && statePick.option === dbPick.option)
    );

    const tiebreakerDBValue = tiebreakerThisIntervalInDB[0]?.option;
    const tiebreakerStateValue = tiebreakerThisIntervalInState[0]?.option;

    const sameTiebreakers = tiebreakerDBValue === tiebreakerStateValue;

    const winsThisInterval = userPicksFromDB?.filter(pick => pick.interval === interval && pick.result === 'Win')?.length;
    const lossesThisInterval = userPicksFromDB?.filter(pick => pick.interval === interval && pick.result === 'Loss')?.length;
    const pointsThisInterval = userPicksFromDB?.filter(pick => pick.interval === interval && pick.result === 'Win')?.reduce((total, pick) => total + pick.points, 0);
    //console.log("Wins This Interval:", winsThisInterval, "Losses This Interval:", lossesThisInterval, "Points This Interval:", pointsThisInterval);


    //console.log("User Picks from DB MY Picks COUNT:", howManyPicksThisIntervalInDB, "Pick State My Picks COUNT:", howManyPicksThisIntervalInState);
    //console.log("DB Pickss COUNT:", dbPicks?.length, "State Picks COUNT:", statePicks?.length, "Same Picks? COUNT:", samePicks);
    //console.log("Tiebreaker DB:", tiebreakerThisIntervalInDB, tiebreakerDBValue, "Tiebreaker State:", tiebreakerThisIntervalInState, tiebreakerStateValue, "Same Tiebreaker", sameTiebreakers);
    useEffect(() => {
        //console.log("Running Use Effect For Saved Picks");
        if (samePicks && sameTiebreakers) {
            setUnsavedPicks(false);
        } else {
            setUnsavedPicks(true);
        }
    }, [samePicks, sameTiebreakers]);

    

    return (
        <Box>
            

            {/* Navigation Bar */}
            {!oneWeekEvent && (
                <QuickPicksMyPicksNavBar
                    gameAddress={gameAddress} 
                    mainGameId={mainGameId} 
                    gameData={gameData} 
                    userData={userData} 
                    interval={interval} 
                    setInterval={setInterval} 
                    firstInterval={firstInterval} 
                    lastInterval={lastInterval} 
                    currentInterval={currentInterval}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    userPicksFromDB={userPicksFromDB}
                    setPickState={setPickState}
                />
            )}

            {/* <TeamNameDisplayBar
                gatherDisplayBarInfo={gatherDisplayBarInfo}
                teams={teams}
                teamId={teamsToInclude[0]}
            /> */}

            {selectedGames?.length === 0 && (
                <>
                    <QuickPickNoGamesCard
                        league={league}
                        isThereAGame={false}
                        teamName={teamName}
                        sponsorName={sponsorName}
                    />
                    <Box sx={{ marginBottom: '40px'}}></Box>
                </>
            )}

            {/* Mapping through selected games */}
            {selectedGames?.map((selectedGame, index) => {
                // Extract necessary game details
                const selectedGameId = selectedGame?.GlobalGameID;
                const selectedGameTime = selectedGame?.DateTime;
                const selectedGameStatus = selectedGame?.Status;
                const selectedGameTimeInNewYork = DateTime.fromISO(selectedGameTime, { zone: 'America/New_York' });
                const hasGameStarted = timeNowInNewYork > selectedGameTimeInNewYork;
                //const hasGameStarted = true;
                const isGameFinal = selectedGameStatus === 'Final' || selectedGameStatus === 'F/OT';
                const selectedQuickPickGame = quickPickInfo?.find(game => game.GlobalGameID === selectedGameId);
                const selectedProps = selectedQuickPickGame?.props;
                //console.log("Selected Quick Pick Game: ", selectedQuickPickGame, "Selected Props: ", selectedProps);
                // Set default empty arrays for each category in case selectedProps is undefined
                let allProps = [
                    ...(selectedProps?.gameProps || []),
                    ...(selectedProps?.homeProps || []),
                    ...(selectedProps?.awayProps || []),
                    ...(selectedProps?.tiebreakers || [])
                ];

                //console.log("Props Test%$: ", allProps);
                // Filter and sort allProps based on currentPropIds
                let sortedProps = currentPropIds?.map(id => allProps.find(prop => prop.id === id)) // Find each prop by ID
                .filter(prop => prop !== undefined); // Filter out undefined values (if any ID didn't match)

                //console.log("Sorted Props Test: %$", sortedProps);
                
                // let props =[];
                // if (isHomeChosenTeam) {
                //     props.push(selectedProps?.gameProps);
                //     props.push(selectedProps?.homeProps);
                //     props.push(selectedProps?.awayProps);
                //     props.push(selectedProps?.tiebreakers);
                // } else {
                //     props.push(selectedProps?.gameProps);
                //     props.push(selectedProps?.awayProps);
                //     props.push(selectedProps?.homeProps);
                //     props.push(selectedProps?.tiebreakers);
                // }
                // // Combine all the arrays into one
                // const combinedProps = props.flat(); // Flattens the array
                // console.log("Combined Props: %$", combinedProps);


                return (
                    <Box key={selectedGameId || index}> 
                        {myPicksMobileImage && (
                            <Box>
                                <img 
                                    src={isMobile? myPicksMobileImage : myPicksDesktopImage}
                                    alt="My Picks Image"
                                    style={{ width: '100%', maxWidth: '1000px', margin: '0 auto', objectFit: 'contain' }}
                                />
                            </Box>
                        )}
                        {/* Game Info Row */}
                        {!myPicksMobileImage && (
                            <GameInfoRow
                                gameId={selectedGameId}
                                teams={teams}
                                names={names}
                                schedule={schedule}
                                standings={standings}
                                gatherGameInfo={gatherGameInfo}
                            />
                        )}

                        {hasGameStarted && (
                            <Box
                                sx={{
                                    borderTop: '2px solid #002129',
                                    width: '100%',
                                    boxSizing: 'border-box', // Ensures border fits within 100% width
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: '#002129',
                                        fontWeight: 700,
                                        marginTop: '16px',
                                    }}
                                >
                                    Record: {winsThisInterval} - {lossesThisInterval} ({pointsThisInterval} Points)
                                </Typography>
                            </Box>
                        )}
                        {/* Only show Pick Deadline if the deadline hasn't passed */}
                        {!hasGameStarted && (
                            <>
                                <PickDeadline gameTime={selectedGameTime} customBackgroundColor={customBackgroundColor} customTextColor={customTextColor} />
                                <MyPicksStatus interval={interval} userPicksFromDB={userPicksFromDB} sortedProps={sortedProps} usingTiebreaker={usingTiebreaker} picksPerInterval={picksPerInterval}/>
                            </>
                        )}

                        {sortedProps?.length === 0 && (
                            <>
                                <QuickPickNoGamesCard
                                    league={league}
                                    isThereAGame={true}
                                    teamName={teamName}
                                    sponsorName={sponsorName}
                                />
                                <Box sx={{ marginBottom: '40px'}}></Box>
                            </>
                        )}

                        {/* Quick Pick Card */}
                        {sortedProps?.map((prop, propIndex) => (
                            <QuickPickCard 
                                key={propIndex}
                                userPicksFromDB={userPicksFromDB}
                                pickState={pickState}
                                setPickState={setPickState}
                                prop={prop}
                                interval={interval}
                                selectedGameId={selectedGameId}
                                hasGameStarted={hasGameStarted}
                                isGameFinal={isGameFinal}
                                displayIndex={propIndex + 1} // Pass the index + 1 to display the correct number
                                customBackgroundColor={customBackgroundColor}
                                customTextColor={customTextColor}
                                customBorderColor={customBorderColor}
                            />
                        ))}
                        <Box sx={{ marginBottom: '20px'}}></Box>
                        {sortedProps?.length > 0 && (
                            <PropIdeaText
                                unsavedPicks={unsavedPicks}
                                userPicksFromDB={userPicksFromDB}
                                setPickState={setPickState}
                            />
                        )}

                    </Box>
                );
            })}

            {(!samePicks || !sameTiebreakers) &&
                <QuickPickSubmitBar 
                    picksPerInterval={picksPerInterval}
                    usingTiebreaker={usingTiebreaker}
                    howManyPicksThisIntervalInState={howManyPicksThisIntervalInState}
                    tiebreakerStateValue={tiebreakerStateValue}
                    league={league}
                    pickState={pickState}
                    setPickState={setPickState}
                    myUserId={myUserId}
                    mainGameId={mainGameId}
                    gameType={gameType}
                    userPicksFromDB={userPicksFromDB}
                    intervalType={intervalType}
                    interval={interval}
                    firstInterval={firstInterval}
                    joinReward={joinReward}
                    gameRewards={gameRewards}
                    voidedPicksCount={voidedPicksCount}
                />
            }

        </Box>
    );
}

export default QuickPickMyPicks;
