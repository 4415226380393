import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const BarBattleRosterCount = ({ hostGameData, barBattleLeaderboard, mainGameId }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const affiliatedGames = hostGameData?.gameParameters[0]?.affiliatedGamesInfo;

    const sortedAffiliatedGames = affiliatedGames?.sort((a, b) => {
        if (a.id === mainGameId) return -1; // Put a first if it matches mainGameId
        if (b.id === mainGameId) return 1;  // Put b first if it matches mainGameId
        return 0;                           // Keep original order otherwise
    });
    

    const getBarCounts = (sortedAffiliatedGames, barBattleLeaderboard) => {
        return sortedAffiliatedGames?.map((bar) => {
            const userCount = barBattleLeaderboard?.filter((user) => user.barId === bar.id).length;
            return {
                name: bar.name,
                circleLogo: bar.circleLogo,
                count: userCount,
            };
        });
    };

    const barCounts = getBarCounts(sortedAffiliatedGames, barBattleLeaderboard);

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                mt: 2
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: '700',
                    color: '#002129',
                    textDecoration: 'underline',
                    textAlign: 'center',
                    mb: 2
                }}
            >
                The Current Rosters
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    gap: 4
                }}
            >
                {barCounts?.map((bar, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1
                        }}
                    >
                        <img
                            src={bar.circleLogo}
                            alt={bar.name}
                            style={{
                                width: isMobile ? '50px' : '80px',
                                height: isMobile ? '50px' : '80px',
                                objectFit: 'contain',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: isMobile ? '12px' : '16px',
                                fontWeight: '700',
                                color: '#002129',
                            }}
                        >
                            {bar.count} <br /> {bar.count > 1 ? 'Entrants' : 'Entrant'}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default BarBattleRosterCount;