// NFL Utility Functions
import { DateTime } from 'luxon';


export const gatherLogoNFL = (teamId, teams) => {
    const team = teams?.find(team => team.GlobalTeamID === teamId);
    return team?.WikipediaLogoURL;
};

export const gatherStadiumInfoNFL = (stadiumId, stadiums) => {
    const stadium = stadiums?.find(stadium => stadium.StadiumID === stadiumId);
    return `${stadium?.City || ''}, ${stadium?.State || ''}`;
};

export const gatherPickEmCardTopLeftInfoNFL = (gameDescription, roundName, game) => {
    const nflConferences = [
        { conference: "AFC", teams: [3, 4, 7, 8, 10, 13, 14, 15, 16, 29, 25, 19, 21, 24, 28, 34] },
        { conference: "NFC", teams: [1, 2, 5, 6, 9, 11, 12, 32, 20, 22, 23, 26, 30, 31, 33, 35] }
    ];
   
    if (roundName === 'Super Bowl') {
        return roundName;
    } else {
        const homeTeamId = game?.GlobalHomeTeamID;

        // Find the conference for the home team
        const foundConference = nflConferences.find(conference => 
            conference.teams.includes(homeTeamId)
        )?.conference;

        return `${foundConference} - ${roundName}`;
    }
};


export const gatherLineDisplayNFL = (homeId, awayId, homeSpreadPicked, awaySpreadPicked, overPicked, underPicked, hasGameStarted, homeLine, awayLine, total, teamsOrNames) => {
    //console.log("TeamsOrNames:", teamsOrNames);
    let spreadDisplay = '';
    let totalDisplay = '';
    
    if (homeSpreadPicked || awaySpreadPicked) {
        if (homeSpreadPicked) {
            const homeTeam = teamsOrNames?.find(team => team.GlobalTeamID === homeId)?.Key;
            const homeSpread = homeLine === undefined ? "Off Board" : (homeLine > 0 ? `+${homeLine}` : homeLine === 0 ? 'PK' : homeLine);
            spreadDisplay = `${homeTeam} (${homeSpread})`;
        } else {
            const awayTeam = teamsOrNames?.find(team => team.GlobalTeamID === awayId)?.Key;
            const awaySpread = awayLine === undefined ? "Off Board" : (awayLine > 0 ? `+${awayLine}` : awayLine === 0 ? 'PK' : awayLine);
            spreadDisplay = `${awayTeam} (${awaySpread})`;
        }
    }
    
    if (overPicked || underPicked) {
        const totalValue = total === undefined ? "Off Board" : total;
        totalDisplay = `O/U (${totalValue})`;
    }    

    if (spreadDisplay !== '' && totalDisplay !== '') {
        return `${spreadDisplay}\u00A0\u00A0-\u00A0\u00A0${totalDisplay}`;
    } else if (spreadDisplay !== '') {
        return spreadDisplay;
    } else if (totalDisplay !== '') {
        return totalDisplay;
    } else {
        return '';
    }
}

export const gatherDisplayInfoNFL = (teamId, teams, seeds, isGameOver, standings) => {
    const team = teams?.find(team => team.GlobalTeamID === teamId);
    const seed = seeds?.find(seed => seed.GlobalTeamID === teamId)?.seed;
    const key = team?.City;
    const name = team?.Name;
    const wins = standings?.find(team => team.GlobalTeamID === teamId)?.Wins;
    const losses = standings?.find(team => team.GlobalTeamID === teamId)?.Losses;
    const display = !isGameOver 
    ? `${seed !== undefined ? `(${seed}) ` : ""}${key} ${name} (${wins}-${losses})`
    : `${seed !== undefined ? `(${seed}) ` : ""}${key} ${name}`;
    return display;
}

export const gatherScoresNFL = (game) => {
    const homeScore = game?.HomeScore;
    const awayScore = game?.AwayScore;
    return { homeScore, awayScore };
}


export const getBracketInfoPreLockNFL = (teamId, teams, seeds, names, standings) => {
    const team = teams?.find((t) => t.GlobalTeamID === teamId);
    const seed = seeds?.find((s) => s.GlobalTeamID === teamId)?.seed;
    const logo = team?.WikipediaLogoURL;
    const name = `${team?.Key} ${team?.Name}`;
    const teamStandings = standings?.find((s) => s.GlobalTeamID === teamId);
    const record = teamStandings?.Wins + "-" + teamStandings?.Losses;
    const teamColor = `#${names.find((n) => n.GlobalTeamID === teamId)?.PrimaryColor}` ;
    const nameDisplay = name + " (" + record + ")";
    const teamDisplayCharacterCount = nameDisplay?.length;

    return {
        teamId,
        seed,
        logo,
        nameDisplay,
        teamDisplayCharacterCount,
        teamColor,
    };
};


export const getBracketInfoPostLockNFL = (teamId, teams, seeds, names) => {
    if (!teamId) {
        return null;
    }
    const team = teams?.find((t) => t.GlobalTeamID === teamId);
    const seed = seeds?.find((s) => s.GlobalTeamID === teamId)?.seed;
    const logo = team?.WikipediaLogoURL;
    const name = team?.City;
    const teamColor = names?.find((n) => n.GlobalTeamID === teamId)?.PrimaryColor;
    const nameDisplay = name;
    const teamDisplayCharacterCount = nameDisplay?.length;

    return {
        teamId,
        seed,
        logo,
        nameDisplay,
        teamDisplayCharacterCount,
        teamColor,
    };
};



export const getPickStatsGameInfoNFL = (gameId, schedule, teams, teamsOrNames) => {
    const game = schedule?.find(game => game?.GlobalGameID === gameId);
    const awayTeamId = game?.GlobalAwayTeamID;
    const homeTeamId = game?.GlobalHomeTeamID;
    const gameStatus = game?.Status;
    const dateTime = game?.DateTime;
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const formattedDate = DateTime.fromISO(dateTime)
        .setZone('America/New_York')  // Ensure it's in New York time zone (ET)
        .toFormat('EEE, MMM dd h:mm a ZZZZ');
    const dateTimeInNewYork = DateTime.fromISO(dateTime, { zone: 'America/New_York' });
    const startedGameStatuses = ['Final', 'F/OT', 'InProgress', 'Suspended'];
    const hasGameStarted = (dateTimeInNewYork < timeNowInNewYork) || startedGameStatuses?.includes(gameStatus);
    const homeScore = game?.HomeScore;
    const awayScore = game?.AwayScore;
    const homePointSpread = game?.PointSpread;
    const overUnder = game?.OverUnder;
    const awayLogo = teams?.find(team => team?.GlobalTeamID === awayTeamId)?.WikipediaLogoURL;
    const homeLogo = teams?.find(team => team?.GlobalTeamID === homeTeamId)?.WikipediaLogoURL;
    const awayName = teamsOrNames?.find(team => team?.GlobalTeamID === awayTeamId)?.Key;
    const homeName = teamsOrNames?.find(team => team?.GlobalTeamID === homeTeamId)?.Key;
    const title = game?.Title;

    return {
        awayTeamId,
        homeTeamId,
        gameStatus,
        dateTime,
        homeScore,
        awayScore,
        homePointSpread,
        overUnder,
        awayLogo,
        homeLogo,
        awayName,
        homeName,
        hasGameStarted,
        formattedDate,
        title
    };
}




export const getPickStatsPickInfoNFL = (gameId, awayTeamId, homeTeamId, leaderboard) => {
    const stats = {
        away: { count: 0, wins: 0, losses: 0, pushes: 0 },
        home: { count: 0, wins: 0, losses: 0, pushes: 0 },
        over: { count: 0, wins: 0, losses: 0, pushes: 0 },
        under: { count: 0, wins: 0, losses: 0, pushes: 0 }
    };

    leaderboard?.forEach(user => {
        const spreadPick = user?.dailyPicks?.find(pick => 
            pick?.gameId === gameId && pick?.pickType === "Spread"
        );
        if (spreadPick) {
            const side = spreadPick.pick === awayTeamId ? 'away' : 'home';
            stats[side].count++;
            if (spreadPick.result === "Win") stats[side].wins++;
            else if (spreadPick.result === "Loss") stats[side].losses++;
            else if (spreadPick.result === "Push") stats[side].pushes++;
        }

        const totalPick = user?.dailyPicks?.find(pick => 
            pick?.gameId === gameId && pick?.pickType === "Total"
        );
        if (totalPick) {
            const side = totalPick.pick === "Over" ? 'over' : 'under';
            stats[side].count++;
            if (totalPick.result === "Win") stats[side].wins++;
            else if (totalPick.result === "Loss") stats[side].losses++;
            else if (totalPick.result === "Push") stats[side].pushes++;
        }
    });

    // Helper function to determine result (Win, Loss, Push, or Split)
    const getResult = (wins, losses, pushes) => {
        const nonZeroCount = [wins, losses, pushes].filter(count => count > 0).length;
        if (nonZeroCount > 1) return "Split";  // More than one non-zero value means "Split"
        if (wins > 0) return "Win";
        if (losses > 0) return "Loss";
        if (pushes > 0) return "Push";
        return "No Result";  // If none have a value > 0, return "No Result"
    };

    return {
        awayCount: stats.away.count,
        awayWins: stats.away.wins,
        awayLosses: stats.away.losses,
        awayPushes: stats.away.pushes,
        awayResult: getResult(stats.away.wins, stats.away.losses, stats.away.pushes),  // Added awayResult

        homeCount: stats.home.count,
        homeWins: stats.home.wins,
        homeLosses: stats.home.losses,
        homePushes: stats.home.pushes,
        homeResult: getResult(stats.home.wins, stats.home.losses, stats.home.pushes),  // Added homeResult

        overCount: stats.over.count,
        overWins: stats.over.wins,
        overLosses: stats.over.losses,
        overPushes: stats.over.pushes,
        overResult: getResult(stats.over.wins, stats.over.losses, stats.over.pushes),  // Added overResult

        underCount: stats.under.count,
        underWins: stats.under.wins,
        underLosses: stats.under.losses,
        underPushes: stats.under.pushes,
        underResult: getResult(stats.under.wins, stats.under.losses, stats.under.pushes)  // Added underResult
    };
};


export const gatherModalInfoNFL = (teamsOrNames, teams, seeds, homeTeamId, awayTeamId, standings) => {
    const homeName = teamsOrNames?.find(team => team.GlobalTeamID === homeTeamId)?.Key;
    const awayName = teamsOrNames?.find(team => team.GlobalTeamID === awayTeamId)?.Key;
    const homeTeam = teams?.find(team => team.GlobalTeamID === homeTeamId);
    const homeWins = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Wins;
    const homeLosses = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Losses;
    const homeRecord = homeWins + '-' + homeLosses;
    let homeRank = null;
    const homeSeed = seeds?.find(seed => seed.GlobalTeamID === homeTeamId)?.seed;
    if (homeSeed) {
        homeRank = homeSeed;
    } else {
        homeRank = teams?.find(team => team.GlobalTeamID === homeTeamId)?.PlayoffRank;
    }
    const awayTeam = teams?.find(team => team.GlobalTeamID === awayTeamId);
    const awayWins = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Wins;
    const awayLosses = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Losses;
    const awayRecord = awayWins + '-' + awayLosses;
    let awayRank = null;
    const awaySeed = seeds?.find(seed => seed.GlobalTeamID === awayTeamId)?.seed;
    if (awaySeed) {
        awayRank = awaySeed;
    } else {
        awayRank = teams?.find(team => team.GlobalTeamID === awayTeamId)?.PlayoffRank;
    }

    return {
        homeName,
        awayName,
        homeRecord,
        homeRank,
        awayRecord,
        awayRank,
    }
}


export const gatherPoolPicksHeaderInfoNFL = (gameId, teamsOrNames, schedule, pageInterval) => {
    const game = schedule?.find(game => game.GlobalGameID === gameId);
    const gameStatus = game?.Status;
    const homeScore = game?.HomeScore;
    const awayScore = game?.AwayScore;
    const homeTeamId = game?.GlobalHomeTeamID;  
    const awayTeamId = game?.GlobalAwayTeamID;
    const homeName = teamsOrNames?.find(team => team.GlobalTeamID === homeTeamId)?.Key;
    const awayName = teamsOrNames?.find(team => team.GlobalTeamID === awayTeamId)?.Key;
    const middleSymobol = (gameStatus === 'Final' || gameStatus === 'F/OT') ? gameStatus : pageInterval === 4 ? 'vs' : '@';
    const homeDisplay = (gameStatus === 'Final' || gameStatus === 'F/OT') ? homeName + ' ' + homeScore : homeName;
    const awayDisplay = (gameStatus === 'Final' || gameStatus === 'F/OT') ? awayName + ' ' + awayScore : awayName;
    return { homeDisplay, awayDisplay, middleSymobol };
};


export const processChampStatsNFL = (leaderboard, seeds) => {
    const champStats = {};
    let totalPickCount = 0;

    // Initialize champStats for each conference and seed
    const conferences = ["AFC", "NFC"];
    conferences.forEach(conference => {
        for (let i = 1; i <= 7; i++) {
            const seed = seeds?.find(seed => seed.conference === conference && seed.seed === i);
            
            // Skip teams not in the bracket
            if (seed?.notInBracket) continue;

            champStats[`${conference}-${i}`] = {
                conference,
                seed: i,
                teamId: seed?.GlobalTeamID || null,
                count: 0,
                result: seed?.result || null, // Set result from seed.result
            };
        }
    });

    leaderboard?.forEach(entry => {
        // Skip if no champ
        if (!entry.champ) return;

        totalPickCount++;
        const teamId = entry.champ;

        // Find the corresponding champ entry
        const champEntry = Object.values(champStats).find(stat => stat.teamId === teamId);
        if (champEntry) {
            champEntry.count++;
        }
    });

    return {
        totalPickCount,
        champStats: Object.values(champStats),
    };
};


