import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const ActiveInactiveModal = ({ open, onClose, onSubmit, myPoolsState, gameName }) => {

    const activeText = `Do you want to move ${gameName} to the Archive tab? You will still have pool access.`;
    const inactiveText = `Do you want to move ${gameName} back to the Active tab?`;

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            BackdropProps={{
                onClick: (e) => e.stopPropagation() // Prevents closing on backdrop click
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    width: '350px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    boxShadow: 24,
                }}
            >
                {/* Image Section */}
                <Box sx={{ marginBottom: '16px', display: 'flex', justifyContent: 'center' }}>
                    <img
                        src="https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyGamingLogoWide.png"
                        alt="Pool Party Gaming"
                        style={{ width: '200px', objectFit: 'contain' }}
                    />
                </Box>

                {/* Text Section */}
                <Typography 
                    sx={{ 
                        fontSize: '16px',
                        fontWeight: '700',
                        marginBottom: '32px',
                        marginTop: '32px', 
                    }}
                >
                    {myPoolsState === 'active' ? activeText : inactiveText}
                </Typography>

                {/* Button Section */}
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onClose}
                        sx={{ width: '115px', marginRight: '32px' }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        sx={{ width: '115px' }}
                    >
                        MOVE
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ActiveInactiveModal;
