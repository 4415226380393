import React from "react";
import { Box, Typography, Button } from "@mui/material";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const NFLBarBattleForHomePage = () => {

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }
    const handleClick = (link) => {
      const url = link;
      // Check if you're already at the target location to avoid unnecessary navigation
      if (window.location.pathname !== url) {
        navigateToTop(url);
      }
    };
    return (
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '32px',
        }}
      >
      <Box>
      <Box
        className="our-pools-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 17px',
          boxSizing: 'border-box',
          gap: '16px',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'left',
          fontSize: '18.3px',
          color: '#000',
          fontFamily: 'Inter',
          maxWidth: '435px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              height: '43px',
              position: 'relative',
              letterSpacing: '-0.02em',
              lineHeight: '150%',
              display: 'inline-block',
              marginBottom: isMobile ? '0' : '24px',
            }}
          >
            <img
                loading="lazy"
                alt="Redbeards's"
                src="https://poolpartyfilestorage.blob.core.windows.net/media/NFLSuperPickemBarBattleLogo.png"
                style={{
                    width: isMobile ? '185px' : '250px', // Control size for mobile and desktop
                    height: 'auto', // Maintain aspect ratio
                    marginRight: '16px', // Space between image and text
                    verticalAlign: 'middle', // Align image with text
                    margin: '0 auto'
                }}
            />
          </Box>
        </Box>

      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '295px' : '420px',
          margin: '0 auto',
          marginTop: isMobile ? '100px' : '120px',
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 700,
            marginTop: '24px',
          }}
        >
              The Pittsburgh Bar Battle. <br/> Which Bar Will Take The Crown?
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left', // Aligns the entire text to the left
              marginTop: '10px',
              marginBottom: '8px',
            }}
          >
            <span style={{ fontWeight: 700 }}>Pick your team</span> -  Pick from 4 legendary bars: M&T’s, Coach’s, Shooters, and Redbeard’s.
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left',
            }}
          >
            <span style={{ fontWeight: 700 }}>Pick the NFL Playoffs</span> -  The Super Pick ‘Em is back. NFL Picks + The Playoff Bracket.
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              handleClick(`/super-pickem-nfl/${uriEncoded('4d26b07b-d448-401b-9fcb-2f43a4697ae6')}/HOME/HOME`)
            }            sx={{
              marginTop: '24px',
              width: '120px',
              whiteSpace: 'nowrap',
            }}
          >
            POOL HOME
          </Button>
      </Box>
      </Box>
      {!isMobile && (
        <Box>
        <img
          loading="lazy"
          alt="NFL Super Pickem Badge"
          src="https://poolpartyfilestorage.blob.core.windows.net/media/NFLSuperPickemBadge.png"
          style={{
            height: '240px',
            width: '300px',
            position: 'relative',
            objectFit: 'contain',
            marginLeft: '117px',
          }}
        />
        </Box>
      )}
      </Box>
  );
};

export default NFLBarBattleForHomePage;