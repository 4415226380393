import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGetUserData, useGetGameHook, useCreateSubgroup } from '../../BirdiePool/hooks/index';
import { useGetSuperPickemPlayoffs } from '../../../hooks/superpickem/useGetSuperPickemPlayoffs';
import { useNFLTeams, useNFLStandings, useNFLStadiums, useNFLScoresBySeason } from '../../../hooks/nfl/index';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { useGetPoolLeaderboard } from '../../../hooks/pools/useGetPoolLeaderboard';
import { useGetGameReferrals } from '../../../hooks/pools/useGetGameReferrals';
import { DateTime } from 'luxon';
import { getWeeklyRound } from '../utils/getWeeklyRound';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const NFLSuperPickemContext = createContext();

export const useNFLSuperPickemContext = () => {
    const context = useContext(NFLSuperPickemContext);
    if (!context) {
        throw new Error('useNFLSuperPickemContext must be used within a NFLSuperPickemContextProvider');
    }
    return context;
}

const NFLSuperPickemContextProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    //console.log('myUserId', myUserId);

    const { mutate: fetchUserData, data: userData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchHostGame, data: hostGameData, isLoading: hostGameLoading, isError: hostGameError, error: hostGameErrorData } = useGetGameHook();
    const { mutate: fetchNFLTTeams, data: nflTeams } = useNFLTeams();
    const { mutate: fetchNFLStandings, data: nflStandings } = useNFLStandings();
    const { mutate: fetchNFLStadiums, data: nflStadiums } = useNFLStadiums();
    const { mutate: fetchNFLScoresBySeason, data: nflScoresBySeason } = useNFLScoresBySeason();
    const { mutate: fetchLeaderboardData, data: leaderboardData, isLoading, leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetPoolLeaderboard();
    const { mutate: fetchSuperPickemPlayoffs, data: superPickemPlayoffsData } = useGetSuperPickemPlayoffs();
    const { mutate: fetchGameReferrals, data: gameReferrals } = useGetGameReferrals();
    const { mutate: createSubgroup, data: createSubgroupData } = useCreateSubgroup();
    //console.log("Leaderboard Data", leaderboardData);

    const { gameId, component, pageState } = useParams();

    const gameType = "SuperPickem";
    const league = "NFL";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateToTop = useNavigateToTop();
    const location = useLocation();

    const [loading, setLoading] = useState(true);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);
    //console.log("userData: ", userData);

     // Fetch game data second
     useEffect(() => {
        if (gameType && gameId) {
            fetchGame ( {gameType, gameID: gameId });
        }
    }, [fetchGame, gameType, gameId]);
    //console.log("gameData: ", gameData);

    const season = gameData?.season;

    const isHostGame = gameData?.gameParameters[0]?.hostGame;
    const isTeamGame = gameData?.gameParameters[0]?.teamGame;
    //console.log("Is Host Game", isHostGame, "Is Team Game", isTeamGame);
    let hostGameId = null;
    if (isTeamGame) {
        hostGameId = gameData?.gameParameters[0]?.hostGameId;
    }
    //console.log("Host Game ID", hostGameId);

    // If teamGame and hostGameId, fetch hostGame data
    useEffect(() => {
        if (isTeamGame && hostGameId) {
            fetchHostGame({ gameType, gameID: hostGameId });
        }
    }, [fetchHostGame, isTeamGame, hostGameId, gameType]);
    //console.log("Host Game Data", hostGameData);

    // If there is Game Data, fetch leaderboard
    useEffect(() => {
        if (gameData) {
            fetchLeaderboardData({ gameID: gameId });
            fetchGameReferrals({ gameID: gameId });
        }
    }, [fetchLeaderboardData, fetchGameReferrals, gameId, gameData])

    //console.log("LEADERBOARD", leaderboardData, "GAME REFERRALS", gameReferrals);

    //Error Handling for Game Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (gameError || hostGameError) {
            console.error("Error fetching game data: ", gameError ? gameErrorData : hostGameErrorData);
            if (gameErrorData?.response?.status === 401 || hostGameErrorData?.response?.status === 401) {
                // Handle token expired for either gameError or hostGameError
                dispatch(userLogoutAction({
                    logoutType: 'token-expired',
                }));
                } else {
                  navigateToTop('/404/pool-hall');
            }
        }
    }, [gameError, gameErrorData, dispatch, navigateToTop]);  
      

    // Error Handling for Leaderboard Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (leaderboardError) {
            console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
            if (leaderboardErrorData.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
            } else {
                navigateToTop('/404/pool-hall');
            }
        }
    }, [leaderboardData, leaderboardError, navigateToTop, leaderboardErrorData,dispatch]);

    const [hasFetchedData, setHasFetchedData] = useState(false);

    // Use Effect to fetch all NFL Data
    useEffect(() => {
        if (gameData && !hasFetchedData && season) {
            fetchNFLTTeams();
            fetchNFLStandings();
            fetchNFLStadiums();
            fetchNFLScoresBySeason();
            fetchSuperPickemPlayoffs({ league, season });
            setHasFetchedData(true);
        }
    }, [fetchNFLTTeams, fetchNFLStandings, fetchNFLStadiums, fetchSuperPickemPlayoffs, fetchNFLScoresBySeason, gameData, hasFetchedData, season, league]);
    //console.log("nflTeams: ", nflTeams, "nflStandings: ", nflStandings, "nflStadiums: ", nflStadiums, "superPickemPlayoffsData: ", superPickemPlayoffsData, "nflScoresBySeason: ", nflScoresBySeason);

    const schedule = superPickemPlayoffsData?.schedule;
    const info = superPickemPlayoffsData?.info;
    const games = info?.games;
    const seeds = info?.seeds;
    const rounds = info?.rounds;
    const odds = superPickemPlayoffsData?.odds;
    const nonBracketGames = info?.nonBracketGames;
    const combinedSchedule = [...(games || []), ...(nonBracketGames || [])];
    //console.log("Combined Schedule", combinedSchedule);

    let currentRound = getWeeklyRound(rounds);
    const highestRound = rounds?.length;

    //console.log("currentRound: ", currentRound, "highestRound: ", highestRound);

    const gameParameters = gameData?.gameParameters[0];
    const isGameOver = gameParameters?.isGameOver;
    const countdownText = gameParameters?.countdownText;
    const countdownBackground = gameParameters?.countdownBackground;
    const picking = gameParameters?.picking;
    const tiebreakerInfo = gameParameters?.tiebreakerInfo;
    const gameDescription = gameParameters?.gameDescription;
    const picksByInterval = gameParameters?.picksByInterval;
    const countdownDeadline = gameParameters?.countdownDeadline;
    const countdownTimer = gameParameters?.countdownTimer;
    const countdownMessage = gameParameters?.countdownMessage;
    const bracketLogo = gameParameters?.bracketLogo;
    const pickemLogo = gameParameters?.pickemLogo;
    const bracketTiebreakerInfo = gameParameters?.bracketTiebreakerInfo;
    const hostIds = gameParameters?.hostIds;
    const hostBackgroundColor = gameParameters?.hostBackgroundColor;
    const maxBracketPoints = gameParameters?.maxBracketPoints;
    const firstBowlTime = gameParameters?.firstBowlTime;
    const showMyPicksBar = gameParameters?.showMyPicksBar;
    const tShirtReward = gameParameters?.tShirtReward;
    const tShirt = gameParameters?.tShirt;
    const showMyBracketModal = gameParameters?.showMyBracketModal;
    const bracketPicksToMake = gameParameters?.bracketPicks;
    const topBracketLogo = gameParameters?.topBracketLogo;
    const bottomBracketLogo = gameParameters?.bottomBracketLogo;
    let affiliatedGamesInfo = [];
    if (isHostGame) {
        affiliatedGamesInfo = gameParameters?.affiliatedGamesInfo;
    }
    if (isTeamGame && hostGameData) {
        affiliatedGamesInfo = hostGameData?.gameParameters[0]?.affiliatedGamesInfo;
    }
    //console.log("Affiliated Games Info", affiliatedGamesInfo);


    // STATES
    const [pickState, setPickState] = useState([]);
    const [bracketState, setBracketState] = useState([]);
    const [clickState, setClickState] = useState(null);
    const [interval, setInterval] = useState(0);
    const [signUpState, setSignUpState] = useState(false);
    const [partyState, setPartyState] = useState(gameId);
    const [partyId, setPartyId] = useState(null);
    const [defaultPartyId, setDefaultPartyId] = useState(null);
    const [defaultPartyName, setDefaultPartyName] = useState(null);
    const [joinOpen, setJoinOpen] = useState(false);
    const [userAlreadyInOtherGame, setUserAlreadyInOtherGame] = useState(false);

    let doesUserBelong = null;
    const thisGame = userData?.Games?.find(game => game.gameID === gameId);
    if (thisGame) {
        doesUserBelong = true;
    } else if (isHostGame) {
        const gameMembers = gameData?.clubMembers;
        if (gameMembers) {
            doesUserBelong = gameMembers.includes(myUserId);
        }
    } else {
        doesUserBelong = false;
    }
    //console.log("Does User Belong", doesUserBelong);

    let doesUserBelongToBattle = null;
    if (isTeamGame && hostGameData) {
        const hostGameMembers = hostGameData?.clubMembers;
        //console.log("Host Game Members", hostGameMembers);
        if (hostGameMembers) {
            doesUserBelongToBattle = hostGameMembers.includes(myUserId);
        }
    }
    //console.log("Does User Belong to Battle", doesUserBelongToBattle);

    let affiliatedGame = null;
    if (isHostGame && affiliatedGamesInfo && doesUserBelong) {
        const affiliatedGameIds = affiliatedGamesInfo?.map(game => game.id);
        //console.log("Affiliated Game IDs", affiliatedGameIds);
        affiliatedGame = userData?.Games?.find(game => affiliatedGameIds?.includes(game.gameID))?.gameID;
    }
    if (isTeamGame && doesUserBelongToBattle && !doesUserBelong && hostGameData) {
        const affiliatedGameIds = affiliatedGamesInfo?.map(game => game.id);
        //console.log("Affiliated Game IDs", affiliatedGameIds);
        affiliatedGame = userData?.Games?.find(game => affiliatedGameIds?.includes(game.gameID))?.gameID;
    }

    //console.log("Affiliated Game", affiliatedGame);


    const gameAddress = gameData?.gameAddress;

    // Use Effect to set User Already In Other Game
    useEffect(() => {
        if (
            doesUserBelongToBattle !== undefined && 
            doesUserBelong !== undefined
        ) {
            if (doesUserBelongToBattle === true && doesUserBelong === false) {
                //console.log("Setting User Already In Other Game to True");
                setUserAlreadyInOtherGame(true);
            } else {
                //console.log("Resetting User Already In Other Game to False");
                setUserAlreadyInOtherGame(false);
            }
        }
    }, [doesUserBelongToBattle, doesUserBelong]);
    
    
    // Use Effect to navigat to the users correct game if they belong to the battle already
    useEffect(() => {
        if (isHostGame && doesUserBelong && affiliatedGame) {
            navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(affiliatedGame)}/HOME/HOME`);
        }
    }, [isHostGame, doesUserBelong, affiliatedGame, navigate, gameAddress]);


    // Use Effect to set interval once currentRound is determined
    useEffect(() => {
        //console.log("Setting Interval");
        if (currentRound) {
            setInterval(currentRound);
        }
    }, [currentRound]);

    // Use Effect to set Bracket Picks, if user has any
    useEffect(() => {
        if (doesUserBelong && thisGame) {
            const bracketPicks = thisGame.pool.bracketPicks;
            setBracketState(bracketPicks || []);
        }
    }, [doesUserBelong, thisGame]);

    const userSubgroups = thisGame?.subGroups || [];
    
    const gameName = gameData?.gameName;
    const gameStartTime = gameData?.gameStartTime;
    const formattedGameStartTime = DateTime.fromISO(gameStartTime, { zone: 'America/New_York' });
    const lastDayToJoin = gameData?.lastDayToJoin;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const gameRewards = gameData?.rewards || [];
    const gameLogoDisplayInfo = gameData?.gameLogoDisplayInfo;
    //console.log("Game Logo Display Info", gameLogoDisplayInfo);
    const referralPrizes = gameData?.referralPrizes;
    let textOptIn = gameData?.textOptIn;
    if (isTeamGame && hostGameData) {
        textOptIn = hostGameData?.textOptIn;
    }
    const amIOptedInToTexts = textOptIn?.includes(myUserId);
    //console.log("Am I Opted In To Texts", amIOptedInToTexts);

    const myUsername = userData?.user_name;
    const userPicksFromDB = thisGame?.pool?.picks || [];
    const userBracketPicksFromDB = thisGame?.pool?.bracketPicks || [];
    const stopPicksWarning = thisGame?.pool?.stopPicksWarning;
    const rewards = thisGame?.rewards || [];
    const joinReward = rewards?.find(reward => reward.interval === 0);

     // Utility function to check if defaultPartyIdInDB is in userSubgroups
     const isDefaultPartyInUserSubgroups = (partyId, subgroups) => {
        //console.log("Default Party ID in DB", partyId, "User Subgroups", subgroups);
        return subgroups?.some(subgroup => subgroup?.subGroupID === partyId);
    };
    
    // Get the default party ID with the additional check
    let defaultPartyIdInDB = (thisGame?.pool?.defaultParty?.length > 0)
        ? thisGame?.pool?.defaultParty
        : gameId;

    if (defaultPartyIdInDB !== gameId && !isDefaultPartyInUserSubgroups(thisGame?.pool?.defaultParty, thisGame?.subGroups || [])) {
        defaultPartyIdInDB = gameId;
    }

    const firstSubgroupId = userSubgroups[0]?.subGroupID || "";
    const firstSubgroupName = userSubgroups[0]?.name || "";
    // Get the last subgroup if there are any subgroups
    const lastIndex = userSubgroups.length - 1;
    const lastSubgroupId = lastIndex >= 0 ? userSubgroups[lastIndex]?.subGroupID || "" : "";
    const lastSubgroupName = lastIndex >= 0 ? userSubgroups[lastIndex]?.name || "" : "";
    //console.log("First Subgroup ID", firstSubgroupId, "First Subgroup Name", firstSubgroupName, "Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);


    let defaultPartyNameInDB = sponsorName ? sponsorName : "Pool Party";
    //console.log("SponsorName", sponsorName);
    if (defaultPartyIdInDB !== gameId) {
    const foundSubGroup = userSubgroups.find(subgroup => subgroup.subGroupID === defaultPartyIdInDB);
    defaultPartyNameInDB = foundSubGroup ? foundSubGroup.name : sponsorName ? sponsorName : "Pool Party";
    }


    useEffect(() => {
        //console.log("Setting Default Party ID and Name");
        if (defaultPartyIdInDB && defaultPartyNameInDB) {
          setDefaultPartyId(defaultPartyIdInDB);
          setDefaultPartyName(defaultPartyNameInDB);
        }
      }, [defaultPartyIdInDB, defaultPartyNameInDB]);


    //Use effect for setting the interval and party state based on the component and page state
    useEffect(() => {
        //console.log("Use Effect for Setting Interval and Party State");
        if (userData && gameData && component) {
            if (component === 'MY_PICKS') {
                if (pageState !== 'CURRENT') {
                    setInterval(Number(pageState));
                } else {
                    setInterval(currentRound);
                }

                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }

            if (component === 'LEADERBOARD' || component === 'POOL_PICKS') {
                // Move searchParams construction here
                const searchParams = new URLSearchParams(location.search);
                const tabParam = searchParams.get('tab');
                
                if (pageState !== gameId) {
                    const pageStateInUserSubgroups = userSubgroups.find(subgroup => subgroup.subGroupID === pageState);
                    if (pageStateInUserSubgroups) {
                        setPartyId(pageState);
                        setPartyState(pageStateInUserSubgroups.name);
                        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}${tabParam ? `?tab=${tabParam}` : ''}`);
                    } else {
                        setPartyId(gameId);
                        setPartyState(sponsorName ? sponsorName : "Pool Party");
                        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}${tabParam ? `?tab=${tabParam}` : ''}`);
                    } 
                } else {
                    setPartyId(gameId);
                    setPartyState(sponsorName ? sponsorName : "Pool Party");
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}${tabParam ? `?tab=${tabParam}` : ''}`);
                }
            }

            if (component === "HOME") {
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }
        
            if (component === "MY_PARTY") {
                if (pageState === "MY_PARTY") {
                    setPartyId(defaultPartyId);
                    setPartyState(defaultPartyName);
                    setSignUpState(true);
                } else if (pageState === "NEW") {
                    setPartyId(lastSubgroupId);
                    setPartyState(lastSubgroupName);
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(lastSubgroupId)}`);
                } else {
                    const subgroupName = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState)?.name;
                    setPartyId(pageState);
                    setPartyState(subgroupName || "Unnamed Subgroup");
                }
            }
        }
    }, [userData, gameData, component, pageState, currentRound, defaultPartyId, defaultPartyName, 
        gameAddress, gameId, lastSubgroupName, userSubgroups, sponsorName, firstSubgroupId, 
        lastSubgroupId, navigate, location.search]);

    const handleCreateSubgroup = (subgroupData) => {
        createSubgroup(subgroupData);
    };

    const nowInNewYork = DateTime.now().setZone('America/New_York');
    const lastDayToJoinDateTime = DateTime.fromISO(lastDayToJoin, { zone: 'America/New_York' });
    const hasJoinDeadlinePassed = nowInNewYork > lastDayToJoinDateTime;

    const finalGame = games?.find(game => game.round === highestRound);
    console.log("Final Game", finalGame);
    let finalGameTime = null;
    if (finalGame && finalGame.DateTime) {
        finalGameTime = DateTime.fromISO(finalGame?.DateTime, { zone: 'America/New_York' });
    }

    const hasFinalStarted = finalGameTime ? nowInNewYork > finalGameTime : false;
    console.log("Has Final Started", hasFinalStarted);
    

      //Use Effect to set loading to false (WHAT INFO GOES IN HERE?)
      useEffect(() => {
        //console.log("Game Data", gameData, "CFB Teams", cfbTeams, "CFB Names", cfbNames, "Super Pickem Playoffs Data", superPickemPlayoffsData, "Interval", interval, "Leaderboard Data", leaderboardData?.length, "Game Referrals", gameReferrals, "Pickem Logo", pickemLogo, "Bracket Logo", bracketLogo);
        if (gameData && nflTeams && nflStadiums && nflStandings && superPickemPlayoffsData && interval !== 0 && leaderboardData && gameReferrals && pickemLogo && bracketLogo && nflScoresBySeason) {
            setLoading(false);
        }
    }, [gameData, nflTeams, nflStadiums, nflStandings, superPickemPlayoffsData, leaderboardData, interval, gameReferrals, pickemLogo, bracketLogo, nflScoresBySeason]);


    const NFLSuperPickemContextValues = {
        myUserId,
        mainGameId: gameId,
        gameType,
        league,
        gameData,
        leaderboardData,
        nflTeams,
        nflStadiums,
        nflStandings,
        schedule,
        games,
        seeds,
        odds,
        rounds,
        highestRound,
        picking,
        gameDescription,
        pickState,
        setPickState,
        bracketState,
        setBracketState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        joinOpen,
        setJoinOpen,
        doesUserBelong,
        userSubgroups,
        gameAddress,
        gameName,
        gameStartTime,
        formattedGameStartTime,
        sponsorName,
        gameRewards,
        myUsername,
        userPicksFromDB,
        userBracketPicksFromDB,
        rewards,
        joinReward,
        handleCreateSubgroup,
        picksByInterval,
        season,
        userData,
        currentRound,
        hasJoinDeadlinePassed,
        isGameOver,
        stopPicksWarning,
        countdownDeadline,
        countdownTimer,
        countdownMessage,
        firstSubgroupId,
        firstSubgroupName,
        tiebreakerInfo,
        gameLogoDisplayInfo,
        gameReferrals,
        referralPrizes,
        bracketLogo,
        pickemLogo,
        bracketTiebreakerInfo,
        amIOptedInToTexts,
        countdownBackground,
        countdownText,
        hostIds,
        hostBackgroundColor,
        maxBracketPoints,
        nflScoresBySeason,
        firstBowlTime,
        nonBracketGames,
        combinedSchedule,
        showMyPicksBar,
        isHostGame,
        affiliatedGamesInfo,
        isTeamGame,
        hostGameData,
        affiliatedGame,
        userAlreadyInOtherGame,
        setUserAlreadyInOtherGame,
        hostGameId,
        tShirtReward,
        tShirt,
        bracketPicksToMake,
        topBracketLogo,
        bottomBracketLogo,
        showMyBracketModal,
        hasFinalStarted,
    }

    if (loading) {
        return <LoadingScreen />
    }

    return (
        <NFLSuperPickemContext.Provider value={NFLSuperPickemContextValues}>
            {children}
        </NFLSuperPickemContext.Provider>
    );
};

export default NFLSuperPickemContextProvider;