import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, TablePagination } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QuickPickNoGamesCard from '../CardPieces/QuickPickNoGamesCard';
import { DateTime } from 'luxon';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

const PlayerPicks = ({ leaderboardInfo, selectedQuickPickGames, currentPropIds, schedule, sponsorName, teamName, league, myUserId, userPicksFromDB }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  //console.log("Player Picks Leaderboard Info: ", leaderboardInfo);
  
  // Add pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Add pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Reset page when leaderboardInfo changes
  useEffect(() => {
    setPage(0);
  }, [leaderboardInfo]);

  // Extract headers (propId and tableHeader) from selectedQuickPickGames
  let columnHeaders = [];
  selectedQuickPickGames?.forEach((game) => {
    const gameProps = game?.props?.gameProps || [];
    const homeProps = game?.props?.homeProps || [];
    const awayProps = game?.props?.awayProps || [];
    const tiebreakerProps = game?.props?.tiebreakers || [];
    const allProps = [...gameProps, ...homeProps, ...awayProps, ...tiebreakerProps];

    const filteredProps = allProps.filter(prop => prop && currentPropIds.includes(prop.id));

    const sortedProps = currentPropIds
      .map(id => filteredProps.find(prop => prop?.id === id))
      .filter(Boolean);

    sortedProps?.forEach((prop) => {
      const propId = prop?.id;
      const propHeader = prop?.tableHeader;
      const line = prop?.line;
      const options = prop?.options;
      const optionsAbbrev = prop?.optionsAbbrev;
      const correctOption = prop?.correctOption;
      if (propId && propHeader) {
        columnHeaders.push({ propId, propHeader, line, options, optionsAbbrev, correctOption });
      }
    });
  });

  const timeNowInNewYork = DateTime.now().setZone('America/New_York');
  const hasThisGameStarted = (gameId) => {
    const thisGamesId = gameId[0];
    const gameInfo = schedule.find(game => game.GlobalGameID === thisGamesId);
    const gameTime = gameInfo?.DateTime;
    const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
    return timeNowInNewYork > gameDateTime;
  }

  const thisGamesIds = selectedQuickPickGames?.map(game => game.GlobalGameID);
  const hasGameStarted = hasThisGameStarted(thisGamesIds);
  //console.log("Has Game Started: ", hasGameStarted);


  function getAbbreviatedOption(option) {
    for (const prop of columnHeaders) {
      if (prop && Array.isArray(prop.options)) {
        const index = prop.options.indexOf(option);
        if (index !== -1) {
          return Array.isArray(prop.optionsAbbrev) ? prop.optionsAbbrev[index] : prop.options[index];
        }
      }
    }
    return null;
  }

  function getRank(n) {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = n % 100;
    const suffix = (value >= 11 && value <= 13) ? suffixes[0] : suffixes[value % 10] || suffixes[0];
    return `${n}${suffix}`;
  }

  // Calculate paginated data
  const paginatedData = leaderboardInfo?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  let userEntry = leaderboardInfo?.find(user => user.userId === myUserId);
  //console.log('usersEntry', userEntry);
  const usersPicks = userPicksFromDB;
  if (userEntry && usersPicks) {
    userEntry.picks = usersPicks;
  }
  //console.log('usersEntry AFTER', userEntry);

  return (
    <>
      {(!selectedQuickPickGames || selectedQuickPickGames.length === 0) ? (
        <>
          <QuickPickNoGamesCard 
            sponsorName={sponsorName} 
            teamName={teamName} 
            league={league} 
            isThereAGame={false}
          />
          <Box sx={{ marginTop: '40px' }} />
        </>
      ) : columnHeaders.length === 0 ? (
        <>
          <QuickPickNoGamesCard
            sponsorName={sponsorName}
            teamName={teamName}
            league={league}
            isThereAGame={true}
            playerPicksComing={true}
          />
          <Box sx={{ marginTop: '40px' }} />
        </>
      ) : (
        <>
          <Box sx={{ maxWidth: '950px', margin: '0 auto' }}>
            <TablePagination
              rowsPerPageOptions={[50, 100]}
              component="div"
              count={leaderboardInfo?.length || 0}
              rowsPerPage={rowsPerPage}
              page={Math.min(page, Math.max(0, Math.ceil((leaderboardInfo?.length || 0) / rowsPerPage) - 1))}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows"
              sx={{
                backgroundColor: 'white',
                '& .MuiTablePagination-toolbar': {
                  color: '#002129',
                },
                '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                  color: '#002129',
                }
              }}
            />
          </Box>

          <TableContainer component={Paper} sx={{ overflow: 'auto', borderRadius: 0, maxWidth: '950px', margin: '0 auto' }}>
            <Table stickyHeader>
              {/* User's Entry Row */}
              {userEntry && (
                <TableHead sx={{ backgroundColor: '#F0F1F1' }}>
                  <TableRow>
                    <TableCell 
                      sx={{ 
                        position: 'sticky', 
                        left: 0, 
                        zIndex: 10, 
                        backgroundColor: '#F0F1F1',
                        padding: '4px',
                        minWidth: '128px',
                        height: '32px',
                        textAlign: 'left',
                        marginLeft: '6px',
                      }} 
                    >
                      <Typography sx={{fontSize: '10px', fontWeight: '500'}}>
                        {userEntry.username}
                      </Typography>
                      <Typography sx={{ fontSize: '10px', fontWeight: '500', marginTop: '2px' }}>
                        {getRank(userEntry.rank)} - Pts: {userEntry.totalPoints ?? 0}
                      </Typography>
                    </TableCell>

                    {columnHeaders?.map(({ propId, line, correctOption }) => {
                      const matchingPick = userEntry?.picks?.find(pick => pick.id === propId);
                      
                      return (
                        <TableCell 
                          key={propId} 
                          sx={{ 
                            fontSize: '10px', 
                            fontWeight: 500, 
                            textAlign: 'center',
                            lineHeight: 1,
                            padding: '2px 4px',
                            height: '32px',
                            backgroundColor: '#F0F1F1',
                          }}
                        >
                          <div 
                            style={{ 
                              border: matchingPick?.result === "Win" ? '1px solid #00AA72' : (matchingPick?.result === 'Loss' || (hasGameStarted && !matchingPick)) ? '1px solid #CC293C' : '1px solid #002129',
                              backgroundColor: matchingPick?.result === "Win" ? '#CCFFEF' : matchingPick?.result === 'Loss' ? '#FFEBEB' : 'transparent',
                              padding: '2px 4px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              minWidth: isMobile ? '40px' : '60px',
                              alignItems: 'center',
                              height: '100%',
                              boxSizing: 'border-box',
                              lineHeight: 1.3,
                            }}
                          >
                            {correctOption === 'Void' ? (
                              <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: '12px' }}>
                                VOID
                              </Typography>
                            ) : (
                              matchingPick ? (
                                <>
                                  <div style={{ marginBottom: '2px' }}>
                                    {matchingPick?.tiebreaker ? 
                                      matchingPick.option :
                                      getAbbreviatedOption(matchingPick?.option)
                                    }
                                  </div>
                                  {line && (
                                    <div>
                                      {line}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <CloseIcon style={{ color: '#CC293C', fontSize: '20px' }} />
                              )
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              )}
              <TableHead>
                <TableRow>
                  <TableCell 
                    sx={{ 
                      backgroundColor: '#002129', 
                      color: '#fff', 
                      fontSize: '14px', 
                      fontWeight: 700, 
                      position: 'sticky', 
                      left: 0, 
                      zIndex: 10,
                      textAlign: 'center',
                      lineHeight: 1,
                      padding: '4px',
                    }}
                  >
                    STANDINGS
                  </TableCell>

                  {columnHeaders?.map(({ propHeader }) => (
                    <TableCell 
                      key={propHeader} 
                      sx={{ 
                        backgroundColor: '#002129', 
                        color: '#fff', 
                        fontSize: '10px', 
                        fontWeight: 500, 
                        position: 'sticky', 
                        top: 0, 
                        lineHeight: 1.2,
                        zIndex: 1,
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        padding: '4px',
                        height: 'auto',
                      }}
                    >
                      {propHeader?.split(' ').map((word, index) => (
                        <React.Fragment key={index}>
                          {word} <br/>
                        </React.Fragment>
                      ))}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedData?.map((user, index) => (
                  <TableRow 
                    key={index}
                    sx={{
                      backgroundColor: user.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                    }}  
                  >
                    <TableCell 
                      sx={{ 
                        position: 'sticky', 
                        left: 0, 
                        zIndex: 1, 
                        backgroundColor: user.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                        padding: '4px',
                        minWidth: '128px',
                        height: '32px',
                        textAlign: 'left',
                        marginLeft: '6px',
                      }} 
                    >
                      <Typography sx={{fontSize: '10px', fontWeight: '500'}}>
                        {user.username}
                      </Typography>
                      <Typography sx={{ fontSize: '10px', fontWeight: '500', marginTop: '2px' }}>
                        {getRank(user.rank)} - Pts: {user.totalPoints ?? 0}
                      </Typography>
                    </TableCell>

                    {columnHeaders?.map(({ propId, line, correctOption }) => {
                      const matchingPick = user?.picks?.find(pick => pick.id === propId);
                      
                      return (
                        <TableCell 
                          key={propId} 
                          sx={{ 
                            fontSize: '10px', 
                            fontWeight: 500, 
                            textAlign: 'center',
                            lineHeight: 1,
                            padding: '2px 4px',
                            height: '32px',
                          }}
                        >
                          <div 
                            style={{ 
                              border: matchingPick?.result === "Win" ? '1px solid #00AA72' : (matchingPick?.result === 'Loss' || (hasGameStarted && !matchingPick)) ? '1px solid #CC293C' : '1px solid #002129',
                              backgroundColor: matchingPick?.result === "Win" ? '#CCFFEF' : matchingPick?.result === 'Loss' ? '#FFEBEB' : 'transparent',
                              padding: '2px 4px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              minWidth: isMobile ? '40px' : '60px',
                              alignItems: 'center',
                              height: '100%',
                              boxSizing: 'border-box',
                              lineHeight: 1.3,
                            }}
                          >
                            {correctOption === 'Void' ? (
                              <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: '12px' }}>
                                VOID
                              </Typography>
                            ) : (!hasGameStarted && user.userId !== myUserId) ? (
                              ''
                            ) : (
                              matchingPick ? (
                                <>
                                  <div style={{ marginBottom: '2px' }}>
                                    {matchingPick?.tiebreaker ? 
                                      matchingPick.option :
                                      getAbbreviatedOption(matchingPick?.option)
                                    }
                                  </div>
                                  {line && (
                                    <div>
                                      {line}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <CloseIcon style={{ color: '#CC293C', fontSize: '20px' }} />
                              )
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ maxWidth: '950px', margin: '0 auto' }}>
            <TablePagination
              rowsPerPageOptions={[50, 100]}
              component="div"
              count={leaderboardInfo?.length || 0}
              rowsPerPage={rowsPerPage}
              page={Math.min(page, Math.max(0, Math.ceil((leaderboardInfo?.length || 0) / rowsPerPage) - 1))}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows"
              sx={{
                backgroundColor: 'white',
                '& .MuiTablePagination-toolbar': {
                  color: '#002129',
                },
                '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                  color: '#002129',
                }
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default PlayerPicks;