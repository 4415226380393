import React, { useState, useEffect } from "react";
import { useMerchContext } from "./context/MerchContext";
import { Typography, Box } from "@mui/material";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import StaticPoolHeaderBar from "../PoolHeaderBar/StaticPoolHeaderBar";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Store from './Store'; // Assuming this is where you're handling payments
// Load your Stripe publishable key
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIP_PUBLIC_KEY}`);


const MerchContainer = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <StaticPoolHeaderBar navigationText="Home" headerText="Merchandise" linkForNavigation="/" />

            <img
                src="https://poolpartyfilestorage.blob.core.windows.net/media/merch-battle-of-the-bars.png"
                alt="Pool Party Gaming"
                style={{
                    width: isMobile ? "300px" : "40%",
                    height: "auto",
                    padding: "32px 0px",
                }}
            />
            <h1 style={{color:'#002129', fontWeight:'bold', fontSize:32, textDecoration:'underline', marginBottom:25}}>The $20 Tee for Free</h1>
            <Elements stripe={stripePromise}>
                <Store />
            </Elements>
        </Box>
    );
}

export default MerchContainer;