import React, { useState } from "react";
import { Box, Button, Select, MenuItem, FormControl, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import BowlPlayerPicks from "./BowlPlayerPicks";
import BowlGameStats from "./BowlGameStats";
import ChampPicks from "./ChampPicks";
import { DateTime } from 'luxon';

const BowlPickemPoolPicks = ({
    gameAddress,
    mainGameId,
    currentInterval,
    games,
    rounds,
    seeds,
    leaderboard,
    teams,
    myUserId,
    schedule,
    gatherLogo,
    teamsOrNames,
    getPickStatsGameInfo,
    getPickStatsPickInfo,
    getChampInfo,
    hasJoinDeadlinePassed,
    countdownDeadline,
    pickemLogo,
    userPicksFromDB,
    bowlGames,
    firstBowlTime,
    hostIds,
    hostBackgroundColor,
    sponsorAbbreviation,
    partyId,
    gatherPoolPicksHeaderInfo,
    hasBracketDeadlinePassed,
    processChampStats,
}) => {
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const firstBowlTimeInNewYork = DateTime.fromISO(firstBowlTime, { zone: 'America/New_York' });
    const hasFirstBowlStarted = timeNowInNewYork > firstBowlTimeInNewYork;

    const location = useLocation();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [pageInterval, setPageInterval] = useState(currentInterval);
    //console.log("pageInterval:", pageInterval);

    const handleIntervalSelectChange = (event) => {
        const newInterval = event.target.value;
        setPageInterval(newInterval);
        navigate(`/${gameAddress}/${mainGameId}/POOL_PICKS/${newInterval}`);
    }

    // Initialize selectedTab based on URL query parameter
    const [selectedTab, setSelectedTab] = useState(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabParam = searchParams.get('tab');
        
        // Check if the tab parameter is valid (overall, bracket, or picks)
        const validTabs = ['playerpicks', 'gamestats', 'champ'];
        return validTabs.includes(tabParam) ? tabParam : 'playerpicks';
    });

    const handleTabChange = (tab) => {
        // Update local state
        setSelectedTab(tab);

        // Update URL parameters
        const searchParams = new URLSearchParams(location.search);
        
        // If tab is overall, remove the tab parameter
        if (tab === 'playerpicks') {
            searchParams.delete('tab');
        } else {
            searchParams.set('tab', tab);
        }

        // Update URL without triggering a page reload
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }

    return (
        <>
            <Box className="super-pickem-leaderboard-button-container">

                <Button
                    className={`super-pickem-leaderboard-button ${selectedTab === 'playerpicks' ? 'active' : ''}`}
                    onClick={() => handleTabChange('playerpicks')}
                >
                    Player Picks
                </Button>

                <Button
                    className={`super-pickem-leaderboard-button ${selectedTab === 'gamestats' ? 'active' : ''}`}
                    onClick={() => handleTabChange('gamestats')}
                >
                    Game Stats
                </Button>

                <Button
                    className={`super-pickem-leaderboard-button ${selectedTab === 'champ' ? 'active' : ''}`}
                    onClick={() => handleTabChange('champ')}
                >
                   Champ Picks
                </Button>

            </Box>

            {selectedTab === 'playerpicks' && (
                <BowlPlayerPicks
                    myUserId={myUserId}
                    leaderboard={leaderboard}
                    teams={teams}
                    schedule={schedule}
                    bowlGames={bowlGames}
                    gatherLogo={gatherLogo}
                    pageInterval={pageInterval}
                    teamsOrNames={teamsOrNames}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    rounds={rounds}
                    userPicksFromDB={userPicksFromDB}
                    hasFirstBowlStarted={hasFirstBowlStarted}
                    hostIds={hostIds}
                    hostBackgroundColor={hostBackgroundColor}
                    sponsorAbbreviation={sponsorAbbreviation}
                    partyId={partyId}
                    mainGameId={mainGameId}
                    gatherPoolPicksHeaderInfo={gatherPoolPicksHeaderInfo}
                />
            )}

            {selectedTab === 'gamestats' && (
                <BowlGameStats
                    leaderboard={leaderboard}
                    teams={teams}
                    schedule={schedule}
                    bowlGames={bowlGames}
                    gatherLogo={gatherLogo}
                    teamsOrNames={teamsOrNames}
                    getPickStatsGameInfo={getPickStatsGameInfo}
                    getPickStatsPickInfo={getPickStatsPickInfo}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                />
            )}

            {selectedTab === 'champ' && (
                <ChampPicks
                    myUserId={myUserId}
                    leaderboard={leaderboard}
                    teams={teams}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    gatherLogo={gatherLogo}
                    pageInterval={pageInterval}
                    teamsOrNames={teamsOrNames}
                    getChampInfo={getChampInfo}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    countdownDeadline={countdownDeadline}
                    hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                    processChampStats={processChampStats}
                />
            )}

            {selectedTab !== 'playerpicks' && pickemLogo && (
                <img
                    src={pickemLogo}
                    alt="Pickem Logo"
                    style={{
                        width: isMobile ? '185px' : '300px',
                        padding: '32px',
                        marginTop: '15px',
                        marginBottom: '15px',
                        margin: '0 auto',
                    }}
                />
            )}

        </>
    );
}

export default BowlPickemPoolPicks;