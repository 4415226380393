import React from "react";
import { Typography, Box, Modal, Button } from "@mui/material";

const AlreadyInBattleModal = ({ 
    logo, 
    gameAddress,
    affiliatedGamesInfo,
    affiliatedGame,
    userAlreadyInOtherGame,
    setUserAlreadyInOtherGame,
}) => {
    //console.log("Affiliated Games Info: ", affiliatedGamesInfo, "Affiliated Game: ", affiliatedGame, "Game Address: ", gameAddress);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const currentTeamName = affiliatedGamesInfo?.find(game => game.id === affiliatedGame)?.name;
    //console.log("Current Team Name: ", currentTeamName);

    const handleButtonClick = () => {
        //console.log("Button Clicked");
        if (currentTeamName) {
            window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(affiliatedGame)}/HOME/HOME`;
        } else {
            window.location.reload();
        }
    }

 


    return (
        <div>
            <Modal
                open={userAlreadyInOtherGame}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box
                    sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '340px',
                        minHeight: '350px',
                        height: 'auto',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2, // Padding of 16px
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                   <Typography
                        variant="h6"
                        sx={{ 
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 800,
                        }}
                    >
                        You're already on another Team!
                    </Typography>

                   <img
                        src={logo}
                        alt="Battle Logo"
                        style={{
                            width: '200px',
                        }}
                    />

                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            color: '#002129',
                            textAlign: 'center',
                            marginBottom: '8px',
                        }}
                    >
                        Sorry, but you are already on Team {currentTeamName}. You can only be on one team at a time.
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2 // Gap of 20px
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                maxWidth: '300px',
                                height: '38px',
                                whiteSpace: 'nowrap',
                                mb: 1 // MarginBottom of 8px
                            }}
                            onClick={handleButtonClick}
                        >
                            TAKE ME TO {currentTeamName?.toUpperCase()}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default AlreadyInBattleModal;