import React from 'react';
import { Box, Typography, Modal, Button } from "@mui/material";
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const SuperPickemTiebreakerModal = ({
    myTiebreakerGames,
}) => {
    const [open, setOpen] = React.useState(true);
    //console.log("My Tiebreaker Games: ", myTiebreakerGames);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 340,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
    };

    const navigateToTop = useNavigateToTop();

    // Function to set the value of cfbNationalChampionshipModel in localStorage
    function setCFBChampsLocalStorage(value) {
        localStorage.setItem('cfbNationalChampionshipModel', value.toString());
    }

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleYesClick = () => {
        setCFBChampsLocalStorage(true);
        const gameAddress = myTiebreakerGames[0]?.address;
        const tiebreakerGameId = myTiebreakerGames[0]?.id;
        const url = `/${uriEncoded(gameAddress)}/${uriEncoded(tiebreakerGameId)}/HOME/HOME`;
        navigateToTop(url);
    }

    const handleNoClick = () => {
        setCFBChampsLocalStorage(true);
        window.location.reload();
    }

  return (
    <Modal
      open={open}
      disableEscapeKeyDown
      disableBackdropClick
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography
            sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: '#002129',
                textAlign: 'center',
            }}
        >
          The Pool Tiebreaker is Open!
        </Typography>
        <Typography
            sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#002129',
                textAlign: 'center',
            }}
        >
          Pool Tiebreaker: <br/> {myTiebreakerGames[0]?.name} Pool
        </Typography>
    
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
          <Button 
            variant="contained" 
            onClick={handleYesClick}
        >
            {myTiebreakerGames[0]?.name} Pool
          </Button>
          <Button 
            variant="outlined" 
            onClick={handleNoClick}
          >
            Not Yet
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuperPickemTiebreakerModal;