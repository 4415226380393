import React from "react"; 
import { Box, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import PickEmUserStatsTeamBox from "./PickEmUserStatsTeamBox";
import "./PickEmUserStatsModal.scss";

const PickEmUserStatsWeeklyPicksTable = ({ usersPicks, startingWeekState, NFLTeams, NFLScoresBySeason, areTheseMyPicks }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    //const dummyTimeNowInNewYork = DateTime.fromObject({ year: 2024, month: 9, day: 18, hour: 12, minute: 30 }, { zone: 'America/New_York' });

    let weekToStartFrom = startingWeekState;
    if (areTheseMyPicks) {
        const highestWeek = usersPicks?.reduce((acc, pick) => Math.max(acc, pick.week), 0) ?? 0;
        weekToStartFrom = highestWeek;
    }

    const teamLogo = (teamId) => NFLTeams?.find((team) => team.GlobalTeamID === teamId)?.WikipediaLogoURL;

    const opponentDisplay = (gameId, isHomeSelected) => {
        const game = NFLScoresBySeason?.find((game) => game.GlobalGameID === gameId);
        const opponentKey = isHomeSelected ? game?.AwayTeam : game?.HomeTeam;
        return isHomeSelected ? `vs ${opponentKey}` : `@ ${opponentKey}`;
    };

    function getGameInfo(pick) {
        const gameId = pick?.gameId;
        const dateTime = pick?.dateTime;
        const isHomeSelected = pick?.isHomeSelected;

        let hasGameStarted = false;
        if (!areTheseMyPicks) {
            hasGameStarted = DateTime.fromISO(dateTime, { zone: 'America/New_York'}) < timeNowInNewYork;
        }

        if (pick?.teamId === "No Pick") {
            return {
                spread: null,
                logo: "X",
                teamText: "Miss",
                borderColor: '#CC293C',
            };
        }

        const teamId = hasGameStarted || areTheseMyPicks ? pick?.teamId : null;
        const spread = hasGameStarted || areTheseMyPicks ? pick?.spread : null;
        const result = hasGameStarted || areTheseMyPicks ? pick?.result : null;
        const logo = hasGameStarted || areTheseMyPicks ? teamLogo(teamId) : null;
        const teamText = hasGameStarted || areTheseMyPicks ? opponentDisplay(gameId, isHomeSelected) : null;

        const borderColor = result === 'Win' ? '#00AA72' 
                          : result === 'Loss' ? '#CC293C' 
                          : result === 'Push' ? '#5A5B5C' 
                          : '#002129';
        const backgroundColor = result === 'Win' ? '#CCEEE3' 
                              : result === 'Loss' ? '#F7DDE0' 
                              : result === 'Push' ? '#E5E5E5' 
                              : '#FFF';

        return { spread, logo, teamText, borderColor, backgroundColor };
    }

    // Group picks by week
    const picksByWeek = usersPicks?.reduce((acc, pick) => {
        if (!acc[pick.week]) acc[pick.week] = [];
        acc[pick.week].push(pick);
        return acc;
    }, {}) || {};

    // Create rows for each week, ensuring Week 1 is included
    const rows = [];
    let weekOneAdded = false; // Flag to check if Week 1 has been added

    for (let week = weekToStartFrom; week > 0; week--) {
        const picks = picksByWeek[week] || [];
        const weekPicks = [...picks, ...Array(5 - picks.length).fill({})]; // Ensure 5 entries per week

        // Calculate points for the week
        const weekPoints = picks.reduce((sum, pick) => {
            if (pick.result === 'Win') return sum + 1;
            if (pick.result === 'Push') return sum + 0.5;
            return sum;
        }, 0);

        if (week === 1) {
            weekOneAdded = true;
        }

        rows.push(
            <TableRow key={week} sx={{ height: '68px' }}>
                <TableCell sx={{ textAlign: 'center', fontSize: isMobile ? '14px' : '22px' }}>
                    {week}
                </TableCell>
                <TableCell>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {weekPicks.map((pick, index) => {
                            const gameInfo = pick.teamId ? getGameInfo(pick) : {};
                            return (
                                <PickEmUserStatsTeamBox 
                                    key={index}
                                    spread={gameInfo?.spread || null}
                                    logo={gameInfo?.logo || null}
                                    teamText={gameInfo?.teamText || null}
                                    borderColor={gameInfo?.borderColor || '#002129'}
                                    backgroundColor={gameInfo?.backgroundColor || '#FFF'}
                                />
                            );
                        })}
                    </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: isMobile ? '14px' : '22px' }}>
                    {weekPoints}
                </TableCell>
            </TableRow>
        );
    }

    // Ensure Week 1 is displayed even if there are no picks
    if (!weekOneAdded) {
        rows.unshift(
            <TableRow key={1} sx={{ height: '68px' }}>
                <TableCell sx={{ textAlign: 'center' }}>
                    1
                </TableCell>
                <TableCell>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {Array(5).fill({}).map((_, index) => (
                            <PickEmUserStatsTeamBox 
                                key={index}
                                spread={null}
                                logo={null}
                                teamText={null}
                                borderColor='#002129'
                                backgroundColor='#FFF'
                            />
                        ))}
                    </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                    0
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Box className="user-stats-table-container">
            <TableContainer component={Paper} sx={{ borderRadius: '0', maxHeight: '285px'}}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#002129', position: 'sticky', top: 0, zIndex: 1 }}>
                        <TableRow>
                            <TableCell sx={{ width: isMobile ? '52px' : '120px', color: '#FFF', fontWeight: 700, textAlign: 'center', fontSize: isMobile ? '12px' : '24px'}}>WEEK</TableCell>
                            <TableCell sx={{ width: isMobile ? '235px' : '600px', color: '#FFF', fontWeight: 700, textAlign: 'center', fontSize: isMobile ? '12px' : '24px'}}>PICKS</TableCell>
                            <TableCell sx={{ width: isMobile ? '40px' : '120px', color: '#FFF', fontWeight: 700, textAlign: 'center', fontSize: isMobile ? '12px' : '24px'}}>PTS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default PickEmUserStatsWeeklyPicksTable;
