import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useGetHomePageCarousel = () => {
    return useMutation({
        mutationFn: getHomePageCarousel,
        onError: (error) => {
            console.error('Error fetching home page carousel:', error);
        },
    });
};

const getHomePageCarousel = async () => {
    // Fetch data from the backend without any auth headers
    const response = await axios.get(`${apiUrl}/user/get-home-page-carousel`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response.data; // Return the carousel data
};
