import React, { createContext, useContext, useEffect, useState } from "react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";

const MerchContext = createContext();

export const useMerchContext = () => {
    const context = useContext(MerchContext);
    if (!context) {
        throw new Error("useMerchContext must be used within a MerchProvider");
    }
    return context;
}

const MerchProvider = ({ children }) => {
    const navigateToTop = useNavigateToTop();

    const [isLoading, setIsLoading] = useState(true);

    const testData = "This is a test";

    const MerchPageContextValues = {
        testData,
    };

    return (
        <MerchContext.Provider value={MerchPageContextValues}>
            {children}
        </MerchContext.Provider>
    )
};

export default MerchProvider;