import React, { useState } from 'react';
import { Box, Typography, Modal, Button } from '@mui/material'; // Import MUI components
import './GameSponsorHeader.scss'; // Import SCSS file
import useNavigateToTop from '../../hooks/general/useNavigateToTop';
import PicksNotSaved from '../PicksNotSaved/PicksNotSaved';

const GameSponsorHeader = ({ 
  sponsorAbbreviation, 
  sponsorLogo, 
  sponsorBannerText, 
  sponsorName, 
  mainGameId, 
  gameAddress, 
  myUserId, 
  setJoinOpen, 
  hasJoinDeadlinePassed, 
  unsavedPicks, 
  setUnsavedPicks, 
  userPicksFromDB, 
  setPickState,
  setPicksToEmpty,
  setBracketState,
  userBracketPicksFromDB,
  unsavedBracketPicks,
  setUnsavedBracketPicks, 
  dontShowJoinButton,
  widerLogo,
  countdownBackground,
  isTeamGame,
  sponsorCircleLogo,
  logo,
  isHostGame,
}) => {
  //console.log("Sponsor Name: ", sponsorName);
  //console.log("HAS JOIN DEADLINE PASSED: ", hasJoinDeadlinePassed);
  const navigateToTop = useNavigateToTop();
  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  const [picksNotSavedModal, setPicksNotSavedModal] = useState(false);
 
  const handleClick = () => {
    if (isHostGame || sponsorName === "Pool Party") {
      // Do nothing if it's a host game
      return;
    }

    if (unsavedPicks || unsavedBracketPicks) {
      setPicksNotSavedModal(true);
    } else {
      const pageStateToUse = `${sponsorAbbreviation} Story`
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('PRESENTED_BY')}/${uriEncoded(pageStateToUse)}`);
    }
  }

  const handleLeavePage = () => {
    if (unsavedPicks) {
        setPickState(setPicksToEmpty ? [] : userPicksFromDB);
        setUnsavedPicks(false);
    }
    if (unsavedBracketPicks) {
        setBracketState(userBracketPicksFromDB);
        setUnsavedBracketPicks(false);
    }
    setPicksNotSavedModal(false);
    const pageStateToUse = `${sponsorAbbreviation} Story`;
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('PRESENTED_BY')}/${uriEncoded(pageStateToUse)}`);
};

  
  return (
    <>
      <Box className="home-page-sponsor-header">
        {isTeamGame && (
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '700',
              color: countdownBackground ? countdownBackground : '#002129',
            }}
          >
            TEAM {sponsorName?.toUpperCase()}
          </Typography>
        )}

        {isTeamGame && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
              gap: '10px',
            }}
          >
            <img
              src={logo} 
              alt={sponsorName}
              className="home-page-sponsor-header-circle-image"
              style={{
                width: '193px',
              }}
            ></img>
            <img
              src={sponsorCircleLogo}
              alt={sponsorName}
              className="home-page-sponsor-header-circle-image"
              style={{
                width: '122px',
              }}
            ></img>
          </Box>
        )}

        {!isTeamGame && (
          <img 
            src={sponsorLogo} 
            alt={sponsorName} 
            className="home-page-sponsor-header-image" 
            onClick={handleClick} 
            style={{
              width: widerLogo ? '250px' : '',
            }}
          />
        )}

        {sponsorName !== "Pool Party" &&
            <Typography 
              variant="h6" 
              className="home-page-sponsor-header-text"
              onClick={handleClick} 
              sx={{
                marginTop: '5px',
              }}
            >
              {sponsorBannerText}
            </Typography>
        }
      </Box>
      {!myUserId && !hasJoinDeadlinePassed && !dontShowJoinButton &&
        <Box>
          <Button 
            variant="contained" 
            onClick={() => setJoinOpen(true)}
            sx={{ 
                fontSize: '14px', 
                fontWeight: '700', 
                width: '100px', 
                height: '36px', 
                whiteSpace: 'nowrap',
                marginBottom: '10px', 
            }}
          >
            JOIN POOL
          </Button>
        </Box>
      }

      <Modal
          open={picksNotSavedModal}
          onClose={() => setPicksNotSavedModal(false)}
          aria-labelledby="picks-not-saved-modal-title"
          aria-describedby="picks-not-saved-modal-description"
          >
          <Box>
              <PicksNotSaved>
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                      <Button
                          variant="contained"
                          onClick={() => setPicksNotSavedModal(false)}
                      >
                          GO BACK
                      </Button>
                      <Button
                          variant="contained"
                          onClick={handleLeavePage}
                      >
                          LEAVE PAGE
                      </Button>
                  </Box>
              </PicksNotSaved>
          </Box>
      </Modal>
    </>
  );
};

export default GameSponsorHeader;
