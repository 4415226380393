import { useEffect } from "react";
import { Box, Paper, Button, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import homeBanner from '../assets/images/hero_background.png';
import trophyImage from '../assets/images/Layer_1.svg';
import bannerContent from '../assets/images/Hero_Contents_Desktop.png';
import { useTheme } from '@mui/material/styles';
import mobileBannerContent from '../assets/images/nba_super_picke_em_hero_text.svg'
import poolPartyLogoStacked from '../assets/images/pool_party_gaming_stacked_logo.svg';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getUserDataFromLocalStorage } from "../redux/actions/loginImport";
import { useGetUserData } from "../components/BirdiePool/hooks";
import useNavigateToTop from "../hooks/general/useNavigateToTop";
import RedbeardsForHomePage from "../components/HomePageGames/RedbeardsForHomePage";
import KOTNForHomePage from "../components/HomePageGames/KOTNForHomePage";
import NFLBarBattleForHomePage from "../components/HomePageGames/NFLBarBattleForHomePage";
import NFLSuperPickemForHomePage from "../components/HomePageGames/NFLSuperPickemForHomePage";
import NattyQuickPickForHomePage from "../components/HomePageGames/NattyQuickPickForHomePage";
import CountdownClock from "../components/GameHomePage/GameHomePageComponents/CountdownClock";
import Carousel from 'react-material-ui-carousel'
import { useGetHomePageCarousel } from "../hooks/general/useGetHomePageCarousel";

export default function HomePage() {
  const userData = getUserDataFromLocalStorage();
  const userDataId = getUserDataFromLocalStorage()?.user_id;
  const { mutate: fetchUserData, data: userGamesData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
  //console.log("UserGames Data:", userGamesData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    if (userDataId) {
        fetchUserData(userDataId);
    }
}, [fetchUserData, userDataId]);

const { mutate: fetchCarouselData, data: carouselData, isLoading: carouselLoading, isError: carouselError, error: carouselErrorData } = useGetHomePageCarousel();

useEffect(() => {
  fetchCarouselData();
}, [fetchCarouselData]);

//console.log("Carousel Data:", carouselData);

const gameIDToFind = '5cfa584e-36c0-4b3f-a9ca-0fe45228295a';
const inBirdiePool = userGamesData?.Games?.find(game => game.gameID === gameIDToFind);
//console.log("In Birdie Pool??", inBirdiePool);
  const descriptionStyles = {
    color: 'var(--color-text)',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '170%',
    letterSpacing: '-0.266px',
    mt: 2,
  };
  const homeBtnStyle = {
    padding: '1rem 1.5rem',
    height: '3.2rem',
  };

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  // const carouselPanels = [
  //   {
  //     imageDesktop: 'https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyHolidayDesktopBackground.png',
  //     imageMobile: 'https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyHolidayMobileBackground.png',
  //     contentDesktop: 'https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyHolidayDesktopContent.png',
  //     contentMobile: 'https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyHolidayMobileContent.png',
  //     link: `quick-pick-nfl/${uriEncoded('049ba153-c4bf-49b7-8a67-d787aec08911')}/HOME/HOME`,
  //     width: '100%'
  //   },
  //   {
  //     imageDesktop: 'https://poolpartyfilestorage.blob.core.windows.net/media/KOTN_Background_Desktop.png',
  //     imageMobile: 'https://poolpartyfilestorage.blob.core.windows.net/media/KOTN_MOBILE_BACKGROUND.png',
  //     contentDesktop: 'https://poolpartyfilestorage.blob.core.windows.net/media/KOTN_CONTENT_DESKTOP.png',
  //     contentMobile: 'https://poolpartyfilestorage.blob.core.windows.net/media/KOTN_MOBILE_CONTENT.png',
  //     link: `/super-pickem-cfb/${uriEncoded('eb8a1c14-f716-456d-9bbd-b2e0fd90b5ec')}/HOME/HOME`,
  //     width: '80%'
  //   },
  //   {
  //     imageDesktop: 'https://poolpartyfilestorage.blob.core.windows.net/media/redBeardsDesktopBG.png',
  //     imageMobile: 'https://poolpartyfilestorage.blob.core.windows.net/media/redBeardsMobileBG.png',
  //     contentDesktop: 'https://poolpartyfilestorage.blob.core.windows.net/media/redBeardsDesktopContent.png',
  //     contentMobile: 'https://poolpartyfilestorage.blob.core.windows.net/media/redBeardsMobileContent.png',
  //     link: `/super-pickem-cfb/${uriEncoded('c172f75f-73c2-4efd-bfdf-969f54d3d4ce')}/HOME/HOME`,
  //     width: '100%'
  //   }
  // ];

  // const jsonData = JSON.stringify(carouselPanels);
  // console.log("JSON DATA", jsonData);

  return (
    <Box sx={{width: '100%' }}>
      <Carousel
        navButtonsAlwaysVisible={true}
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            bottom: 0,
            zIndex: 900,
            pointerEvents: 'auto'
          }
        }}
        indicatorIconButtonProps={{
          style: {
            // The base style for the indicators
            color: 'white',     // inactive indicator color
            opacity: 1        // slightly transparent for inactive
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#444444',     // the text/icon color (if any)
            backgroundColor: 'white', // making the active indicator "filled in"
            borderRadius: '50%',      // ensure it's a circle if desired
            opacity: 1                // make it fully opaque
          }
        }}
        sx={{
          '& .MuiButtonBase-root': {
            color: 'white'
          },
          height: { xs: '506px', sm: '506px', md: '450px', lg: '450px' },
        }}
      >
        {carouselData?.map((item, index) => (
          <Box
            key={index}
            sx={{
              // height: { xs: '506px', sm: '506px', md: '450px', lg: '450px' },
              display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' },
              alignItems: 'center',
              minHeight: '300px'
            }}
          >
            <Link to={item.link} style={{ display: 'block', flex: '1' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'column' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundImage: !isMobile ? `url(${item.imageDesktop})` : `url(${item.imageMobile})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: 'var(--color-black)',
                  minHeight: '300px',
                  height: { xs: '506px', sm: '506px', md: '450px', lg: '450px' },
                  padding: '12px',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  {!isMobile ? (
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '51px',
                        margin: '0 auto',
                        maxWidth: '1206px'
                      }}
                    >
                      <img
                        src={item.contentDesktop}
                        alt='Logo'
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        margin: '0 auto',
                        padding: '0px',
                        paddingTop: '0px'
                      }}
                    >
                      <img
                        src={item.contentMobile}
                        alt='Logo'
                        style={{
                          width: item.width ? item.width : '100%',
                          height: 'auto',
                          margin: '0px auto',
                          maxWidth: '320px',
                          marginBottom: '30px'
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Link>
          </Box>
        ))}
      </Carousel>

      <Typography
        variant='h1'
        sx={{
          color: 'var(--color-black)',
          fontStyle: 'normal',
          fontWeight: '800',
          lineHeight: '150%',
          textAlign: 'center',
          margin: '0 auto',
          marginTop: isMobile ? '32px' : '60px',
          marginBottom: '32px',
          letterSpacing: '-0.87px',
          maxWidth: isMobile ? '300px' : '650px',
          fontSize: isMobile ? '28px' : '46px',
        }}
      >
        {isMobile ? (
          <>
            The Best Sports Pools.{' '}
            <span style={{ textDecoration: 'none', position: 'relative', fontSize: '24px' }}>
              For the Best Sports Fans.
              {/* <span
                style={{
                  position: 'absolute',
                  content: '',
                  width: '100%',
                  height: '3px',
                  backgroundColor: 'black',
                  bottom: '-2px',
                  left: '0',
                }}
              /> */}
            </span>
          </>
        ) : (
          <span style={{ textDecoration: 'none', position: 'relative' }}>
            The Best Sports Pools.<br/> For the Best Sports Fans.
            {/* <span
              style={{
                position: 'absolute',
                content: '',
                width: '100%',
                height: '3px',
                backgroundColor: 'black',
                bottom: '-2px',
                left: '0',
              }}
            /> */}
          </span>
        )}
      </Typography>
      <Box
        sx={{
          width: { xs: '100%', lg: '75%' },
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ maxWidth: isMobile ? '290px' : '575px', margin: '0 auto' }}>
          <Typography
            variant='h3'
            sx={{
              color: 'var(--color-black)',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '170%',
              letterSpacing: '-0.266px',
              textAlign: 'center',
              fontSize: isMobile ? '14px' : '14px',
            }}
          >
            Pool Party Gaming is the premier sports pool platform where
            sports fans compete in the most exciting and highest quality
            sports pools. In addition to the timeless classic pools, we provide
            enhanced versions of these pools and brand new never before seen
            innovative pools and formats.
          </Typography>
        </Box>
        <Box sx={{ maxWidth: isMobile ? '290px' : '544px', margin: '0 auto' }}>
          <Typography
            variant='h3'
            sx={{
              color: 'var(--color-black)',
              fontStyle: 'normal',
              fontWeight: '800',
              lineHeight: '150%',
              letterSpacing: '-0.87px',
              textAlign: 'center',
              fontSize: isMobile ? '18px' : '30px',
              marginTop: isMobile ? '32px' : '60px',
            }}
          >
            Elite Competition Awaits
          </Typography>
          <Typography variant='body1' sx={descriptionStyles}>
          Sports pools are much more than just games. In fact,
          they bring people together through competition, entertainment,
          and the shared love of sports. Pool Party Gaming enables two
          exciting competition settings:
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Conditional flex direction
            alignItems: isMobile ? 'center' : 'flex-start', // Center items vertically on mobile
            justifyContent: 'center', // Center items horizontally
            maxWidth: '100%', // Full width container
            margin: '0 auto',
            gap: isMobile ? '20px' : '40px', // Spacing between pairs
            padding: '20px', // Optional padding for the entire box
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column', // Column layout for text pairs
              alignItems: 'center', // Center text horizontally
              textAlign: 'center',
              maxWidth: isMobile ? '100%' : '50%', // Adjust width for mobile and desktop
              gap: '10px', // Spacing between title and description
              padding: '10px', // Padding inside each pair box
            }}
          >
            <Typography
              variant='h3'
              sx={{
                color: 'var(--color-black)',
                fontStyle: 'normal',
                fontWeight: '800',
                lineHeight: '150%',
                letterSpacing: '-0.87px',
                fontSize: isMobile ? '14px' : '18px',
                marginTop: isMobile ? '20px' : '0px',
                textDecoration: 'underline',
              }}
            >
              Pool Party Sitewide Competitions
            </Typography>
            <Box
              sx={{
                width: '290px'
              }}
            >
              <Typography variant='body1' sx={descriptionStyles}>
                Compete against the entire Pool Party Universe to earn and be crowned The Pool Party Champion.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column', // Column layout for text pairs
              alignItems: 'center', // Center text horizontally
              textAlign: 'center',
              maxWidth: isMobile ? '100%' : '50%', // Adjust width for mobile and desktop
              gap: '10px', // Spacing between title and description
              padding: '10px', // Padding inside each pair box
            }}
          >
            <Typography
              variant='h3'
              sx={{
                color: 'var(--color-black)',
                fontStyle: 'normal',
                fontWeight: '800',
                lineHeight: '150%',
                letterSpacing: '-0.87px',
                fontSize: isMobile ? '14px' : '18px',
                marginTop: isMobile ? '20px' : '0px',
                textDecoration: 'underline',
              }}
            >
              My Party Competitions
            </Typography>
            <Box
              sx={{
                width: '290px'
              }}
            >
              <Typography variant='body1' sx={descriptionStyles}>
                Create custom group competitions with your invite-only participants, allowing you to conquer your friends and family.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: isMobile ? '20px' : '60px',
          width: isMobile ? '290px' : '544px',
          margin: '0 auto',
        }}
        >
          <Typography variant='body1' sx={descriptionStyles}>
            All pool entrants are automatically entered into the Pool Party universe competitions.
            Our unique platform easily allows for seamless transitions between competitions.
          </Typography>
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: isMobile ? '32px' : '46px',
            fontWeight: 800,
            marginTop: '32px'
          }}
        >
          NEW POOLS
        </Typography>
        <Typography
          sx={{
            fontSize: isMobile ? '20px' : '32px',
            fontWeight: 700,
            textAlign: 'center',
            marginBottom: '24px',
          }}
        >
          FREE to Enter.  Multiple prizes.
        </Typography>
      </Box>

        <NattyQuickPickForHomePage />
        <Box sx={{width: '100%', height: '1px', backgroundColor: '#002129', marginTop: '30px', marginBottom: '8px'}}/>
        <NFLBarBattleForHomePage />
        <Box sx={{width: '100%', height: '1px', backgroundColor: '#002129', marginTop: '30px', marginBottom: '8px'}}/>
        <NFLSuperPickemForHomePage />
        <Box sx={{width: '100%', height: '1px', backgroundColor: '#002129', marginTop: '30px', marginBottom: '8px'}}/>
        
      <Box>
        <Typography
          sx={{
            fontSize: isMobile ? '32px' : '46px',
            fontWeight: 800,
            marginTop: '64px'
          }}
        >
          ONGOING POOLS
        </Typography>
      </Box>

      <KOTNForHomePage />

      <Box sx={{width: '100%', height: '1px', backgroundColor: '#002129', marginTop: '30px', marginBottom: '8px'}}/>

      <RedbeardsForHomePage />

      <Box>
        <Typography
          variant='h1'
          sx={{
            color: 'var(--color-black)',
            fontStyle: 'normal',
            fontWeight: '900',
            lineHeight: '150%',
            textAlign: 'center',
            fontSize: isMobile ? '32px' : '46px',
            margin: '75px auto 10px auto',
            letterSpacing: '-0.87px',
          }}
        >
          Upcoming Pools
        </Typography>
        <Typography
          sx={{
            margin: '0 auto',
            textAlign: 'center',
            color: 'var(--color-black)',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '150%',
            letterSpacing: '-0.87px',
            marginBottom: '35px',
            fontSize: isMobile ? '16px' : '29px',
          }}
        >
          The Next Wave
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: isMobile ? 'nowrap' : 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px', // Adjust the gap as needed
          margin: '0 auto',
          maxWidth: '750px', // Maximum width for larger screens
        }}
      >
        <Box
          sx={{
            width: isMobile ? '295px' : 'calc(50% - 16px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'left' : 'center',
            margin: '0 auto',
          }}
        >
          <Box
            component='img'
            sx={{
              display: 'block',
              width: 35,
              marginRight: '10px',
              marginLeft: isMobile ? '25px' : '0px',
            }}
            alt='Pool Party Trophy'
            src={trophyImage}
          />
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: isMobile ? '24px' : '32px',
            }}
          >
            CFB Playoffs
          </Typography>
        </Box>
        <Box
          sx={{
            width: isMobile ? '295px' : 'calc(50% - 16px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'left' : 'center',
            margin: '0 auto',
            marginBottom: isMobile? '0' : '50px',
          }}
        >
          <Box
            component='img'
            sx={{
              display: 'block',
              width: 35,
              marginRight: '10px',
              marginLeft: isMobile ? '25px' : '0px',
            }}
            alt='Pool Party Trophy'
            src={trophyImage}
          />
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: isMobile ? '24px' : '32px',
            }}
          >
            NFL Playoffs
          </Typography>
        </Box>
        <Box
          sx={{
            width: isMobile ? '295px' : 'calc(50% - 16px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'left' : 'center',
            margin: '0 auto',
            marginBottom: '50px',
          }}
        >
          <Box
            component='img'
            sx={{
              display: 'block',
              width: 35,
              marginRight: '10px',
              marginLeft: isMobile ? '25px' : '0px',
            }}
            alt='Pool Party Trophy'
            src={trophyImage}
          />
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: isMobile ? '24px' : '32px',
            }}
          >
            And More...
          </Typography>
        </Box>
      </Box>
    </Box>













  );
}