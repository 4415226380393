import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import HomePageScreenshots from './HomePageScreenshots';

const PoolScreenshots = ({ screenshots, doesUserBelong, handleOpenModal, isTeamGame, isHostGame, hideMyParty }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const rows = screenshots?.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);
        
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new row
        }
        
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: (((doesUserBelong && !hideMyParty)  || isHostGame) && !isTeamGame) ? '#002129' : '#fff',
                padding: '16px 0px',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: (((doesUserBelong && !hideMyParty)  || isHostGame) && !isTeamGame) ? '#fff' : '#002129',
                }}
            >
                POOL PAGES
            </Typography>

            <Box className="game-home-page-media-container">
                
            <Box className="game-home-page-screen-capture-main-container">
            <Box className="game-home-page-screen-capture-row">
                {rows?.map((row, rowIndex) => (
                    <Box key={`row-${rowIndex}`} className="game-home-page-screen-capture-pair-container">
                        {row.map((item) => (
                            <Box key={item.name} className="game-home-page-screen-capture-container">
                                <Typography 
                                    className="game-home-page-screen-capture-title"
                                    sx={{
                                        color: (((doesUserBelong && !hideMyParty)  || isHostGame) && !isTeamGame) ? '#fff' : '#002129',
                                    }}
                                >
                                    {item.name}
                                </Typography>
                                <Box 
                                    className="game-home-page-screen-capture-image-box"
                                    onClick={() => handleOpenModal(item.image)}
                                >
                                    <img 
                                        src={item.image}
                                        alt={item.name}
                                        className="game-home-page-screen-capture-image" 
                                    />
                                </Box>       
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>

            </Box>

        </Box>

    );
}

export default PoolScreenshots;

