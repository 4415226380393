import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import BracketGameLive from "../BracketComponents/BracketGameLive";
import './EightTeamBracketLive.scss';
import FourRoundNav from "../../SuperPickem/MyBracket/MyBracketNavs/FourRoundNav";
import BracketChampLive from "../BracketComponents/BracketChampLive";
import MyBracketSubmitBar from "../../SuperPickem/MyBracket/MyBracketSubmitBar";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { getBracketHeaderInfo } from "../../SuperPickem/utils/bracketUtils";
import { useParams } from "react-router-dom";

const EightTeamBracketLive = ({
    mainGameId,
    gameAddress,
    teams,
    names,
    schedule,
    games,
    seeds,
    rounds,
    bracketState,
    userBracketPicksFromDB,
    getBracketInfoPostLock,
    usernameForDisplay,
    bracketLogo,
    topBracketLogo,
    bottomBracketLogo
}) => {

    const { component } = useParams();

    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width:900px)");

    const [bracketRound, setBracketRound] = useState(1);
    const [roundDash, setRoundDash] = useState(1);

    const roundOneRef = useRef(null);
    const roundTwoRef = useRef(null);
    const roundThreeRef = useRef(null);
    const roundFourRef = useRef(null);
    const roundFiveRef = useRef(null);
    //console.log("Round 1 Ref:", roundOneRef.current); // Check if the ref is set correctly
    //console.log("Round 2 Ref:", roundTwoRef.current); // Check if the ref is set correctly

    const observerRef = useRef(null); // Invisible observer ref
    const containerRef = useRef(null); // Sticky container ref
    const [isStuck, setIsStuck] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (observerRef.current && containerRef.current) {
                const observerPosition = observerRef.current.getBoundingClientRect();
                setIsStuck(observerPosition.top <= 80); // Freeze when 80px from the top
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    
    const headerInfo = getBracketHeaderInfo(rounds, userBracketPicksFromDB);
    //console.log("Header Info:", headerInfo);

    const renderGames = (count, round, spacing = 'normal') => (
        Array(count).fill().map((_, index) => (
            <React.Fragment key={index}>
                 {round === 3 && index === 0 && (
                    <img 
                        src={topBracketLogo} 
                        alt="Top Bracket Logo" 
                        style={{
                            width: '66px',
                            display: 'block',
                            margin: '0 auto',
                            marginBottom: '28.5px'
                        }}
                    />
                )}
                {round === 3 && index === 1 && (
                    <img 
                        src={bottomBracketLogo} 
                        alt="Bottom Bracket Logo"
                        style={{
                            width: '66px',
                            display: 'block',
                            margin: '0 auto',
                            marginBottom: '28.5px'
                        }}
                    />
                )}
                <BracketGameLive 
                    games={games}
                    rounds={rounds}
                    teams={teams}
                    names={names}
                    seeds={seeds}
                    schedule={schedule}
                    game={index + 1}
                    round={round}
                    bracketState={bracketState}
                    getBracketInfoPostLock={getBracketInfoPostLock}
                    skipRoundOne={true}
                    balancedBracket={true}
                />
                {index !== count - 1 && (
                    <>
                        {round === 2 && index === 0 && (
                            <img 
                                src={topBracketLogo} 
                                alt="Top Bracket Logo" 
                                style={{
                                    width: '66px',
                                    display: 'block',
                                    margin: '0 auto',
                                    marginTop: '20px',
                                    marginBottom: '-25px',
                                }}
                            />
                        )}
                        {round === 2 && index === 2 && (
                            <img 
                                src={bottomBracketLogo} 
                                alt="Bottom Bracket Logo"
                                style={{
                                    width: '66px',
                                    display: 'block',
                                    margin: '0 auto',
                                    marginTop: '20px',
                                    marginBottom: '-25px',
                                }}
                            />
                        )}
                        <div className={`eight-team-bracketlive-game-spacer${spacing === 'large' ? '-large' : ''}`} />
                    </>
                )}
            </React.Fragment>
        ))
    );

    const renderConnector = (className) => (
        <div className={`eight-team-bracketlive-connector ${className}`} />
    );


    

    return (
        
        <div>
            {isMobile && (
                <div style={{ position: 'relative', zIndex: 10 }}>
                {/* Invisible observer div */}
                    <div
                        ref={observerRef}
                        style={{
                            height: '1px',
                            position: 'relative',
                        }}
                    />
                    {/* Sticky container */}
                    
                    <div
                        ref={containerRef}
                        className={`sticky-scroll-container ${isStuck ? 'stuck' : ''}`}
                        style={{
                            height: '25px',
                            backgroundColor: '#fff',
                            width: '100%',
                            position: isStuck ? 'fixed' : 'relative', // Dynamically set sticky behavior
                            top: isStuck ? '80px' : '0',
                            marginBottom: '16px',
                            zIndex: 10,
                        }}
                    >
                        {/* Place FourRoundNav inside */}
                        <FourRoundNav
                            rounds={rounds}
                            bracketRound={bracketRound}
                            setBracketRound={setBracketRound}
                            roundDash={roundDash}
                            setRoundDash={setRoundDash}
                            setRoundDashPosition={{
                                2: () => roundTwoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }),
                                3: () => roundThreeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                                4: () => roundFourRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                                5: () => roundFiveRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                            }}
                            skipRoundOne={true}
                        />
                    </div>
                </div>
            )}

            {component === 'BRACKET' && usernameForDisplay && (
                <Typography sx={{ fontSize: '24px', fontWeight: '700', textAlign: 'center', marginTop: isMobile ? '50px' : '16px' }}>
                    {usernameForDisplay}'s Bracket
                </Typography>
            )}
            
            <div className="eight-team-bracketlive">
                {/* First round - left side */}
                {/*                 
                <div className="eight-team-bracketlive-column eight-team-bracketlive-column-games-first" ref={roundOneRef}>
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '30px', position: 'relative'  }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700'}}>{headerInfo[0]?.name}</Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>Points: {headerInfo[0]?.points} of {headerInfo[0]?.total}</Typography>
                    </Box>
                    {renderGames(4, 1)}
                </div>

                <div className="eight-team-bracketlive-column">
                    {renderConnector('eight-team-bracketlive-connector-first')}
                    {renderConnector('eight-team-bracketlive-connector-first-two')}
                </div>
                 */}

                {/* Second round - left side */}
                <div className="eight-team-bracketlive-column eight-team-bracketlive-column-games-second" ref={roundTwoRef}>
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '23.5px', position: 'relative'}}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700', whiteSpace: 'nowrap' }}>{headerInfo[1]?.name}</Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>Points: {headerInfo[1]?.points} of {headerInfo[1]?.total}</Typography>
                    </Box>
                    {renderGames(4, 2, 'two')}
                </div>

                {/* Second round connectors */}
                <div className="eight-team-bracketlive-column">
                    {renderConnector('eight-team-bracketlive-connector-second')}
                    {renderConnector('eight-team-bracketlive-connector-second')}
                </div>

                {/* Third round connectors - left */}
                <div className="eight-team-bracketlive-column">
                    {renderConnector('eight-team-bracketlive-connector-third')}
                </div>

                {/* Semi-finals */}
                <div className="eight-team-bracketlive-column eight-team-bracketlive-column-games-third" ref={roundThreeRef}>
                        <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '16px', position: 'relative'}}>
                            <Typography sx={{ fontSize: '24px', fontWeight: '700', whiteSpace: 'nowrap'  }}>{headerInfo[2]?.name}</Typography>
                            <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>Points: {headerInfo[2]?.points} of {headerInfo[2]?.total}</Typography>
                        </Box>
                    {renderGames(2, 3, 'large')}
                </div>

                {/* Third round connectors - right */}
                <div className="eight-team-bracketlive-column">
                    {renderConnector('eight-team-bracketlive-connector-third eight-team-bracketlive-connector-third-right')}
                </div>

                {/* Final connector */}
                <div className="eight-team-bracketlive-column">
                    {renderConnector('eight-team-bracketlive-connector-final')}
                </div>

                {/* Championship game */}
                <div className="eight-team-bracketlive-column eight-team-bracketlive-column-games-final" ref={roundFourRef}>
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '88px', position: 'relative'}}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700', whiteSpace: 'nowrap'  }}>{headerInfo[3]?.name}</Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>Points: {headerInfo[3]?.points} of {headerInfo[3]?.total}</Typography>
                    </Box>
                    <Box>
                        <img
                            src={bracketLogo}
                            alt="Bracket Logo"
                            style={{
                                width: '55px',
                                margin: '0 auto',
                                paddingBottom: '71px',
                            }}
                        />
                    </Box>
                    {renderGames(1, 4)}
                </div>

                {/* Championship connector */}
                <div className="eight-team-bracketlive-column eight-team-bracketlive-column-games-champ">
                    <Box sx={{ width: '230px', marginBottom: '216px', paddingBottom: '10px', position: 'relative'}}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700', whiteSpace: 'nowrap'  }}>Champ</Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>&nbsp;</Typography>
                    </Box>
                    {/* {renderConnector('eight-team-bracketlive-connector-champ')} */}
                
                    
                    <Box ref={roundFiveRef}>
                        <BracketChampLive
                            games={games}
                            rounds={rounds}
                            teams={teams}
                            names={names}
                            seeds={seeds}
                            schedule={schedule}
                            bracketState={bracketState}
                            getBracketInfoPostLock={getBracketInfoPostLock}
                        />
                    </Box>
                </div>
                
            </div>
        </div>
    );
};

export default EightTeamBracketLive;