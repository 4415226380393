import React, { useState, useEffect } from 'react';
import { useNFLSurvivorContext } from '../context/NFLSurvivorContext';
import  { DateTime } from "luxon";
import '../../NFLPickEm/NFLPickEmStyles/PickEmStyles.scss';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Box, Modal, Button } from '@mui/material';
import { useSaveSurvivorPicks } from '../../../hooks/pools/useSaveSurvivorPicks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DuplicatePickModal from '../../GameModals/SurvivorModals/DuplicatePickModal';
import SurvivorSubmittedPickModal from '../../GameModals/SurvivorModals/SurvivorSubmittedPickModal';
import SurvivorSubmitErrorModal from '../../GameModals/SurvivorModals/SurvivorSubmitErrorModal';
import InfoIcon from '@mui/icons-material/Info';
import NFLMoreInfoModal from '../../GameModals/MoreInfoModals/Football/NFLMoreInfoModal';
import SurvivorCantSubmitPick from '../../GameModals/SurvivorModals/SurvivorCantSubmitPick';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import { has } from 'lodash';

const NFLSurvivorCard = ({ thisGamesId, deadline, thisWeeksPickLocked, moreInfoOpen, setMoreInfoOpen, selectedGame, setSelectedGame, isThisNonPickWeek }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();

    // Finds and returns the sportsbook name of an NFL team given its GlobalTeamID
    // @param {Array} NFLTeams - The list of NFL teams
    // @param {number} globalTeamID - The GlobalTeamID of the team
    // @returns {string|null} The sportsbook name of the team or null if not found
    const nflSportsbookName = (globalTeamID) => {
        const team = NFLTeams?.find(team => team.GlobalTeamID === globalTeamID);
        const teamKey = team?.Key;
        const teamName = team?.Name;
        if (teamKey && (teamKey.startsWith("LA") || teamKey.startsWith("NY"))) {
            return teamKey.slice(0, 2) + " " + teamName;
        } 
        // else if (teamKey === "WAS") {
        //     return "WAS Command"
        // }
        else {
            return teamKey + " " + teamName;
        }
    };

    // Finds and returns the stadium information given its StadiumID
    // @param {Array} NFLStadiums - The list of NFL stadiums
    // @param {number} stadiumID - The StadiumID of the stadium
    // @returns {object|null} The stadium information object or null if not found
    const nflStadiumInfoByID = (stadiumID) => {
        const stadium = NFLStadiums?.find(stadium => stadium.StadiumID === stadiumID);
        return stadium ? stadium : null;
    };

    /**
     * Formats a date string to the format "THURS OCT 26" in EST.
     *
     * @param {string} dateString - The date string in ISO format (e.g., "2024-10-26T20:20:00").
     * @returns {string} - The formatted date string.
     */
    function formatDate(dateString) {
        // Parse the date string into a DateTime object and set it to the EST time zone
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

        // Format the date part and convert to uppercase
        return date.toFormat('EEE MMM d').toUpperCase();
    }

    /**
     * Formats a date string to the format "THURS OCT 26 - 8:20 PM EST".
     *
     * @param {string} dateString - The date string in ISO format (e.g., "2024-10-26T20:20:00").
     * @returns {string} - The formatted date and time string.
     */
    function formatDateTime(dateString) {
        // Parse the date string into a DateTime object and set it to the EST time zone
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

        // Format the date part and convert to uppercase
        const formattedDate = date.toFormat('EEE MMM d').toUpperCase();

        // Format the time part
        let formattedTime = date.toFormat('h:mm a');

        // Check if the time is midnight
        if (formattedTime === '12:00 AM') {
            formattedTime = 'TBD'; // Set to TBD if midnight
        }

        // Combine the date and time parts
        let dateTimeString = `${formattedDate} - ${formattedTime}`;

        // Append EST only if time is not TBD
        if (formattedTime !== 'TBD') {
            dateTimeString += ' EST';
        }

        // Return the formatted date and time string
        return dateTimeString;
    }


        const formatDateStringWithAt = (dateString) => {
            const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });
            const formattedDate = date.toFormat("ccc M/d");
            const formattedTime = date.toFormat("h:mma 'ET'").replace('AM', 'am').replace('PM', 'pm');
        
            // Combine the date and time parts with an "@" symbol
            const formattedDateTime = `${formattedDate} @ ${formattedTime}`;
        
            return formattedDateTime;
        };
        

    const { NFLTeams, 
        NFLStandings, 
        NFLStadiums, 
        NFLGameOddsByWeek, 
        NFLSchedules, 
        NFLScoresBySeason, 
        myUserId,
        pickEmState, 
        setPickEmState,
        week,
        startingWeekState, 
        userPicksFromDB,
        doesUserHaveALoss,
        lossWeek,
        mainGameId, 
        lockedBackgroundColor,
        winBackgroundColor,
        lossBackgroundColor,
        pushBackgroundColor,
        winNotSelectedBackgroundColor,
        pushNotSelectedBackgroundColor,
        finalizedMyPickBorderColor,
        finalizedPushBorderColor,
        finalizedWinBorderColor,
        lockedPickFontColor,
        notSelectedStartedFontColor,
        notSelectedPushFontColor,
        topLeftLiveColor,
        topLeftNotSelectedColor,
        topLeftSelectedColor
    } = useNFLSurvivorContext();

    
    const { mutate, isLoading, isError, error, isSuccess } = useSaveSurvivorPicks();
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [submittedPickData, setSubmittedPickData] = useState([]);
    const [submitError, setSubmitError] = useState(null);
    const [picksSaved, setPicksSaved] = useState(false);
    const [removeTooLateMessage, setRemoveTooLateMessage] = useState('');
    
    const submittedGame = submittedPickData?.find(pick => pick.week === week);
    //console.log("SUBMITTED GAME:", submittedGame);
    const submittedLogo = submittedGame?.teamId ? NFLTeams?.find(team => team.GlobalTeamID === submittedGame?.teamId)?.WikipediaLogoURL : null;
    const submittedTeamName = submittedGame?.teamId ? nflSportsbookName(submittedGame?.teamId) : null;
    //console.log("SUBMITTED PICK DATA:", submittedPickData);
    const submittedGameFromSchedule = NFLScoresBySeason?.find(game => game.GlobalGameID === submittedGame?.gameId);
    const isSubmittedPickHome = submittedGameFromSchedule?.GlobalHomeTeamID === submittedGame?.teamId;
    const opponentKey = isSubmittedPickHome ? submittedGameFromSchedule?.AwayTeam : submittedGameFromSchedule?.HomeTeam;
    const restOfDisplay = isSubmittedPickHome ? `vs ${opponentKey}` : `@ ${opponentKey}`;
    const submittedGameTime = submittedGame?.dateTime;
    const submittedGameBeforeDeadline = DateTime.fromISO(submittedGameTime) < DateTime.fromISO(deadline);
    let submittedChangeTime = null;
    if (submittedGameBeforeDeadline) {
        submittedChangeTime = formatDateStringWithAt(submittedGameTime);
    } else {
        submittedChangeTime = formatDateStringWithAt(deadline);
    }
    //console.log("Deadline:", deadline);
    
    const handleSubmit = async (data, newPick) => {
        //console.log("SUBMITTING PICKS:", data);
        const picksData ={
            picks: data,
            userID: myUserId,
            gameType: "Survivor",
            gameId: mainGameId,
            league: "NFL",
            newPick: newPick,
        }
        try {
            await mutate(picksData, {
                onSuccess: async (response) => {
                    //console.log("Response:", response);
                    const { invalidGames, savedPicks, deadlinePassed } = response;
                    //console.log("Invalid Game:", invalidGames, "Saved Picks:", savedPicks, "Deadline Passed:", deadlinePassed);
                    //console.log("Picks Submitted Successfully");
                    if (savedPicks && savedPicks.length > 0) {
                        setSubmittedPickData(data);
                        setSubmitModalOpen(true);
                        setPicksSaved(true);
                    } else if (invalidGames && invalidGames.length > 0) {
                        setSubmitModalOpen(true);
                        setPicksSaved(false);
                        setRemoveTooLateMessage('The schedule gametime has passed.');
                    } else if (deadlinePassed) {
                        setSubmitModalOpen(true);
                        setPicksSaved(false);
                        setRemoveTooLateMessage('The pick deadline has passed.');
                    }
                },
                onError: (error) => {
                    // Check if the error status is 401
                    if (error.response && error.response.status === 401) {
                        dispatch(userLogoutAction({
                            logoutType: 'token-expired'
                        }));
                    } else {
                        console.log("Error Submitting Picks:", error);
                        setSubmitError(error);
                        setSubmitModalOpen(true);
                    }
                }
            });
        } catch (error) {
            console.log("Error Submitting Picks:", error);
            setSubmitError(error);
            setSubmitModalOpen(true);
        }
    };
    
    
    //console.log("NFL Pick Em Card Game Id:", thisGamesId, NFLStandings);
    //console.log("Stadiums:", NFLStadiums);
    const gameInfo = NFLScoresBySeason?.find(game => game.GlobalGameID === thisGamesId);
    const homeSpread = gameInfo?.PointSpread;
    const homeKey = gameInfo?.HomeTeam;
    const awayKey = gameInfo?.AwayTeam;
    let spreadDisplay = null;
    if (homeSpread === 0)   {
        spreadDisplay = "PK";
    } else if (homeSpread > 0) {
        spreadDisplay = `${awayKey} (-${homeSpread})`;
    } else if(homeSpread < 0) {
        spreadDisplay = `${homeKey} (${homeSpread})`;
    } else {
        spreadDisplay = "No Line";
    }
    //console.log("SPREAD DISPLAY:", spreadDisplay);

    const [topSpreadClicked, setTopSpreadClicked] = useState(false);
    const [bottomSpreadClicked, setBottomSpreadClicked] = useState(false);
    const [duplicatePickModalOpen, setDuplicatePickModalOpen] = useState(false);
    const [duplicatePickId, setDuplicatePickId] = useState(null);

    const gameTime = gameInfo?.DateTime;
    const gameDateTime = DateTime.fromISO(gameInfo?.DateTime, { zone: 'America/New_York' });
    const nowInNY = DateTime.now().setZone('America/New_York');
    const formattedGameDate = formatDate(gameTime);
    const formattedGameDateTime = formatDateTime(gameTime);

    const hasGameStarted = nowInNY >= gameDateTime;
    const gameStatus = gameInfo?.Status;
    //const gameClosed = gameInfo?.IsClosed;
    const gameFinalized = ((gameStatus === "Final" || gameStatus === "F/OT") && gameInfo?.HomeScore !== null && gameInfo?.AwayScore !== null);
    //console.log("game status:", gameStatus, "game closed:", gameClosed, "game finalized:", gameFinalized);
    //console.log("GAME TIME:", gameTime, "GAME DATE TIME:", gameDateTime, "NOW IN NY:", nowInNY, "HAS GAME STARTED?", hasGameStarted);
    
    const homeId = gameInfo?.GlobalHomeTeamID;
    const homeLogo = NFLTeams?.find(team => team.GlobalTeamID === homeId)?.WikipediaLogoURL;
    const homeStandings = NFLStandings?.find(team => team.GlobalTeamID === homeId);
    const homeWins = homeStandings?.Wins || 0;
    const homeLosses = homeStandings?.Losses || 0;
    const homeTies = homeStandings?.Ties || 0;
    const homeRecord = homeTies > 0 ? `${homeWins}-${homeLosses}-${homeTies}` : `${homeWins}-${homeLosses}`;
    const homeName = nflSportsbookName(homeId);
    const homeAbbreviation = gameInfo?.HomeTeam;
    const homeScore = gameInfo?.HomeScore;
    const awayId = gameInfo?.GlobalAwayTeamID;
    const awayLogo = NFLTeams?.find(team => team.GlobalTeamID === awayId)?.WikipediaLogoURL;
    const awayStandings = NFLStandings?.find(team => team.GlobalTeamID === awayId);
    const awayWins = awayStandings?.Wins || 0;
    const awayLosses = awayStandings?.Losses || 0;
    const awayTies = awayStandings?.Ties || 0;
    const awayRecord = awayTies > 0 ? `${awayWins}-${awayLosses}-${awayTies}` : `${awayWins}-${awayLosses}`;
    const awayName = nflSportsbookName(awayId);
    const awayAbbreviation = gameInfo?.AwayTeam;
    //console.log("HOME AND AWAY SPREAD:", homeSpread, awaySpread);
    const awayScore = gameInfo?.AwayScore;

    const topAlreadySelected = pickEmState?.find(pick => pick.teamId === awayId  && pick.week !== week);
    const topSelectedCurrentWeek = pickEmState?.find(pick => pick.teamId === awayId && pick.week === startingWeekState);
    const topSelectedCurrentWeekTime = topSelectedCurrentWeek?.dateTime;
    const topSelectedCurrentWeekTimeInNY = DateTime.fromISO(topSelectedCurrentWeekTime, { zone: 'America/New_York' });
    const topSelectedCurrentWeekGameStarted = nowInNY >= topSelectedCurrentWeekTimeInNY;
    const topSelectedInWeekThatsOver = pickEmState?.find(pick => pick.teamId === awayId && pick.week < startingWeekState);
    const topAlreadyLocked = topSelectedCurrentWeekGameStarted || topSelectedInWeekThatsOver;
    const bottomAlreadySelected = pickEmState?.find(pick => pick.teamId === homeId && pick.week !== week);
    const bottomSelectedCurrentWeek = pickEmState?.find(pick => pick.teamId === homeId && pick.week === startingWeekState);
    const bottomSelectedCurrentWeekTime = bottomSelectedCurrentWeek?.dateTime;
    const bottomSelectedCurrentWeekTimeInNY = DateTime.fromISO(bottomSelectedCurrentWeekTime, { zone: 'America/New_York' });
    const bottomSelectedCurrentWeekGameStarted = nowInNY >= bottomSelectedCurrentWeekTimeInNY;
    const bottomSelectdInWeekThatsOver = pickEmState?.find(pick => pick.teamId === homeId && pick.week < startingWeekState);
    const bottomAlreadyLocked = bottomSelectedCurrentWeekGameStarted || bottomSelectdInWeekThatsOver;
    // if (topAlreadySelected) {
    //     console.log("TOP ALREADY SELECTED:", topAlreadySelected, awayAbbreviation, topAlreadyLocked);
    // }
    // if (bottomAlreadySelected) {
    //     console.log("BOTTOM ALREADY SELECTED:", bottomAlreadySelected, homeAbbreviation, bottomAlreadyLocked);
    // }

    const channel = gameInfo?.Channel;
    const stadiumID = gameInfo?.StadiumID;
    const stadiumInfo = nflStadiumInfoByID(stadiumID);
    const stadiumName = stadiumInfo?.Name;
    const stadiumCity = stadiumInfo?.City;
    const stadiumState = stadiumInfo?.State;
    const stadiumCountry = stadiumInfo?.Country;
    const stadiumCapacity = stadiumInfo?.Capacity;
    const stadiumSurface = stadiumInfo?.PlayingSurface;
    const stadiumType = stadiumInfo?.Type;
    //console.log("STADIUM INFO:", stadiumID, stadiumInfo);

   
    // Use Effect to set picks on page load
    // useEffect(() => {
    //     //console.log("USER PICKS Filtered:", userPicks);
    //     if (userPicks) {
    //         const isAwaySelected = userPicks?.find(pick => pick.teamId === awayId && pick.week === week);
    //         const isHomeSelected = userPicks?.find(pick => pick.teamId === homeId && pick.week === week);
    //         //console.log("IS AWAY SELECTED?", isAwaySelected, "IS HOME SELECTED?", isHomeSelected);
    //         if (isAwaySelected) {
    //             setTopSpreadClicked(true);
                
    //         } 
    //         if (isHomeSelected) {
    //             setBottomSpreadClicked(true);
    //         } 
    //     }
       

    //     //console.log("Away Locked Spread:", awayLockedSpread, "Home Locked Spread:", homeLockedSpread);
    // }, [userPicks, awayId, homeId]);

    useEffect(() => {
        const isAwayInState = userPicksFromDB?.find(pick => pick.teamId === awayId && pick.week === week);
        const isHomeInState = userPicksFromDB?.find(pick => pick.teamId === homeId && pick.week === week);
        const isTopClicked = topSpreadClicked;
        const isBottomClicked = bottomSpreadClicked;

        if (doesUserHaveALoss && lossWeek < week) {
            setTopSpreadClicked(false);
            setBottomSpreadClicked(false);
        } else {

        if (isAwayInState && !isTopClicked) {
            setTopSpreadClicked(true);
        }
        if (!isAwayInState && isTopClicked) {
            setTopSpreadClicked(false);
        }
        if (isHomeInState && !isBottomClicked) {
            setBottomSpreadClicked(true);
        }
        if (!isHomeInState && isBottomClicked) {
            setBottomSpreadClicked(false);
        }
    }
    },   [userPicksFromDB, awayId, homeId, doesUserHaveALoss, lossWeek]);


    let whoWon = null;
    if (gameFinalized) {
        whoWon = (homeScore === awayScore) ? "Push" : (homeScore > awayScore) ? homeId : awayId;
    }
    let myResult = null;
    if (gameFinalized) {    
        myResult = ((whoWon === awayId && topSpreadClicked) || (whoWon === homeId && bottomSpreadClicked)) ? "Win" : (whoWon === "Push" ? "Loss" : "Loss");
        //console.log("MY RESULT:", myResult, awayAbbreviation, homeAbbreviation);
    }


    let topLeftText = formattedGameDateTime;
    if (gameStatus === "InProgress" || (hasGameStarted && !gameFinalized)) {
        topLeftText = "LIVE";
    } else if (gameFinalized) {
        topLeftText = formattedGameDate;
    }
    let topRightText = channel;
    if (gameFinalized) {
        topRightText = gameStatus;
    }
    let bottomLeftText = stadiumState ? `${stadiumCity}, ${stadiumState}` : `${stadiumCity}, ${stadiumCountry}`;
    if (gameFinalized && (topSpreadClicked || bottomSpreadClicked)) {
        if (myResult === "Win") {
            bottomLeftText = "My Result: WIN";
        } else if (myResult === "Loss") {
            bottomLeftText = "My Result: LOSS";
        } else if (myResult === "Push") {
            bottomLeftText = "My Result: PUSH";
        }
    }
    let bottomRightText = spreadDisplay;
    



    let topButtonBackgroundColor = null;
    let bottomButtonBackgroundColor = null;
    let topButtonBorderColor = null;
    let bottomButtonBorderColor = null;
    let topButtonFontColor = null;
    let bottomButtonFontColor = null;
    let buttonBackgroundColor = null;
        if ((gameStatus === "InProgress") && topSpreadClicked) {  //Should this be: hasGameStarted && !gameFinalized && topSpreadClicked
            topButtonBackgroundColor = lockedBackgroundColor;
            topButtonBorderColor = finalizedMyPickBorderColor;
            topButtonFontColor = "#FFF";
        } else if ((gameStatus === "InProgress") && bottomSpreadClicked) {  //Should this be: hasGameStarted && !gameFinalized && bottomSpreadClicked
            bottomButtonBackgroundColor = lockedBackgroundColor;
            bottomButtonBorderColor = finalizedMyPickBorderColor;
            bottomButtonFontColor = "#FFF";
        } else if (myResult === "Win") {
            if (topSpreadClicked) {
                topButtonBackgroundColor = winBackgroundColor;
                topButtonBorderColor = winBackgroundColor;
                topButtonFontColor = "#FFF";
                bottomButtonBackgroundColor = "#FFF";
                bottomButtonBorderColor = "#FFF";
            }
            if (bottomSpreadClicked) {
                bottomButtonBackgroundColor = winBackgroundColor;
                bottomButtonBorderColor = winBackgroundColor;
                bottomButtonFontColor = "#FFF";
                topButtonBackgroundColor = "#FFF";
                topButtonBorderColor = "#FFF";
            }
        } else if (myResult === "Loss") {
            if (topSpreadClicked) {
                topButtonBackgroundColor = lossBackgroundColor;
                topButtonBorderColor = lossBackgroundColor;
                topButtonFontColor = "#FFF";
                bottomButtonBackgroundColor = "#FFF";
                bottomButtonBorderColor = "#FFF";
            }
            if (bottomSpreadClicked) {
                bottomButtonBackgroundColor = lossBackgroundColor;
                bottomButtonBorderColor = lossBackgroundColor;
                bottomButtonFontColor = "#FFF";
                topButtonBackgroundColor = "#FFF";
                topButtonBorderColor = "#FFF";
            }
        } 

        if (gameFinalized && !topSpreadClicked && !bottomSpreadClicked) {
            if (whoWon === awayId) {
                topButtonBackgroundColor = winNotSelectedBackgroundColor;
                topButtonBorderColor = winBackgroundColor;
                bottomButtonBackgroundColor = "#FFF";
                bottomButtonBorderColor = "#FFF";
                } else if (whoWon === homeId) {
                bottomButtonBackgroundColor = winNotSelectedBackgroundColor;
                bottomButtonBorderColor = winBackgroundColor;
                topButtonBackgroundColor = "#FFF";
                topButtonBorderColor = "#FFF";
                } else if (whoWon === "Push") {
                topButtonBackgroundColor = pushNotSelectedBackgroundColor;
                topButtonBorderColor = "#002129";
                bottomButtonBackgroundColor = pushNotSelectedBackgroundColor;
                bottomButtonBorderColor = "#002129";
            }
        }


    let topBorderColor = "#E5E5E5";
    if (topSpreadClicked || bottomSpreadClicked) {
        if (gameStatus === "InProgress" || (hasGameStarted && !gameFinalized)) {  //SHOULD THIS BE: (hasGameStarted && !gameFinalized) 
            topBorderColor = lockedBackgroundColor;
        } else if (myResult === "Win") {
            topBorderColor = winBackgroundColor;
        } else if (myResult === "Loss") {
            topBorderColor = lossBackgroundColor;
        } else if (myResult === "Push") {
            topBorderColor = pushBackgroundColor;
        }  else if (!hasGameStarted) {
            topBorderColor = "#00AAD1";
        }
    }
    


    let topLeftTextColor = "#232425";
    if (doesUserHaveALoss && lossWeek < week) {
        topLeftTextColor = "#CC293C";
    } else if (gameStatus === "InProgress" || (hasGameStarted && !gameFinalized)) {
        topLeftTextColor = topLeftLiveColor;
    } else if (topSpreadClicked || bottomSpreadClicked) {
        topLeftTextColor = topLeftSelectedColor;
    } else if (gameFinalized && (!topSpreadClicked || !bottomSpreadClicked)) {
        topLeftTextColor = topLeftNotSelectedColor;
    }

    let topRightTextColor = "#232425";
    if (doesUserHaveALoss && lossWeek < week) {
        topRightTextColor = "#CC293C";
    } else if (topSpreadClicked || bottomSpreadClicked) {
        topRightTextColor = topLeftSelectedColor;
    } else if (gameFinalized && (!topSpreadClicked || !bottomSpreadClicked)) {
        topRightTextColor = topLeftNotSelectedColor;
    } 

    let bottomLeftTextColor = null;
    if (doesUserHaveALoss && lossWeek < week) {
        bottomLeftTextColor = "#CC293C";
    } else if (gameFinalized && (topSpreadClicked || bottomSpreadClicked)) {
        if (myResult === "Win") {
            bottomLeftTextColor = winBackgroundColor;
        } else if (myResult === "Loss") {
            bottomLeftTextColor = lossBackgroundColor;
        } else if (myResult === "Push") {
            bottomLeftTextColor = pushBackgroundColor;
        }
    } 

    let bottomRightTextColor = null;
    if (doesUserHaveALoss && lossWeek < week) {
        bottomRightTextColor = "#CC293C";
    }

    const handleMoreInfoClick = () => {

        const gameInfoForModal = {
            homeLogo: homeLogo,
            awayLogo: awayLogo,
            homeName: homeAbbreviation,
            awayName: awayAbbreviation,
            homeRecord: homeRecord,
            awayRecord: awayRecord,
            homeScore: homeScore,
            awayScore: awayScore,
            gameLine: homeSpread < 0 ? `(${homeAbbreviation} ${homeSpread})` : homeSpread === 0 ? `Pick 'Em` : homeSpread > 0 ? `(${awayAbbreviation} ${homeSpread * -1})` : "No Line",
            gameTime: gameTime,
            gameDateTime: gameDateTime,
            status: gameStatus,
            gameId: thisGamesId,
            homeId: homeId,
            awayId: awayId,
    };

    //console.log("GAME INFO FOR MODAL:", gameInfoForModal);

    setSelectedGame(gameInfoForModal);
    setMoreInfoOpen(true);
    };

    


    const handleTopClick = () => {
        if (hasGameStarted || gameStatus === "InProgress" || topSpreadClicked) {
            return;
        }
        if (topAlreadySelected) {
            // console.log('Top Spread Clicked in Future.  Add Modal Here');
            setDuplicatePickModalOpen(true);
            setDuplicatePickId(awayId);
            return;
        }
        //console.log('Top Spread Clicked');
        const pick = {
            gameId: thisGamesId,
            teamId: awayId,
            dateTime: gameInfo.DateTime,
            week: gameInfo.Week,
            result: "Pending",
            isHomeSelected: false
        };
        const existingPickIndex = pickEmState.findIndex(p => p.gameId === pick.gameId && p.teamId === pick.teamId && p.week === pick.week);
        const otherPickIndex = pickEmState.findIndex(p => p.teamId !== pick.teamId && p.week === pick.week);
        let picksToSave = [];
        if (otherPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(otherPickIndex, 1);
            setPickEmState([...updatedPickEmState, pick]);
            picksToSave = [...updatedPickEmState, pick];
            setBottomSpreadClicked(false);
        } else if (existingPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(existingPickIndex, 1);
            setPickEmState(updatedPickEmState);
            picksToSave = updatedPickEmState;
        } else {
            setPickEmState([...pickEmState, pick]);
            picksToSave = [...pickEmState, pick];
        }
        setTopSpreadClicked(!topSpreadClicked);
        handleSubmit(picksToSave, pick);
    };

    const handleBottomClick = () => {
        if (hasGameStarted || gameStatus === "InProgress" || bottomSpreadClicked) {
            //console.log('Game Has Started');
            return;
        }
        if (bottomAlreadySelected) {
            //console.log('Bottom Spread Clicked in Future.  Add Modal Here');
            setDuplicatePickModalOpen(true);
            setDuplicatePickId(homeId);
            return;
        }
        //console.log('Bottom Spread Clicked');
        const pick = {
            gameId: thisGamesId,
            teamId: homeId,
            dateTime: gameInfo.DateTime,
            week: gameInfo.Week,
            result: "Pending",
            isHomeSelected: true
        };
        const existingPickIndex = pickEmState?.findIndex(p => p.gameId === pick.gameId && p.teamId === pick.teamId && p.week === pick.week);
        const otherPickIndex = pickEmState?.findIndex(p => p.teamId !== pick.teamId && p.week === pick.week);
        let picksToSave = [];
        if (otherPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(otherPickIndex, 1);
            setPickEmState([...updatedPickEmState, pick]);
            picksToSave = [...updatedPickEmState, pick];
            setTopSpreadClicked(false);
        } else if (existingPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(existingPickIndex, 1);
            setPickEmState(updatedPickEmState);
            picksToSave = updatedPickEmState;
        } else {
            setPickEmState([...pickEmState, pick]);
            picksToSave = [...pickEmState, pick];
        }
        setBottomSpreadClicked(!bottomSpreadClicked);
        handleSubmit(picksToSave, pick);
    };

    const handleModalAccept = (homeOrAway) => {
        const pick = {
            gameId: thisGamesId,
            teamId: duplicatePickId,
            dateTime: gameInfo.DateTime,
            week: gameInfo.Week,
            result: "Pending"
        };
        //console.log("MODAL Pick:", pick);
    
        let updatedPickEmState = [...pickEmState];
    
        // Find and remove existing pick for the same team in a different week
        const existingPickOtherWeekIndex = updatedPickEmState.findIndex(p => p.teamId === duplicatePickId && p.week !== gameInfo.Week);
        if (existingPickOtherWeekIndex !== -1) {
            updatedPickEmState.splice(existingPickOtherWeekIndex, 1);
        }
    
        // Find and remove existing pick for the current week with a different team
        const otherPickSameWeekIndex = updatedPickEmState.findIndex(p => p.teamId !== duplicatePickId && p.week === gameInfo.Week);
        if (otherPickSameWeekIndex !== -1) {
            updatedPickEmState.splice(otherPickSameWeekIndex, 1);
        }
    
        // Add the new pick
        updatedPickEmState.push(pick);
        setPickEmState(updatedPickEmState);
    
        // Update the UI state
        if (homeOrAway === "home") {
            setBottomSpreadClicked(true);
            setTopSpreadClicked(false);
        } else {
            setTopSpreadClicked(true);
            setBottomSpreadClicked(false);
        }
    
        setDuplicatePickModalOpen(false);
    
        // Submit the picks
        handleSubmit(updatedPickEmState, pick);
    };
    
    

    return (
        <>
        <div className={`pickem-card${!isMobile ? '-desktop' : ''}`}>
            <div className={`top-border${!isMobile ? '-desktop' : ''}`}  style={{ backgroundColor: topBorderColor }}>
            <div 
                className={`info-icon${!isMobile ? '-desktop' : ''}`} 
                onClick={() => {handleMoreInfoClick()}}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}
            >
                <InfoIcon sx={{ fontSize: isMobile ? '15px' : '20px', color: topLeftTextColor}}/>
            </div>
            <div className={`top-left-text${!isMobile ? '-desktop' : ''}`} style={{ color: topLeftTextColor, width: isMobile ? '167px' : '175px', marginLeft: isMobile ? '13px' : '-24px', whiteSpace: 'nowrap' }}>
                {topLeftText}
            </div>
                <div className={`top-right-text${!isMobile ? '-desktop' : ''}`} style={{ color: topRightTextColor }}>{topRightText}</div>
            </div>

            <div className="middle-box">
                <div className={`team-row${!isMobile ? '-desktop' : ''}`}>
                    <div className={`logo${!isMobile ? '-desktop' : ''}`}><img src={awayLogo} alt="Top Team Logo" /></div>
                    <div className={`name-and-record${!isMobile ? '-desktop' : ''}`} style={{ fontWeight: whoWon === awayId ? 'bold' : '' }}>
                        {awayName} ({awayWins}-{awayLosses}{awayTies > 0 && `-${awayTies}`})
                        {(topAlreadySelected && !topAlreadyLocked) && (
                            <span style={{ fontStyle: 'italic', color: '#757575', fontSize: '10px',  }}>
                                &nbsp;- Wk {topAlreadySelected?.week}
                            </span>
                        )}
                    </div>
                    {
                        doesUserHaveALoss && lossWeek < week && !gameFinalized ? (
                            <div className={`out-text${!isMobile ? '-desktop' : ''}`} style={{ height: isMobile ? '21px' : '27px', width: isMobile ? '53px' : '68px', fontSize: '12px', color: '#CC293C', fontWeight: '700', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                OUT
                            </div>
                        ) : (isThisNonPickWeek && !gameFinalized) || (thisWeeksPickLocked && !gameFinalized && !topSpreadClicked) || ((hasGameStarted || gameStatus === "InProgress") && !gameFinalized && !bottomSpreadClicked && !topSpreadClicked) ? (
                            <div className={`pick-button${!isMobile ? '-desktop' : ''}`} style={{ backgroundColor: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`}>
                                    <LockIcon style={{ fontSize: 18, color: '#757575' }} />
                                </div>
                            </div>
                        ) : !hasGameStarted && !thisWeeksPickLocked && topAlreadyLocked && !topSpreadClicked ? (
                            <div className={`empty-button${!isMobile ? '-desktop' : ''}`} style={{ fontSize: '12px', color: '#757575', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                Wk {topAlreadySelected?.week}
                            </div>
                        ) : (
                            <div className={`pick-button${!isMobile ? '-desktop' : ''} ${topSpreadClicked ? 'clicked' : ''}`} onClick={handleTopClick} style={{ backgroundColor: topButtonBackgroundColor, border: `1px solid ${topButtonBorderColor}`, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`} style={{ color: topButtonFontColor, fontSize: '12px', fontWeight: whoWon === awayId ? "bold" : '500' }}>
                                    {gameFinalized ? awayScore : awayKey}
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className={`team-row${!isMobile ? '-desktop' : ''}`}>
                    <div className={`logo${!isMobile ? '-desktop' : ''}`}><img src={homeLogo} alt="Bottom Team Logo" /></div>
                    <div className={`name-and-record${!isMobile ? '-desktop' : ''}`} style={{ fontWeight: whoWon === homeId ? 'bold' : '' }}>
                        {homeName} ({homeWins}-{homeLosses}{homeTies > 0 && `-${homeTies}`})
                        {(bottomAlreadySelected && !bottomAlreadyLocked) && (
                            <span style={{ fontStyle: 'italic', color: '#757575', fontSize: '10px' }}>
                                &nbsp;- Wk {bottomAlreadySelected?.week}
                            </span>
                        )}
                    </div>                
                    {
                        doesUserHaveALoss && lossWeek < week && !gameFinalized ? (
                            <div className={`out-text${!isMobile ? '-desktop' : ''}`} style={{ height: isMobile ? '21px' : '27px', width: isMobile ? '53px' : '68px', fontSize: '12px', color: '#CC293C', fontWeight: '700', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                OUT
                            </div>
                        ) : (isThisNonPickWeek && !gameFinalized) || (thisWeeksPickLocked && !gameFinalized && !bottomSpreadClicked) || ((hasGameStarted || gameStatus === "InProgress") && !gameFinalized && !bottomAlreadyLocked && !topSpreadClicked) ? (
                            <div className={`pick-button${!isMobile ? '-desktop' : ''}`} style={{ backgroundColor: "#FFF", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`}>
                                    <LockIcon style={{ fontSize: 18, color: '#757575' }} />
                                </div>
                            </div>
                        ) : !hasGameStarted && !thisWeeksPickLocked && bottomAlreadyLocked && !bottomSpreadClicked ? (
                            <div className={`empty-button${!isMobile ? '-desktop' : ''}`} style={{ fontSize: '12px', color: '#757575', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                Wk {bottomAlreadySelected?.week}
                            </div>
                        ) : (
                            <div className={`pick-button${!isMobile ? '-desktop' : ''} ${bottomSpreadClicked ? 'clicked' : ''}`} onClick={handleBottomClick} style={{ backgroundColor: bottomButtonBackgroundColor, border: `1px solid ${bottomButtonBorderColor}`, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`} style={{ color: bottomButtonFontColor, fontSize: '12px', fontWeight: whoWon === homeId ? "bold" : '500' }}>
                                    {gameFinalized ? homeScore : homeKey}
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>

            <div className={`bottom-border${!isMobile ? '-desktop' : ''}`}>
                <div className={`bottom-left-text${!isMobile ? '-desktop' : ''}`} style={{ color: bottomLeftTextColor }}>{bottomLeftText}</div>
                <div className={`bottom-right-text${!isMobile ? '-desktop' : ''}`} style={{ color: bottomRightTextColor }}>{bottomRightText}</div>
            </div>
        </div>

        <div>
                {duplicatePickModalOpen && (
                    <DuplicatePickModal
                        duplicatePickModalOpen={duplicatePickModalOpen}
                        setDuplicatePickModalOpen={setDuplicatePickModalOpen}
                        duplicatePickId={duplicatePickId}
                        handleModalAccept={handleModalAccept}
                        homeId={homeId}
                        homeName={homeName}
                        homeLogo={homeLogo}
                        awayName={awayName}
                        awayLogo={awayLogo}
                        topAlreadySelected={topAlreadySelected}
                        bottomAlreadySelected={bottomAlreadySelected}
                    />
                )}
            
            {submitModalOpen && !submitError && picksSaved && (
                <SurvivorSubmittedPickModal
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                    week={week}
                    submitError={submitError}
                    submittedTeamName={submittedTeamName}
                    submittedLogo={submittedLogo}
                    restOfDisplay={restOfDisplay}
                    submittedChangeTime={submittedChangeTime}
                    submittedGameBeforeDeadline={submittedGameBeforeDeadline}
                />
            )}
            {submitModalOpen && !submitError && !picksSaved && (
                 <SurvivorCantSubmitPick 
                    submitModalOpen={submitModalOpen}
                    week={week}
                    removeTooLateMessage={removeTooLateMessage}
                 />
            )}
            {submitModalOpen && submitError && (
                <SurvivorSubmitErrorModal
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                    submitError={submitError}
                    setSubmitError={setSubmitError}
                />
            )}
            {moreInfoOpen && (
                <NFLMoreInfoModal
                    moreInfoOpen={moreInfoOpen}
                    setMoreInfoOpen={setMoreInfoOpen}
                    selectedGame={selectedGame}
                    setSelectedGame={setSelectedGame}
                    NFLTeams={NFLTeams}
                    NFLScoresBySeason={NFLScoresBySeason}
                />
            )}
        </div>
    </>




    );


  };

export default NFLSurvivorCard;