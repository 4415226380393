import React, { useState, useEffect } from "react";
import { TextField, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./CardPieces.scss";

const SingleInput = ({ 
    pickState, 
    setPickState, 
    isPropSelected, 
    interval, 
    hasGameStarted, 
    isGameFinal, 
    prop, 
    selectedGameId, 
    result,
    customBorderColor, 
}) => {
    let id = prop?.id;
    const globalGameId = selectedGameId;
    const propChoice = prop?.propChoice;
    const correctOption = prop?.correctOption;
    const isTiebreaker = prop?.tiebreaker;

    const [isPickInState, setIsPickInState] = useState(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const isPickInState = pickState?.find(pick => pick.gameId === globalGameId && pick.id === id);
        setIsPickInState(!!isPickInState);
        if (isPickInState) {
            setInputValue(isPickInState.option); // Set the input value to the existing pick value if it exists
        }
    }, [pickState, globalGameId, id]);

    const handleChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Replace non-numeric characters
        setInputValue(value);

        if (value === '') {
            // Remove the pick if the input is cleared
            const updatedPicks = pickState.filter((pick) => !(pick.gameId === globalGameId && pick.id === id));
            setPickState(updatedPicks);
        } else {
            const numericValue = Number(value); // Convert the value to a number
            const newPick = {
                id: id,
                propChoice: propChoice,
                option: numericValue, // Save the number
                gameId: globalGameId,
                interval: interval,
            };

            if (isTiebreaker) {
                newPick.tiebreaker = true;
            }

            const existingPickIndex = pickState?.findIndex(
                (pick) => pick.propChoice === propChoice && pick.gameId === globalGameId && pick.id === id
            );

            if (existingPickIndex !== -1) {
                // If the pick already exists, update it
                const updatedPicks = [...pickState];
                updatedPicks[existingPickIndex] = newPick;
                setPickState(updatedPicks);
            } else {
                // If no pick exists, add the new one
                setPickState([...pickState, newPick]);
            }
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRight: customBorderColor ? `1px solid ${customBorderColor}` : '1px solid #002129',
                borderLeft: customBorderColor ? `1px solid ${customBorderColor}` : '1px solid #002129',
            }}
        >
            {!hasGameStarted && (
            <TextField
                value={inputValue}
                onChange={handleChange}
                inputProps={{
                    inputMode: 'numeric',
                    maxLength: 4, // Allow up to 4 digits
                    pattern: '[0-9]*',
                }}
                sx={{
                    width: '80px', // Set width
                    height: '51px', // Set height
                    '& input': {
                        textAlign: 'center', // Center text inside the input
                    },
                }}
                autoComplete="off" // Disable autocomplete
                variant="outlined"
            />
            )}
            {hasGameStarted && (
                isPickInState ? (
                    <Box
                        sx={{
                            width: '80px',
                            height: '51px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                alignItems: 'center',
                                fontSize: '24px !important',
                                fontWeight: '700 !important',
                            }}
                        >
                            {inputValue}
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: '80px',
                            height: '51px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#CC293C'
                        }}
                    >
                        <CloseIcon sx={{ fontSize: '36px' }} />
                    </Box>
                )
            )}

        </Box>
    );
};

export default SingleInput;
