import React, { useState, useEffect } from "react";
import { Box, Modal, Button, Typography } from "@mui/material";
import bracketTrophy from "../../../../assets/images/bracketTrophy.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CFBTeamsSelectedBox from "./CFBTeamSelectedBox";
import CFBTeamAvailableBox from "./CFBTeamAvailableBox";
import { DateTime } from "luxon";

const CFBSurvivorUserStatsModal = ({
    statsModalOpen,
    setStatsModalOpen,
    myUsername,
    lossWeek,
    startingWeekState,
    currentWeeksDeadlineDateTime,
    CFBTeams,
    CFBNames,
    areTheseMyStats,
    usersPicks,
    doesUserHaveACCLoss,
    lossWeekACC,
    doesUserHaveBigTenLoss,
    lossWeekBigTen,
    doesUserHaveBig12Loss,
    lossWeekBig12,
    doesUserHaveSECLoss,
    lossWeekSEC,
    teamFilter
}) => {
    //console.log("User Picks: ", usersPicks);
    //console.log("Does User Have ACC Loss: ", doesUserHaveACCLoss, "Does User Have Big 12 Loss: ", doesUserHaveBig12Loss, "Does User Have Big Ten Loss: ", doesUserHaveBigTenLoss, "Does User Have SEC Loss: ", doesUserHaveSECLoss);
    //console.log("Current Week's Deadline: ", currentWeeksDeadlineDateTime, "Starting Week State: ", startingWeekState);
    const [conferenceFilter, setConferenceFilter] = useState(teamFilter);
    const [conferenceTeams, setConferenceTeams] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const CFBConferences = [
        { Name: "Atlantic Coast", Abbreviation: "ACC", ConferenceID: 3 },
        { Name: "Big 12", Abbreviation: "Big 12", ConferenceID: 5 },
        { Name: "Big Ten", Abbreviation: "Big Ten", ConferenceID: 6 },
        { Name: "SEC", Abbreviation: "SEC", ConferenceID: 17 },
    ];

    function gatherConferenceTeams(conferenceIDs) {
        if (!Array.isArray(conferenceIDs)) {
            conferenceIDs = [conferenceIDs];
        }

        const conferenceTeams = [];
        for (let i = 0; i < CFBTeams?.length; i++) {
            const team = CFBTeams[i];
            if (conferenceIDs.includes(team.ConferenceID)) {
                conferenceTeams.push(team.GlobalTeamID);
            }
        }
        return conferenceTeams;
    }

    useEffect(() => {
        if (conferenceFilter !== "Top 25" && conferenceFilter !== "All Games") {
            const conference = CFBConferences?.find(conf => conf.Abbreviation === conferenceFilter);
            const conferenceIDs = conference?.ConferenceID;
            const conferenceTeams = gatherConferenceTeams(conferenceIDs);
            setConferenceTeams(conferenceTeams);
        }
    }, [CFBTeams, conferenceFilter]);

    const handleClose = () => setStatsModalOpen(false);

    const getConferenceStatus = (conference) => {
        switch (conference) {
            case "ACC":
                return doesUserHaveACCLoss;
            case "Big 12":
                return doesUserHaveBig12Loss;
            case "Big Ten":
                return doesUserHaveBigTenLoss;
            case "SEC":
                return doesUserHaveSECLoss;
            default:
                return false;
        }
    };

    // Function to get team logo URL by teamId
    const teamLogo = (teamId) => {
        // Handle the "No Pick" case explicitly
        if (teamId === "No Pick") {
            return "X";
        }

        // Find the team in the CFBTeams array
        const team = CFBTeams?.find(team => team.GlobalTeamID === teamId);
        // Return the team logo URL if the team is found
        return team?.TeamLogoUrl || "";
    };

    const shortDisplayName = (teamId) => {
        if (teamId === "No Pick") {
            return "Out";
        }
        const team = CFBNames?.find(team => team.GlobalTeamID === teamId);
        return team?.ShortDisplayName;
    };
    

    const timeInNewYorkNow = DateTime.now().setZone('America/New_York');
    const currentWeeksDeadline = currentWeeksDeadlineDateTime;
    const hasThisWeeksDeadlinePassed = currentWeeksDeadline ? timeInNewYorkNow > currentWeeksDeadline : false;
    
    const filteredPicks = usersPicks?.filter(pick => {
        if (areTheseMyStats) {
            // For "My Stats" case, just filter by conference
            return pick.conference === conferenceFilter;
        } else {
            if (hasThisWeeksDeadlinePassed) {
                // If the deadline has passed, include all picks up to and including the current week
                return pick.conference === conferenceFilter && pick.week <= startingWeekState;
            } else {
                // If the deadline has not passed, include picks up to the previous week
                // and include current week picks only if the pick's dateTime has passed
                return (
                    pick.conference === conferenceFilter &&
                    (pick.week < startingWeekState || 
                     (pick.week === startingWeekState && timeInNewYorkNow > DateTime.fromISO(pick.dateTime, { zone: 'America/New_York'})))      
                );
            }
        }
    });
    
    
    //console.log("FILTERED PICKS: ", filteredPicks);

   // Filter out teams that have a pick with result not equal to 'Pending'
    const availableTeams = conferenceTeams?.filter(team => {
        const isPicked = filteredPicks?.some(pick => {
            if (hasThisWeeksDeadlinePassed) {
                // If the deadline has passed, exclude picks up to and including the current week
                return pick.teamId === team && pick.week <= startingWeekState;
            } else {
                // If the deadline has not passed, exclude picks from previous weeks,
                // and also exclude picks from the current week if their dateTime has passed
                return pick.teamId === team && 
                    (pick.week < startingWeekState || 
                    (pick.week === startingWeekState && timeInNewYorkNow > DateTime.fromISO(pick.dateTime, { zone: 'America/New_York'})));
            }
        });
        return !isPicked;
    });


    //console.log("AVAILABLE TEAMS: ", availableTeams);

    // Function to chunk the array into rows of up to 7 items
    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const availableTeamsRows = chunkArray(availableTeams, 7);

    function isTeamInUsersPicks(teamId) {
        if (areTheseMyStats) {
        return usersPicks.some(pick => pick.teamId === teamId);
        } else {
            if (hasThisWeeksDeadlinePassed) {
                return usersPicks?.some(pick => pick.teamId === teamId && pick.week <= startingWeekState);
            } else {
                return usersPicks?.some(pick => pick.teamId === teamId && pick.week < startingWeekState);
            }
        }
    }


    

    // Generate data for 14 weeks
    const weeks = Array.from({ length: 14 }, (_, i) => i + 1);

    return (
        <Modal
            open={statsModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: isMobile ? '359px' : '1115px',
                    height: isMobile ? 'auto' : '885px',
                    maxHeight: isMobile ? '635px' : '997px',
                    padding: '16px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    borderRadius: '8px',
                    backgroundColor: '#FFF',
                    boxShadow: '2px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25), -2px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                    outline: 'none',
                }}
            >
                <Box
                    sx={{
                        height: isMobile ? '16px' : '140px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                    }}
                >
                    <img
                        src={bracketTrophy}
                        alt='Trophy'
                        style={{ width: isMobile ? '13px' : '30px', height: isMobile ? '16px' : '36px' }} // Adjust size as needed
                    />
                    <Typography
                        variant="h1"
                        sx={{ 
                            fontSize: isMobile ? '16px' : '36px',
                            fontWeight: 700, 
                        }}
                    >
                        {myUsername}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        maxWidth: isMobile ? '300px' : '500px',
                        margin: '0 auto', // Centering the parent box
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            maxWidth: isMobile ? '300px' : '500px',
                        }}
                    >
                        {["ACC", "Big 12", "Big Ten", "SEC"].map((conference) => (
                            <Box key={conference} sx={{ textAlign: 'center' }}>
                                <Typography
                                    component="span"
                                    onClick={() => setConferenceFilter(conference)}
                                    sx={{
                                        display: 'block',
                                        padding: isMobile ? '8px 16px' : '12px 30px',
                                        borderRadius: '6px',
                                        backgroundColor: conferenceFilter === conference ? '#E8E8E8' : 'transparent',
                                        color: getConferenceStatus(conference) ? '#CC293C' : '#002129',
                                        fontSize: isMobile ? '12px' : '18px',
                                        fontWeight: '700',
                                        cursor: 'pointer',
                                        marginRight: isMobile ? '0px' : '20px',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                    }}
                                >
                                    {conference}
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'block',
                                            fontSize: isMobile ? '10px' : '14px',
                                            color: getConferenceStatus(conference) ? '#CC293C' : '#002129',
                                            fontWeight: '600',
                                        }}
                                    >
                                        {getConferenceStatus(conference) ? 'Out' : 'Alive'}
                                    </Box>
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            color: '#002129',
                            fontSize: isMobile ? '16px' : '24px',
                            fontWeight: '700',
                            textDecoration: 'underline',
                        }}
                    >
                        {conferenceFilter} Picks
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(7, 1fr)',
                        gap: '16px',
                        width: '100%',
                        maxWidth: '894px',
                    }}
                >
                    {weeks?.map(week => {
                        const pick = filteredPicks?.find(pick => pick.week === week);
                        return (
                            <CFBTeamsSelectedBox
                                key={week}
                                weekPicked={week}
                                logo={teamLogo(pick?.teamId)} // Use teamLogo function
                                result={pick ? pick?.result : 'Pending'} // Replace with actual result or default
                                abbreviation={pick ? shortDisplayName(pick?.teamId) : ''} // Replace with actual team abbreviation or default
                            />
                        );
                    })}
                </Box>

                <Box>
                    <Typography
                        sx={{
                            color: '#002129',
                            fontSize: isMobile ? '16px' : '24px',
                            fontWeight: '700',
                            textDecoration: 'underline',
                        }}
                    >
                        {conferenceFilter} Teams Available
                    </Typography>
                </Box>

                {getConferenceStatus(conferenceFilter) ? (
                    <Typography
                        sx={{
                            fontSize: isMobile ? '16px' : '20px',
                            color: '#CC293C',
                            fontWeight: '700',
                        }}
                    >
                        Eliminated from the {conferenceFilter}.
                    </Typography>
                ) : (

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    {availableTeamsRows?.map((row, rowIndex) => (
                        <Box
                            key={rowIndex}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '16px',
                                flexWrap: 'wrap',
                                width: '100%',
                            }}
                        >
                            {row?.map((teamId) => (
                                <CFBTeamAvailableBox
                                key={teamId}
                                logo={teamLogo(teamId)}
                                color={isTeamInUsersPicks(teamId)}
                                abbreviation={shortDisplayName(teamId)}
                                />
                            ))}
                        </Box>
                    ))}
                </Box>
                )}


                <Button
                    variant="contained"
                    onClick={handleClose}
                    sx={{ marginTop: '16px' }}
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );
}

export default CFBSurvivorUserStatsModal;
