import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme, Typography, Select, MenuItem, FormControl } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const TiebreakerPartyDropdown = ({
    mainGameId,
    userSubgroups,
    gameName,
    myTiebreakerGames,
    partyId,
    setPartyId,
    partyState,
    setPartyState,
    gameAddress,
}) => {
    let nameOptions = [{ name: gameName, id: mainGameId }];
    //console.log("Party State: ", partyState);

    if (myTiebreakerGames?.length > 0) {
        const tiebreakerNames = myTiebreakerGames.map(tiebreaker => ({
            name: tiebreaker.name,
            id: tiebreaker.id
        }));
        nameOptions = [...nameOptions, ...tiebreakerNames];
    }

    if (userSubgroups?.length > 0) {
        const subgroupNames = userSubgroups.map(subgroup => ({
            name: subgroup.name,
            id: subgroup.subGroupID
        }));
        nameOptions = [...nameOptions, ...subgroupNames];
    }

    const navigate = useNavigate();
    const { gameId, component } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const uriEncoded = (link) => encodeURI(`${encodeURIComponent(link)}`);

    // Find the currently selected option based on partyId
    const selectedOption = nameOptions.find(option => option.id === partyId) || nameOptions[0];

    const handleChange = (e) => {
        const selectedId = e.target.value;
        const selectedName = nameOptions.find(option => option.id === selectedId)?.name;
        
        setPartyId(selectedId);
        setPartyState(selectedName);

        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(selectedId)}`);
    };

    return (
        <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            background: "#FFC60A", 
            height: isMobile ? '39px' : '50px', 
            borderBottom: "2px solid #000", 
            margin: '0 auto' 
        }}>
            <Typography 
                sx={{ 
                    fontSize: isMobile ? '20px' : '26px', 
                    fontWeight: '700', 
                    marginRight: '10px' 
                }}
            >
                Party:
            </Typography>
            <FormControl
                variant="outlined"
                size="small"
                sx={{ 
                    width: isMobile ? '162px' : '200px' 
                }}
            >
                <Select
                    value={selectedOption.id}
                    onChange={handleChange}
                    style={{
                        textAlign: "center",
                        fontSize: isMobile ? '12px' : '14px',
                        padding: isMobile ? '5px' : '10px',
                        height: isMobile ? '24px' : '36px',
                        border: "1px solid #000000",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "4px",
                        boxShadow: "none",
                        width: '100%'
                    }}
                >
                    {nameOptions.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                            {isMobile && option.name.length > 30 
                                ? `${option.name.slice(0, 30)}...` 
                                : option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default TiebreakerPartyDropdown;