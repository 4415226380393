import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import BracketTeamRow from "./BracketTeamRow";
import "./SuperPickemBracket.scss";
import { DateTime } from "luxon";

const BracketGame = ({
    games,
    rounds,
    teams,
    names,
    seeds,
    game,
    round,
    picksByRound,
    bracketState,
    setBracketState,
    getBracketInfoPreLock,
    setBracketRound,
    roundDash,
    setRoundDash,
    setRoundDashPosition,
    standings,
}) => {

    //console.log("Game: ", game, "Round: ", round, "Games: ", games, "Rounds: ", rounds, "Seeds: ", seeds, "Bracket State: ", bracketState);

    const roundPlaceholderName = rounds?.find((r) => r.round === round);
    const pendingDates = roundPlaceholderName?.pendingDates;
    //console.log("Round Placeholder Name: ", pendingDates);

    // The Top and Bottom Teams that have been selected
    const [topTeamId, setTopTeamId] = useState(null);
    const [bottomTeamId, setBottomTeamId] = useState(null);
    // If the Top and Bottom Teams have been selected
    const [isTopSelected, setIsTopSelected] = useState(false);
    const [isBottomSelected, setIsBottomSelected] = useState(false);
    //console.log("Top Team ID: ", topTeamId, "Bottom Team ID: ", bottomTeamId);

    const gameTime = games?.find((g) => g.game === game && g.round === round)?.DateTime;
    //console.log("GAME ID!!!", gameTime);
    const formatDate = (isoDate) => {
        const date = DateTime.fromISO(isoDate, { zone: "America/New_York" }); // Parse and set time zone
        return date.toFormat("ccc, LLL d @ h:mm a 'ET'");
    };

    // Use Effect to set the teams
    useEffect(() => {
        //console.log("Setting Teams Use Effect");
    
        // Fetch from current round schedule
        const topTeamIdFromSchedule = games?.find((g) => g.game === game && g.round === round)?.TopTeam;
        const bottomTeamIdFromSchedule = games?.find((g) => g.game === game && g.round === round)?.BottomTeam;
    
        let topTeamIdFromBracketState = null;
        let bottomTeamIdFromBracketState = null;
    
        // Logic for different rounds
        switch (round) {
            case 2:
                // Round 2: Use same game number from previous round
                topTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === game && p.round === round - 1
                )?.teamId;
                break;
    
            case 3:
                // Round 3: Use games (2n-1) and (2n) from previous round
                topTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === ((2 * game) - 1) && p.round === round - 1
                )?.teamId;
                bottomTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === (2 * game) && p.round === round - 1
                )?.teamId;
                break;
    
            case 4:
                // Round 4: Similar to round 3 logic
                topTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === game && p.round === round - 1
                )?.teamId;
                bottomTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === (game * 2) && p.round === round - 1
                )?.teamId;
                break;
        }
    
        // Prioritize schedule team IDs, then bracket state team IDs
        setTopTeamId(
            topTeamIdFromSchedule || 
            topTeamIdFromBracketState || 
            null
        );
    
        setBottomTeamId(
            bottomTeamIdFromSchedule || 
            bottomTeamIdFromBracketState || 
            null
        );
    
    }, [games, game, round, bracketState]);

    // Use effect to see if a team is selected
    useEffect(() => {
        //console.log("Checking if a team is selected");

        const topTeamIdFromState = bracketState?.find(
            (p) => p.game === game && p.round === round && p.teamId === topTeamId
        )?.teamId;

        const bottomTeamIdFromState = bracketState?.find(
            (p) => p.game === game && p.round === round && p.teamId === bottomTeamId
        )?.teamId;

        setIsTopSelected(!!topTeamIdFromState);
        setIsBottomSelected(!!bottomTeamIdFromState);

        //console.log("Is Top Selected: ", isTopSelected, "Is Bottom Selected: ", isBottomSelected);

    }, [bracketState, game, round, topTeamId, bottomTeamId]);
    
    // Get the team info about top and bottom teams
    const topTeamInfo = getBracketInfoPreLock(topTeamId, teams, seeds, names, standings);
    const bottomTeamInfo = getBracketInfoPreLock(bottomTeamId, teams, seeds, names, standings);
    //console.log("Top Team Info: ", topTeamInfo, "Bottom Team Info: ", bottomTeamInfo);


    return (
        <Box className="super-pickem-bracket-game-container">
            {/* Top Team */}
            {topTeamId ? (
                <BracketTeamRow
                    game={game}
                    round={round}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    teamInfo={topTeamInfo}
                    isSelected={isTopSelected}
                    setIsSelected={setIsTopSelected}
                    isOppositeSelected={isBottomSelected}setBracketRound={setBracketRound}
                    roundDash={roundDash}
                    setRoundDash={setRoundDash}
                    setRoundDashPosition={setRoundDashPosition}
                    picksByRound={picksByRound}
                />
            ) : (
                <Box className="super-pickem-bracket-team-row-placeholder" />
            )}

            {/* Game Information */}
            <Box>
                <Typography variant="h6" className="super-pickem-game-info">
                    {gameTime ? formatDate(gameTime) : pendingDates}
                </Typography>
            </Box>

            {/* Bottom Team */}
            {bottomTeamId ? (
                <BracketTeamRow
                    game={game}
                    round={round}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    teamInfo={bottomTeamInfo}
                    isSelected={isBottomSelected}
                    setIsSelected={setIsBottomSelected}
                    isOppositeSelected={isTopSelected}
                    setBracketRound={setBracketRound}
                    roundDash={roundDash}
                    setRoundDash={setRoundDash}
                    setRoundDashPosition={setRoundDashPosition}
                    picksByRound={picksByRound}
                />
            ) : (
                <Box className="super-pickem-bracket-team-row-placeholder" />
            )}
        </Box>
    );
};

export default BracketGame;
