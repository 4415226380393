import React, { useState } from 'react';
import NFLSurvivorCard from './NFLSurvivorCard';
import WeeklyPicksBar from './WeeklyPicksBar';
import SurvivorWeekNav from './SurvivorWeekNav';
import SortByDropdown from './SortByDropdown';
import { useNFLSurvivorContext } from '../context/NFLSurvivorContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Box, Grid, Modal, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { DateTime } from 'luxon';
import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSaveSurvivorPicks } from '../../../hooks/pools/useSaveSurvivorPicks';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import RemovePickModal from '../../GameModals/SurvivorModals/RemovePickModal';
import PickRemovedTooLateModal from '../../GameModals/SurvivorModals/PickRemovedTooLateModal';
import SurvivorUserStatsModal from "../../GameModals/SurvivorModals/SurvivorUserStats/SurvivorUserStatsModal";
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';




const MyPicks = ({ currentWeeksDeadlineDateTime }) => {
    const { NFLTeams,
        NFLStandings,
        NFLStadiums,
        NFLGameOddsByWeek,
        NFLSchedules,
        NFLScoresBySeason,
        week,
        startingWeekState,
        userPicksFromDB,
        pickEmState,
        doesUserHaveALoss,
        lossWeek,
        myUserId,
        mainGameId,
        gameAddress,
        doesUserBelong,
        myUsername,
        firstWeek,
        pickDeadlines,
     } = useNFLSurvivorContext();
    //console.log("MY PICKS NFL Schedule:", NFLScoresBySeason, NFLTeams);
    //console.log("Current Week's Deadline DateTime:", currentWeeksDeadlineDateTime);
    const [leadingPlaceholder, setLeadingPlaceholder] = useState(false);  // Placeholder for the left side of the Weekly Picks Bar
    const [trailingPlaceholder, setTrailingPlaceholder] = useState(false);  // Placeholder for the right side of the Weekly Picks Bar
    const [sortBy, setSortBy] = useState("Game Time"); // Default sort option
    const [removeModalOpen, setRemoveModalOpen] = useState(false); // Modal state
    const [removeTooLateOpen, setRemoveTooLateOpen] = useState(false); // Modal state
    const [removeTooLateMessage, setRemoveTooLateMessage] = useState(false); // Modal state
    const [statsModalOpen, setStatsModalOpen] = useState(false); // Modal state
    const [moreInfoOpen, setMoreInfoOpen] = useState(false); // Modal state
    const [selectedGame, setSelectedGame] = useState(null); // Modal state
    //console.log("Leading Placeholder:", leadingPlaceholder, "Trailing Placeholder:", trailingPlaceholder);


    const { mutate, isLoading, isError, error, isSuccess } = useSaveSurvivorPicks();

    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    if (!myUserId) {
        navigateToTop(`/login/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/HOME/HOME`);
    }

    const handleChange = (event) => {
        setSortBy(event.target.value);
      };



    const isThisNonPickWeek = week < firstWeek;
    //console.log("IS THIS NON PICK WEEK", isThisNonPickWeek, week, firstWeek);

    // Getting the Weekly Schedule by Week state, then sorting it by date/time
    let weeklySchedule = NFLScoresBySeason?.filter(game => game.Week === week);
    let sortedSchedule = weeklySchedule?.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));
    //console.log("MY PICKS Weekly Schedule:", sortedSchedule);
    if (sortBy === "Largest Spread") {
        const spreadSortedSchedule = weeklySchedule?.sort((a, b) => Math.abs(b.PointSpread) - Math.abs(a.PointSpread));
        sortedSchedule = spreadSortedSchedule;
    }
    // console.log("MY PICKS Weekly Schedule SPREAD", spreadSortedSchedule);

    // Getting the User Picks by Week state
    const userPicks = userPicksFromDB?.filter(pick => pick.week === week) || [];
    //console.log("MY PICKS User Picks:", userPicksFromDB, userPicks);
    const userPickTeamId = userPicks.length > 0 ? userPicks[0].teamId : null;
    //console.log("MY PICKS User Pick Team ID:", userPickTeamId);

    let usersSelectedGame = sortedSchedule?.find(game => userPicks.length > 0 && game.GlobalGameID === userPicks[0].gameId);
    
    //console.log("MY PICKS User's Selected Game:", usersSelectedGame);
    if (doesUserHaveALoss && lossWeek < week) {
        usersSelectedGame = null;
    }

    let hasUsersSelectedGameStarted = false;
    if (usersSelectedGame) {
        hasUsersSelectedGameStarted = usersSelectedGame?.HasStarted;
    }
    //console.log("MY PICKS User's Selected Game Started:", hasUsersSelectedGameStarted);




    if (usersSelectedGame) {
        weeklySchedule = weeklySchedule.filter(game => game.GlobalGameID !== usersSelectedGame.GlobalGameID);
        sortedSchedule = sortedSchedule.filter(game => game.GlobalGameID !== usersSelectedGame.GlobalGameID);
    }


    // Getting the current week's Sunday 1:00 PM dealine
    const currentWeeksSchedule = NFLScoresBySeason?.filter(game => game.Week === startingWeekState);
    const currentWeeksDeadline = currentWeeksSchedule?.find(game => {
        const gameDateTime = DateTime.fromISO(game.DateTime, { zone: 'America/New_York' });
        return gameDateTime.weekday === 7 && gameDateTime.hour === 13;
    })?.DateTime;
    //console.log("MY PICKS Current Week's Deadline:", currentWeeksDeadline);

    // // Getting the Sunday 1:00 PM Deadline
    // const sundayGame = sortedSchedule?.find(game => {
    //     const gameDateTime = DateTime.fromISO(game.DateTime, { zone: 'America/New_York' });
    //     return gameDateTime.weekday === 7; // Check if it's a Sunday
    // });

    let deadline = null; // Initialize deadline variable

    // if (sundayGame) {
    //     const sundayDate = DateTime.fromISO(sundayGame.DateTime, { zone: 'America/New_York' });
    //     deadline = sundayDate.set({ hour: 13, minute: 0, second: 0 }).toISO(); // Set time to 1:00 PM
    // }

    const deadlineFromRedis = pickDeadlines?.find(deadline => deadline.week === week)?.deadline;
        //console.log("Test Deadline From Redis: ", deadlineFromRedis);
    if (deadlineFromRedis) {
        deadline = deadlineFromRedis;
    }

    //console.log("MY PICKS Sunday 1:00 PM Deadline:", deadline);

    const formatDateString = (dateString) => {
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });
        const formattedDate = date.toFormat("ccc M/d");
        const formattedTime = date.toFormat("h:mma 'ET'").replace('AM', 'am').replace('PM', 'pm');

        // Combine the date and time parts with an "@" symbol
        const formattedDateTime = `${formattedDate} @ ${formattedTime}`;

        return formattedDateTime;
    };
    // Formatting the Deadline for display
    const formattedDeadline = formatDateString(deadline);
    //console.log("MY PICKS Formatted Deadline:", formattedDeadline, deadline);

    // Determining if the current Week's pick is locked.  Constructing the time in NY, the deadline time in NY,
    // and the current week's game time in NY.  IF either the deadline or the game time has passed, the picks are locked.
    const timeInNy = DateTime.now().setZone('America/New_York');
    const deadlineTimeInNy = DateTime.fromISO(deadline, { zone: 'America/New_York'});
    //console.log("MY PICKS Deadline Time in NY:", deadlineTimeInNy);
    const currentWeeksGametime = pickEmState?.find(pick => pick.week === week)?.dateTime;  //FIX THIS TO BE DYNAMIC!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const currentWeeksGametimeInNy = DateTime.fromISO(currentWeeksGametime, { zone: 'America/New_York'});
    //console.log("MY PICKS Time in NY:", timeInNy, deadlineTimeInNy, currentWeeksGametimeInNy);
    let thisWeeksPickLocked = false; //FIX!!!!!!!!!!!
    if (timeInNy > deadlineTimeInNy) {
        thisWeeksPickLocked = true;
    }
    if (timeInNy > currentWeeksGametimeInNy || hasUsersSelectedGameStarted || userPickTeamId === "No Pick") {
        thisWeeksPickLocked = true;
    }
    //console.log("MY PICKS This Week's Pick Locked:", thisWeeksPickLocked);


    const handleRemovePick = async (gameId) => {
        const newYorkTime = DateTime.now().setZone('America/New_York');
        const deadlineInNy = DateTime.fromISO(deadline, { zone: 'America/New_York'});
        const currentPickTimeInNy = DateTime.fromISO(currentWeeksGametime, { zone: 'America/New_York'});
        //const testValue = true
        //console.log("MY PICKS Remove Pick Times IN REMOVE:", newYorkTime, deadlineInNy, currentPickTimeInNy);
        if (newYorkTime > deadlineInNy || newYorkTime > currentPickTimeInNy) {
            setRemoveTooLateOpen(true);
            if (newYorkTime > deadlineInNy) {
                setRemoveTooLateMessage("The pick deadline has passed.");
            } else if (newYorkTime > currentPickTimeInNy) {
                setRemoveTooLateMessage("The game has already started.");
            }
            return;
        }

        //console.log("Remove Pick:", gameId);
        const removedPick = pickEmState.find(pick => pick.gameId === gameId);
        const updatedPickEmState = pickEmState.filter(pick => pick.gameId !== gameId);
        //console.log("Remove Pick Updated PickEm State:", updatedPickEmState);
        const picksData = {
            picks: updatedPickEmState,
            userID: myUserId,
            gameType: "Survivor",
            gameId: mainGameId,
            league: "NFL",
            newPick: removedPick,
            removal: true,
        }
        //console.log("Remove Pick Data:", picksData);
        try {
            await mutate(picksData, {
                onSuccess: async (response) => {
                    //console.log("Success Removing Pick:", response);
                    const { removal, deadlinePassed, removedGame } = response;
                    //console.log("Removal:", removal, "Deadline Passed:", deadlinePassed, "Removed Game:", removedGame);
                    if (removedGame) {
                        setRemoveModalOpen(true);
                    } else if (deadlinePassed) {
                        //console.log("Deadline Passed Modal Should open");
                        setRemoveTooLateOpen(true);
                        setRemoveTooLateMessage("The pick deadline has passed.");
                    } else if (removal) {
                        setRemoveTooLateOpen(true);
                        setRemoveTooLateMessage("The game has already started.");
                    }
                },
                onError: (error) => {
                    console.log("Error submitting picks:", error);
                    const errorMessage = error.message;
                    console.log("ERROR MESSAGE:", errorMessage);
                    
                    // Check for token expiration
                    if (error.response?.status === 401) {
                        dispatch(userLogoutAction({
                            logoutType: 'token-expired',
                        }));
                    }
                } // Close onError function here
            });
        } catch (error) {
            console.log("Error Submitting Picks:", error);
        }
    };




    let myPickText = "Pending";
    let myPickColor = "#00AAD1";

    if (usersSelectedGame) {
        const userResult = userPicks[0].result;
        if (userResult === "Pending" && thisWeeksPickLocked) {
            myPickText = "Locked";
            myPickColor = "#002129";
        } else if (userResult === "Win") {
            myPickText = "Survived";
            myPickColor = "#00AA72";
        } else if (userResult === "Loss") {
            myPickText = "Eliminated";
            myPickColor = "#CC293C";
        } else if (userResult === "No Pick") {
            myPickText = "No Pick";
            myPickColor = "#CC293C";
        }
    }




    return (
        <div>
            <WeeklyPicksBar
                weeklySchedule={sortedSchedule}
                userPicks={userPicks}
                deadline={deadline}
                leadingPlaceholder={leadingPlaceholder}
                setLeadingPlaceholder={setLeadingPlaceholder}
                trailingPlaceholder={trailingPlaceholder}
                setTrailingPlaceholder={setTrailingPlaceholder}
                firstWeek={firstWeek}
            />
            {!thisWeeksPickLocked && (week >= firstWeek) && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column', // Ensures items are stacked vertically
                            alignItems: 'center',    // Centers items horizontally
                            padding: '16px 0',       // Vertical padding around the content
                            backgroundColor: '#FFC60A',
                            borderTop: '2px solid #002129',
                            height: isMobile ? '44px' : '64px',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#002129',
                                fontSize: isMobile ? '16px' : '22px',
                                fontWeight: '700',
                            }}
                        >
                            Pick Deadline: {formattedDeadline}
                        </Typography>
                    </Box>
                )}
            <SurvivorWeekNav setLeadingPlaceholder={setLeadingPlaceholder} setTrailingPlaceholder={setTrailingPlaceholder} />

           {!usersSelectedGame && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '16px' : '20px',
                        fontWeight: 700,
                        color: '#CC293C',
                        textAlign: 'center',
                        justifyContent: 'center',
                        padding: '16px',
                        borderBottom: '2px solid #002129',
                    }}
                >
                    Select one team to win outright.
                    {isMobile ? <br /> : ' '}
                    Spreads are for reference only.
                </Typography>
            )}

            {week >= firstWeek && (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Ensures items are stacked vertically
                    alignItems: 'center',    // Centers items horizontally
                    padding: '16px 0',       // Vertical padding around the content
                }}
            >
                {doesUserHaveALoss && lossWeek < week && (
                    <Typography
                        variant="h6"
                        sx={{
                        color: '#002129',        // Default text color for the second typography
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center'
                        }}
                    >
                        My Pick: <span style={{ color: '#CC293C', marginLeft: '4px' }}>Eliminated</span> <CloseIcon sx={{ color: '#CC293C', marginLeft: '4px' }} />
                    </Typography>
                )}
                {!usersSelectedGame && !doesUserHaveALoss && (
                    <Typography
                        variant="h6"
                        sx={{
                        color: '#002129',        // Default text color for the second typography
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center'
                        }}
                    >
                        My Pick: <span style={{ color: '#CC293C', marginLeft: '4px' }}>No Pick</span>
                    </Typography>
                )}
                {!usersSelectedGame && doesUserHaveALoss && lossWeek === week && (
                    <Typography
                        variant="h6"
                        sx={{
                        color: '#002129',        // Default text color for the second typography
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center'
                        }}
                    >
                        My Pick: <span style={{ color: '#CC293C', marginLeft: '4px' }}>Eliminated <CloseIcon sx={{ color: '#CC293C', marginLeft: '4px' }} /></span>
                    </Typography>
                )}
                {usersSelectedGame && (
                    <Typography
                        variant="h6"
                        sx={{
                        color: '#002129',        // Default text color for the second typography
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center'
                        }}
                    >
                        My Pick: <span style={{ color: myPickColor, marginLeft: '4px' }}>{myPickText}</span>

                        {myPickText === 'Pending' && (
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            style={{ color: myPickColor, marginLeft: '4px' }}
                            >
                            <g clipPath="url(#clip0_936_9440)">
                                <path d="M4.03846 0C2.76346 0 1.66038 0.529038 0.973269 1.38808C0.287308 2.24769 0 3.36115 0 4.56115V6.34615H1.73077V4.56115C1.73077 3.64962 1.95519 2.93423 2.32558 2.46981C2.69654 2.00596 3.19673 1.73077 4.03846 1.73077C4.8825 1.73077 5.38154 1.99038 5.75135 2.45192C6.12058 2.91346 6.34615 3.64038 6.34615 4.56115V5.19231H8.07692V4.56115C8.07692 3.35423 7.77288 2.22808 7.08519 1.37019C6.39808 0.512308 5.31115 0 4.03846 0ZM5.19231 5.76923C4.23692 5.76923 3.46154 6.54462 3.46154 7.5V13.2692C3.46154 14.2246 4.23692 15 5.19231 15H13.2692C14.2246 15 15 14.2246 15 13.2692V7.5C15 6.54462 14.2246 5.76923 13.2692 5.76923H5.19231ZM9.23077 8.65385C9.53679 8.65385 9.83027 8.77541 10.0467 8.9918C10.263 9.20819 10.3846 9.50167 10.3846 9.80769C10.3846 10.2335 10.1515 10.5992 9.80769 10.7994V12.1154C9.80769 12.4333 9.54865 12.6923 9.23077 12.6923C8.91288 12.6923 8.65385 12.4333 8.65385 12.1154V10.7994C8.31 10.5992 8.07692 10.234 8.07692 9.80769C8.07692 9.50167 8.19849 9.20819 8.41488 8.9918C8.63126 8.77541 8.92475 8.65385 9.23077 8.65385Z" fill={myPickColor} />
                            </g>
                            <defs>
                                <clipPath id="clip0_936_9440">
                                <rect width="15" height="15" fill="white" />
                                </clipPath>
                            </defs>
                            </svg>
                        )}
                        {myPickText === 'Locked' && <LockIcon sx={{ color: myPickColor, marginLeft: '4px', width: '15px', height: '24px' }} />}
                        {myPickText === 'Survived' && <CheckIcon sx={{ color: myPickColor, marginLeft: '4px' }} />}
                        {myPickText === 'Eliminated' && <CloseIcon sx={{ color: myPickColor, marginLeft: '4px' }} />}

                </Typography>
                )}

            </Box>
            )}

            {userPickTeamId === "No Pick" && (week >= firstWeek) && (
                <Box
                    sx={{
                    width: isMobile ? '310px' : '375px',
                    height: isMobile ? '102px' : '130px',
                    flexShrink: 0,
                    borderRadius: '8px', // Fallback value for border-radius
                    border: '2px solid #CC293C',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 auto', // Center the box horizontally
                    justifyContent: 'space-between', // Ensure space between text and icon
                    padding: '0 16px', // Padding to add space around content inside the box
                    boxSizing: 'border-box', // Include padding in width calculation
                    marginBottom: '16px',
                    }}
                >
                    <Typography
                    sx={{
                        color: '#CC293C',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: isMobile ? '48px' : '64px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                    }}
                    >
                    NO PICK
                    </Typography>
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', // Center the icon within the container
                        width: isMobile ? '48px' : '64px',
                        height: isMobile ? '48px' : '64px',
                        border: '3px solid #CC293C',
                        flexShrink: 0,
                    }}
                    >
                    <CloseIcon sx={{ width: isMobile ? '32px' : '48px', height: isMobile ? '32px' : '48px', color: '#CC293C' }} />
                    </Box>
              </Box>
            )
            }


            {usersSelectedGame && (week >= firstWeek) && (
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'column', // Ensures items are stacked vertically
                    alignItems: 'center',    // Centers items horizontally
                    padding: '16px 0',       // Vertical padding around the content
                    }}
                >
                    <NFLSurvivorCard
                        key={usersSelectedGame.GlobalGameID}
                        thisGamesId={usersSelectedGame.GlobalGameID}
                        deadline={deadline}
                        thisWeeksPickLocked={thisWeeksPickLocked}
                        moreInfoOpen={moreInfoOpen}
                        setMoreInfoOpen={setMoreInfoOpen}
                        setSelectedGame={setSelectedGame}
                        selectedGame={selectedGame}
                    />
                    {!thisWeeksPickLocked && (
                    <Typography
                        variant="h6"
                        onClick={() => handleRemovePick(usersSelectedGame.GlobalGameID)}
                        sx={{
                        color: '#CC293C',
                        fontSize: '12px',
                        fontWeight: '500',
                        textDecoration: 'underline',
                        cursor: 'pointer'
                        }}
                    >
                        Remove Pick
                    </Typography>
                    )}
                </Box>
            )}

            {/* <SortByDropdown sortBy={sortBy} setSortBy={setSortBy} /> */}
            {week >= firstWeek && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                }}>
                    {/* <Typography variant="body1" sx={{ marginRight: 2, fontSize: isMobile ? '12px' : '16px' }}>
                    Sort By:
                    </Typography> */}
                    <RadioGroup
                    row
                    name="sort-by"
                    value={sortBy}
                    onChange={handleChange}
                    >
                    <FormControlLabel
                        value="Game Time"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: isMobile ? '12px' : '16px' }}>Game Time</Typography>}
                    />
                    <FormControlLabel
                        value="Largest Spread"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: isMobile ? '12px' : '16px' }}>Largest Spread</Typography>}
                    />
                    </RadioGroup>
                </Box>
            )}

            {week < firstWeek && (
                <Box
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    marginTop: '32px',
                    marginBottom: '32px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '20px' : '36px',
                            fontWeight: 700,
                            color: '#CC293C',
                        }}
                    >
                        Picks do NOT start until Week {firstWeek}.
                    </Typography>
                </Box>
            )}

            {doesUserBelong && (week >= firstWeek) && (
                      <Typography
                        style={{
                          color: '#00AAD1',
                          fontSize: isMobile ? '12px' : '20px',
                          fontWeight: 500,
                          textDecoration: 'underline',
                          marginTop: isMobile ? '0px' : '10px',
                          marginBottom: isMobile ? '20px' : '20px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer', // Add cursor pointer to indicate it's clickable
                        }}
                        onClick={() => setStatsModalOpen(true)}
                      >
                        My Picks
                      </Typography>
            )}

            <Box
                sx={{
                    maxWidth: '975px',
                    margin: '0 auto',
                    padding: '0 16px' // Optional padding to prevent content from touching the edges
                }}
            >
                <Grid container spacing={1}> {/* Reduced spacing */}
                    {sortedSchedule?.map(game => (
                        <Grid item xs={12} sm={12} md={6} key={game.GlobalGameID}>
                            <NFLSurvivorCard
                                key={game.GlobalGameID}
                                thisGamesId={game.GlobalGameID}
                                deadline={deadline}
                                thisWeeksPickLocked={thisWeeksPickLocked}
                                moreInfoOpen={moreInfoOpen}
                                setMoreInfoOpen={setMoreInfoOpen}
                                setSelectedGame={setSelectedGame}
                                selectedGame={selectedGame}
                                isThisNonPickWeek={isThisNonPickWeek}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {/* {pickEmState.length > 0 &&
            <UserSubmitBar currentWeeksDeadline={currentWeeksDeadline} />
            } */}

            {statsModalOpen && (
                <SurvivorUserStatsModal
                statsModalOpen={statsModalOpen}
                setStatsModalOpen={setStatsModalOpen}
                myUsername={myUsername}
                doesUserHaveALoss={doesUserHaveALoss}
                lossWeek={lossWeek}
                startingWeekState={startingWeekState}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
                NFLTeams={NFLTeams}
                areTheseMyStats={true}
                usersPicks={userPicksFromDB}
                firstWeek={firstWeek}
                />
            )}

            {removeModalOpen && (
                <RemovePickModal
                    removeModalOpen={removeModalOpen}
                    setRemoveModalOpen={setRemoveModalOpen}
                    week={week}
                    formattedDeadline={formattedDeadline}
                />
            )}

            {removeTooLateOpen && (
                <PickRemovedTooLateModal
                    removeTooLateOpen={removeTooLateOpen}
                    setRemoveTooLateOpen={setRemoveTooLateOpen}
                    removeTooLateMessage={removeTooLateMessage}
                    setRemoveTooLateMessage={setRemoveTooLateMessage}
                    week={week}
                />
            )}
        </div>
    );
}

export default MyPicks;
