import React, { useState } from 'react';
import { useGetProducts } from '../../hooks/commerce/useGetProducts';
import { useCreatePaymentIntent } from '../../hooks/commerce/useCreatePaymentIntent';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Modal, Box, Typography, Button, CircularProgress, TextField, MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import useNavigateToTop from "../../hooks/general/useNavigateToTop"; // Import the navigation hook
import './styles/MerchStyles.scss';

const Store = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openModal, setOpenModal] = useState(false); // Modal open state
  const [isProcessing, setIsProcessing] = useState(false); // State for loading
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    shirtSize: '', // New state for shirt size
  }); // State to capture customer information

  const { data: products, isLoading, isError, error } = useGetProducts();
  const { mutate: createPaymentIntent } = useCreatePaymentIntent();
  const stripe = useStripe();
  const elements = useElements();

  const navigateToTop = useNavigateToTop(); // Initialize the navigation hook

  if (isLoading) return <div>Loading products...</div>;
  if (isError) return <div>Error: {error.message}</div>;

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    setOpenModal(true); // Open the modal when a product is selected
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
    setSelectedProduct(null); // Reset selected product
    setCustomerInfo({
      name: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      shirtSize: '', // Reset shirt size
    }); // Reset customer info
  };

  const handleCustomerInfoChange = (e) => {
    setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value });
  };

  const handleSubmitPayment = async (event) => {
    event.preventDefault();

    // Validate customer information
    if (!customerInfo.name || !customerInfo.email || !customerInfo.address || !customerInfo.city || !customerInfo.state || !customerInfo.zip || !customerInfo.shirtSize) {
      alert('Please fill in all fields');
      return;
    }

    if (!stripe || !elements || !selectedProduct) return;

    setIsProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: customerInfo.name,
        email: customerInfo.email,
        address: {
          line1: customerInfo.address,
          city: customerInfo.city,
          state: customerInfo.state,
          postal_code: customerInfo.zip,
        },
      },
    });

    if (error) {
      console.error('Error creating payment method:', error);
      setIsProcessing(false);
      return;
    }

    // Create a return URL dynamically or statically depending on your need
    const returnUrl = `${process.env.REACT_APP_CLIENT_URL}/payment-success`;  // Replace with your actual URL

    // Trigger the payment intent creation process with the return URL
    createPaymentIntent({
      paymentMethodId: paymentMethod.id,
      price: selectedProduct.price,
      productId: selectedProduct.id,
      returnUrl,
      customerInfo,  // Include customer info
      productName: selectedProduct.name
    });

    setIsProcessing(false);
    handleCloseModal(); // Close the modal after submission

    // After successful payment, navigate using the returnUrl
    navigateToTop('/payment-success'); // Trigger the navigation to the return URL
  };

  return (
    <Box>
      <Box className="products">
        {products?.map((product) => (
          <Box key={product.id} className="product" onClick={() => handleSelectProduct(product)}>
            <img className='product-image' src={product.image} alt={product.name} style={{ width: 250, display:'block' }} />
            <h2 className='shipping-label'>$20 Including Shipping</h2>
            <h3 className='product-name'>{product.name}</h3>
            <p className='product-description'>{product.description}</p>
            <Button className='buy-now-button' variant="contained">BUY NOW!</Button>
          </Box>
        ))}
      </Box>

      {/* MUI Modal for payment */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: 4,
            borderRadius: 2,
            width: 400,
            boxShadow: 24,
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" gutterBottom>
            Checkout for {selectedProduct?.name}
          </Typography>

          {/* Customer Info Form */}
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={customerInfo.name}
            name="name"
            onChange={handleCustomerInfoChange}
            sx={{ marginBottom: 2 }}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={customerInfo.email}
            name="email"
            onChange={handleCustomerInfoChange}
            sx={{ marginBottom: 2 }}
            required
          />
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            value={customerInfo.address}
            name="address"
            onChange={handleCustomerInfoChange}
            sx={{ marginBottom: 2 }}
            required
          />
          <TextField
            label="City"
            variant="outlined"
            fullWidth
            value={customerInfo.city}
            name="city"
            onChange={handleCustomerInfoChange}
            sx={{ marginBottom: 2 }}
            required
          />
          <TextField
            label="State"
            variant="outlined"
            fullWidth
            value={customerInfo.state}
            name="state"
            onChange={handleCustomerInfoChange}
            sx={{ marginBottom: 2 }}
            required
          />
          <TextField
            label="Zip Code"
            variant="outlined"
            fullWidth
            value={customerInfo.zip}
            name="zip"
            onChange={handleCustomerInfoChange}
            sx={{ marginBottom: 2 }}
            required
          />

          {/* Shirt Size Dropdown */}
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Shirt Size</InputLabel>
            <Select
              value={customerInfo.shirtSize}
              name="shirtSize"
              onChange={handleCustomerInfoChange}
              required
            >
              <MenuItem value="S">Small</MenuItem>
              <MenuItem value="M">Medium</MenuItem>
              <MenuItem value="L">Large</MenuItem>
              <MenuItem value="XL">X-Large</MenuItem>
              <MenuItem value="XXL">XX-Large</MenuItem>
            </Select>
          </FormControl>

          {/* Payment Form */}
          <form onSubmit={handleSubmitPayment}>
            <CardElement />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={!stripe || isProcessing}
              sx={{ marginTop: 2 }}
            >
              {isProcessing ? <CircularProgress size={24} /> : `Pay $${selectedProduct?.price}`}
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default Store;
