import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import SuperPickemTwoPickCard from '../SuperPickemComponents/SuperPickemTwoPickCard';
import SuperPickemMyPicksNavBar from './SuperPickemMyPicksNavBar';
import SuperPickemSubmitBar from './SuperPickemSubmitBar';
import MyPicksBar from './MyPicksBar';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import MyPicksNoGamesPlaceholder from './MyPicksNoGamesPlaceholder';
import LockIcon from '@mui/icons-material/Lock';

const SuperPickemMyPicks = ({
    myUserId,
    mainGameId,
    gameAddress,
    gameType,
    teams,
    names,
    stadiums,
    schedule,
    games,
    seeds,
    odds,
    rounds,
    highestRound,
    picking,
    gameDescription,
    pickState,
    setPickState,
    bracketState,
    setBracketState,
    interval,
    setInterval,
    userPicksFromDB,
    userBracketPicksFromDB,
    unsavedPicks,
    setUnsavedPicks,
    league,
    gameRewards,
    joinReward,
    picksThisInterval,
    picksThisIntervalInDB,
    picksThisIntervalInState,
    season,
    logo,
    gatherLogo,
    gatherStadiumInfo,
    gatherDisplayInfo,
    gatherScores,
    gatherLineDisplay,
    stopPicksWarning,
    picksNotSavedModal,
    setPicksNotSavedModal,
    setPendingInterval,
    pendingInterval,
    pickemLogo,
    sponsorSecondaryLogo,
    gatherPickEmCardTopLeftInfo,
    hideSponsorInPoolHall,
    regularSeasonSchedule,
    gatherModalInfo,
    standings,
    showMyPicksBar,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const round = rounds?.find(round => round.round === interval);
    const roundName = round?.name;
    //console.log("Round:", round, "Round Name:", roundName);

   // Filter the games for the current interval and get their IDs.
    const thisIntervalsGames = games?.filter(game => game.round === interval);
    const thisIntervalsGameIds = thisIntervalsGames?.map(game => game.GlobalGameID);
    //console.log("This Intervals Games:", thisIntervalsGames, "This Intervals Game Ids:", thisIntervalsGameIds);

    // Filter the schedule for the current interval using the game IDs, then sort by DateTime.
    let thisIntervalsSchedule = schedule
        ?.filter(game => thisIntervalsGameIds?.includes(game.GlobalGameID))
        .sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime)); // Sort by DateTime (earliest first)

    //console.log("This Intervals Schedule (sorted):", thisIntervalsSchedule);

    // Filter the odds for the current interval using the game IDs.
    let thisIntervalsOdds = odds?.filter(game => thisIntervalsGameIds?.includes(game.GlobalGameId));
    //console.log("This Intervals Odds:", thisIntervalsOdds);


    // Map over thisIntervalsSchedule to add `homeSpread` and `total` if they exist in `thisIntervalsOdds`.
    thisIntervalsSchedule = thisIntervalsSchedule?.map(scheduleGame => {
        const matchingGame = thisIntervalsOdds?.find(game => game.GlobalGameId === scheduleGame.GlobalGameID);
        //console.log("Matching Game:", matchingGame);
        // If matchingGame is found and has `homeSpread` or `total`, add them to scheduleGame.
        if (matchingGame) {
            let pregameOdds = matchingGame.PregameOdds?.find(odds => odds.Sportsbook === 'DraftKings'  && odds.Unlisted === null);
            if (!pregameOdds) {
                pregameOdds = matchingGame.PregameOdds?.find(
                  odds => odds.Sportsbook === 'FanDuel' && odds.Unlisted === null
                );
              }
            //console.log("Pregame Odds:", pregameOdds);
            const HomePointSpread = pregameOdds?.HomePointSpread;
            const OverUnder = pregameOdds?.OverUnder;
            return {
                ...scheduleGame,
                homeSpread: HomePointSpread,
                total: OverUnder,
            };
        }
        return scheduleGame;
    });

    //console.log("This Intervals Schedule with spreads and totals:", thisIntervalsSchedule);


    useEffect(() => {
        //console.log("Checking if picks are the same...");
        if (pickState?.length === 0) {
            setUnsavedPicks(false);
        } else {
            setUnsavedPicks(true);
        }
    }, [pickState]);

    //console.log("Picks This Interval in DB:", picksThisIntervalInDB, "Picks This Interval in State:", picksThisIntervalInState);


    return (
        <div>
            {showMyPicksBar && (
                <MyPicksBar 
                    thisIntervalsSchedule={thisIntervalsSchedule}
                    userPicksFromDB={userPicksFromDB}
                    interval={interval}
                    teams={teams}
                    gatherLogo={gatherLogo}
                    rounds={rounds}
                />
            )}

            <SuperPickemMyPicksNavBar
                gameAddress={gameAddress}
                mainGameId={mainGameId}
                interval={interval}
                setInterval={setInterval}
                highestInterval={highestRound}
                currentInterval={interval}
                unsavedPicks={unsavedPicks}
                userPicksFromDB={userPicksFromDB}
                setPickState={setPickState}
                rounds={rounds}
                picksNotSavedModal={picksNotSavedModal}
                setPicksNotSavedModal={setPicksNotSavedModal}
                setPendingInterval={setPendingInterval}
                pendingInterval={pendingInterval}
                showMyPicksBar={showMyPicksBar}
            />

            
            {thisIntervalsSchedule?.length > 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '10px',
                        marginBottom: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: '700',
                            color: '#CC293C',
                        }}
                    >
                        2 Picks per Game:
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            color: '#CC293C',
                        }}
                    >
                        1. Against the Spread (ATS) <br />
                        2. Over/Under (O/U)
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            color: '#002129',
                            marginTop: '8px',
                        }}
                    >
                        <LockIcon sx={{ fontSize: isMobile ? '16px' : '20px', color: '#002129' }} /> = Line currently Off The Board
                    </Typography>
                </Box>
            )}

            {thisIntervalsSchedule?.length === 0 && (
                <MyPicksNoGamesPlaceholder
                    rounds={rounds}
                    interval={interval}
                />
            )}


            {thisIntervalsSchedule?.map(game => (
                <SuperPickemTwoPickCard
                    key={game.GlobalGameID}
                    gameDescription={gameDescription}
                    roundName={roundName}
                    game={game}
                    teams={teams}
                    names={names}
                    stadiums={stadiums}
                    seeds={seeds}
                    picking={picking}
                    pickState={pickState}
                    setPickState={setPickState}
                    userPicksFromDB={userPicksFromDB}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    interval={interval}
                    gatherLogo={gatherLogo}
                    gatherStadiumInfo={gatherStadiumInfo}
                    gatherDisplayInfo={gatherDisplayInfo}
                    gatherScores={gatherScores}
                    gatherLineDisplay={gatherLineDisplay}
                    teamsOrNames={names}
                    gatherPickEmCardTopLeftInfo={gatherPickEmCardTopLeftInfo}
                    regularSeasonSchedule={regularSeasonSchedule}
                    gatherModalInfo={gatherModalInfo}
                    league={league}
                    standings={standings}
                />
            ))}

            {pickemLogo && (
                <img 
                    src={pickemLogo}
                    alt="Bracket Logo"
                    style={{
                        width: isMobile ? '185px' : '300px',
                        padding: '32px',
                        margin: '0 auto',
                    }}
                />
            )}

            {pickState?.length > 0  && (
                <div>
                    <SuperPickemSubmitBar
                        myUserId={myUserId}
                        gameType={gameType}
                        mainGameId={mainGameId}
                        picksThisInterval={picksThisInterval}
                        howManyPickMadeThisInterval={picksThisIntervalInState?.length + picksThisIntervalInDB?.length}
                        league={league}
                        pickState={pickState}
                        setPickState={setPickState}
                        userPicksFromDB={userPicksFromDB}
                        interval={interval}
                        gameRewards={gameRewards}
                        joinReward={joinReward}
                        season={season}
                        roundName={roundName}
                        logo={logo}
                        names={names}
                        schedule={schedule}
                        stopPicksWarning={stopPicksWarning}
                        sponsorSecondaryLogo={sponsorSecondaryLogo}
                        hideSponsorInPoolHall={hideSponsorInPoolHall}
                    />
                </div>
            )}
            
        </div>
    )
}

export default SuperPickemMyPicks;