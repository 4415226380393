import React, { useState, useEffect } from 'react';
import { Box, Button, Link } from '@mui/material';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { userRegisterAction, getUserDataFromLocalStorage } from '../redux/actions/loginImport';
import JoinCard from '../components/JoinCard';
import HorizontalLinearStepper from '../components/AccountCreation/HorizontalLinearStepper';
import AccountSetup from '../components/AccountCreation/AccountSetup';
import PasswordSetup from '../components/AccountCreation/PasswordSetup';
import BasicInfo from '../components/AccountCreation/BasicInfo';
import Preferences from '../components/AccountCreation/Preferences';
import FinishAndSubmit from '../components/AccountCreation/FinishAndSubmit';
import MainLayout from "../layouts/mainLayouts";
import login from '../components/login/login';

export default function AccountCreationPage() {
  const { '*': redirectPath } = useParams(); // Captures everything after /register/
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralId = queryParams.get('referralId');
  let loginUrl = redirectPath; // Initialize loginUrl to redirectPath
  if (referralId) {
    loginUrl += `?referralId=${referralId}&signUp=true`; // Append referralId and signUp=true
  } else {
    loginUrl += '?signUp=true'; // Append signUp=true
  }
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);

  //Local State Bank:
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  let loginPayload = {
    email: email,
    passwordConfirmation: passwordConfirmation,
    password: password,
    first_name: firstName,
    last_name: lastName,
    phone: phone,
    user_name: userName,
    date_of_birth: dateOfBirth,
    ifGoogleUser: { isGoogleUser: false },
    usersAgreementsValid:isTermsAccepted
  };

  const handleRegister = () => {
    // Dispatch the setCurrentUser action with a payload
    dispatch(userRegisterAction({loginPayload}, loginUrl));
  };

  const userData = getUserDataFromLocalStorage();

  const stepsProps = {
    currentStep: currentStep,
    goToNextStep: goToNextStep,
    goToPrevStep: goToPrevStep,
  };

  const JOIN_STEPS = [
    {
      heading: "Account Setup",
      description: (
        <span style={{ whiteSpace: "pre-line" }}>
          Welcome to the Party!
          {"\n"}
          We need a few quick details to get you started.
        </span>
      ),
      component: <AccountSetup
        {...stepsProps}
        email={email}
        setEmail={setEmail} />,
    },
    {
      heading: "Password",
      description: "",
      component: (
        <PasswordSetup
          {...stepsProps}
          password={password}
          setPassword={setPassword}
          passwordConfirmation={passwordConfirmation}
          setPasswordConfirmation={setPasswordConfirmation}
        />
      ),
    },
    {
      heading: "General Info",
      description: "",
      component: (
        <BasicInfo
          {...stepsProps}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setPhone={setPhone}
          setUserName={setUserName}
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          userName={userName}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
        />
      ),
    },
    {
      heading: "Finish & Submit",
      description: null,
      component: (
        <FinishAndSubmit {...stepsProps} handleRegister={handleRegister} isTermsAccepted={isTermsAccepted} setIsTermsAccepted={setIsTermsAccepted}/>
      ),
    },
    {
      heading: "Welcome to the party!",
      description:
        "The first official Pool Party. The biggest one of them all. The Pool Party Bracket Battle is a click away.",
      component: <Button variant="contained">MY BRACKETS</Button>,
    },
  ];

  const childrenToRender = JOIN_STEPS[currentStep].component;
  const heading = JOIN_STEPS[currentStep].heading;
  const description = JOIN_STEPS[currentStep].description;

  useEffect(() => {
    // Scroll to the top of the page whenever currentStep changes
    window.scrollTo(0, 0);
  }, [currentStep]);

  function goToNextStep() {
    setCurrentStep((prevStep) => prevStep + 1);
  }

  function goToPrevStep() {
    setCurrentStep((prevStep) => prevStep - 1);
  }

  return (
    <>
      {!userData ? (
        <Box
          sx={{
            display: "flex",
            height: "100%,",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "30px 0",
          }}
        >
          <JoinCard heading={heading} description={description}>
            <Box sx={{ marginTop: "32px", textAlign: "center" }}>
              {childrenToRender}
            </Box>
          </JoinCard>
          {currentStep >= 0 && currentStep <= 3 && (
            <HorizontalLinearStepper currentStep={currentStep} />
          )}
        </Box>
      ) : (
        <div>
          You are already signed in,{" "}
          <Link to="/welcome">jump back in and start playing!</Link>
        </div>
      )}
    </>
  );
}
