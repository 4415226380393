import React from 'react';
import { Box, Typography } from '@mui/material';
import trophyImage from '../../assets/images/Layer_1.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SuperPickemTrophies = ({ logo, prizes }) => {
    //console.log("PRIZES: ", prizes);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img src={logo} alt="Game Logo" style={{ width: isMobile ? '180px' : '40%' }} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '16px',
                    gap: isMobile ?  '24px' : '48px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}
                >
                    
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '500',
                        }}
                    >
                        {prizes?.bracketPrize}
                    </Typography>
                    <img src={trophyImage} alt="Trophy" style={{ width: isMobile ? '24px' : '32px' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}
                >
                    
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '500',
                        }}
                    >
                        {prizes?.overallPrize}
                    </Typography>
                    <img src={trophyImage} alt="Trophy" style={{ width: isMobile ? '24px' : '32px' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}
                >
                    
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '500',
                        }}
                    >
                        {prizes?.pickemPrize}
                    </Typography>
                    <img src={trophyImage} alt="Trophy" style={{ width: isMobile ? '24px' : '32px' }} />
                </Box>
            </Box>
        </Box>
    )
}

export default SuperPickemTrophies;