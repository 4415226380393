import React from "react";
import PickStatsCard from "./PickStatsCard";
import { Typography, Box } from "@mui/material";
import { DateTime } from "luxon";
import QuickPickNoGamesCard from "../CardPieces/QuickPickNoGamesCard";

const PickStatsTab = ({ 
    leaderboardInfo, 
    selectedQuickPickGames, 
    schedule, 
    interval, 
    timeNowInNewYork, 
    currentPropIds, 
    league, 
    teamName, 
    sponsorName 
}) => {
    //console.log("Current Prop Ids in Pick Stats Tab: ", currentPropIds);


    const hasThisGameStarted = (gameId) => {
        const gameInfo = schedule.find(game => game.GlobalGameID === gameId);
        const gameTime = gameInfo?.DateTime;
        const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
        //console.log("Game Date Time", gameDateTime, "Time Now in NY", timeNowInNewYork, "Has Game Started?", timeNowInNewYork > gameDateTime);
        return timeNowInNewYork > gameDateTime;
    }


    // Function to process and count options for a specific game's props
    const processGameProps = (game) => {
        const props = game?.props || {};
        const gameProps = props.gameProps || [];
        const homeProps = props.homeProps || [];
        const awayProps = props.awayProps || [];

        const allProps = [...gameProps, ...homeProps, ...awayProps];

        // Filter allProps to only include those that match currentPropIds
        const filteredProps = allProps.filter(prop => prop && currentPropIds.includes(prop.id));

        // Sort and remove any null/undefined values in the final sortedProps
        const sortedProps = currentPropIds
            .map(id => filteredProps.find(prop => prop?.id === id))
            .filter(Boolean); // This will filter out both null and undefined

        //console.log("Sorted Props in Pick Stats", sortedProps);

        

        // Initialize the counts for each game prop
        const optionCounts = sortedProps?.map((prop) => ({
            id: prop.id,
            points: prop.points,
            line: prop.line,
            propChoice: prop.propChoice,
            propText: prop.propText,
            options: prop.options,
            correctOption: prop.correctOption,
            actualTotal: prop.actualTotal,
            counts: prop.options.reduce((acc, option) => {
                acc[option] = 0; // Initialize the count for each option
                return acc;
            }, {}),
            ...(prop.correctOptions ? { correctOptions: prop.correctOptions } : {}) // Conditionally include correctOptions
        }));

        // Count selections based on user picks for this specific game's props
        leaderboardInfo?.forEach((user) => {
            user?.picks?.forEach((pick) => {
                const matchingProp = optionCounts.find(prop => prop.id === pick.id);
                if (matchingProp) {
                    if (matchingProp.counts.hasOwnProperty(pick.option)) {
                        matchingProp.counts[pick.option]++;
                    }
                }
            });
        });

        return optionCounts;
    };

    return (
        <>
            {selectedQuickPickGames?.length === 0 ? (
                <QuickPickNoGamesCard
                    league={league}
                    isThereAGame={false}
                    teamName={teamName}
                    sponsorName={sponsorName}
                />
            ) : (
                selectedQuickPickGames?.map((game, gameIndex) => {
                    const gameId = game.GlobalGameID;
                    const hasGameStarted = hasThisGameStarted(gameId);
    
                    if (!hasGameStarted) {
                        // Return only one 'QuickPickNoGamesCard' for all non-started games
                        return gameIndex === 0 ? (
                            <QuickPickNoGamesCard
                                key={`${gameId}-${gameIndex}`} // Use gameId for unique keys
                                league={league}
                                isThereAGame={true}
                                teamName={teamName}
                                sponsorName={sponsorName}
                                picksComing={true}
                            />
                        ) : null;
                    } else {
                        const optionCounts = processGameProps(game) || [];
    
                        return (
                            optionCounts.map((prop, propIndex) => (
                                <PickStatsCard
                                    key={`${gameId}-${propIndex}`} // Use gameId for unique keys
                                    prop={prop}
                                    interval={interval}
                                    displayIndex={propIndex + 1}
                                />
                            ))
                        );
                    }
                })
            )}
            <Box height={40} />
        </>
    );
    
};

export default PickStatsTab;
