import './Top3Display.scss';
import { Box, Typography } from '@mui/material';
import trophyImage from '../../assets/images/Layer_1.svg';
import BronzeTrophy from '../../assets/images/BronzeTrophy.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Top3DisplayNew = ({ 
    rankedUsernames, 
    partyName, 
    line2, 
    gameName 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Utility function to render usernames with character count checks
  const renderUsernames = (usernames, isFirstPlace = false) => {
    return usernames.map((username, index) => {
      const characterCount = username.length;
      return (
        <Typography
          key={index}
          className={`top-three-display-text__title ${isMobile
            ? characterCount > 17
              ? 'top-three-display-text__title--small-mobile'
              : 'top-three-display-text__title--mobile'
            : 'top-three-display-text__title'
          }`}
        >
          {username}
        </Typography>
      );
    });
  };

  return (
    <Box className={`top-three-display ${isMobile ? 'top-three-display--mobile' : ''}`}>
      {/* Header */}
      <Box className="top-three-display-header">
        <Typography
          className={`top-three-display-header__text ${isMobile ? 'top-three-display-header__text--mobile' : ''}`}
          sx={{ marginBottom: isMobile ? '16px' : '0px' }}
        >
          {partyName && partyName !== gameName && (
            <>
              {partyName}'s <br />
            </>
          )}
          {line2}
        </Typography>
      </Box>

      {/* 1st Place */}
      {rankedUsernames?.first?.length > 0 && (
        <Box className="top-three-display-body">
          <Box className="top-three-display-content">
            <Box component="img" src={trophyImage} alt="Trophy Image" className="top-three-display-image" />
            <Box className="top-three-display-text">
              <Typography
                className={`top-three-display-text__title ${isMobile
                  ? 'top-three-display-text__title--mobile'
                  : 'top-three-display-text__title'
                }`}
              >
                {rankedUsernames?.first?.length > 1 ? 'Co-Champions' : 'Champion'}
              </Typography>
              {renderUsernames(rankedUsernames?.first, true)}
            </Box>
            <Box component="img" src={trophyImage} alt="Trophy Image" className="top-three-display-image" />
          </Box>
        </Box>
      )}

      {/* 2nd Place */}
      {rankedUsernames?.second?.length > 0 && (
        <Box className="top-three-display-body">
          <Box className="top-three-display-content top-three-display-content--second">
            <Box component="img" src={trophyImage} alt="Trophy Image" className="top-three-display-image top-three-display-image--second" />
            <Box className="top-three-display-text top-three-display-text--second">
              <Typography
                className={`top-three-display-text__title top-three-display-text__title--second ${isMobile
                  ? 'top-three-display-text__title--mobile'
                  : 'top-three-display-text__title--second'
                }`}
              >
                2nd Place
              </Typography>
              {renderUsernames(rankedUsernames?.second)}
            </Box>
            <Box component="img" src={trophyImage} alt="Trophy Image" className="top-three-display-image top-three-display-image--second" />
          </Box>
        </Box>
      )}

      {/* 3rd Place */}
      {rankedUsernames?.third?.length > 0 && (
        <Box className="top-three-display-body--third">
          <Box className="top-three-display-content top-three-display-content--third">
            <Box component="img" src={BronzeTrophy} alt="Trophy Image" className="top-three-display-image top-three-display-image--third" />
            <Box className="top-three-display-text top-three-display-text--third">
              <Typography
                className={`top-three-display-text__title top-three-display-text__title--third ${isMobile
                  ? 'top-three-display-text__title--mobile'
                  : 'top-three-display-text__title--third'
                }`}
              >
                3rd Place
              </Typography>
              {renderUsernames(rankedUsernames?.third)}
            </Box>
            <Box component="img" src={BronzeTrophy} alt="Trophy Image" className="top-three-display-image top-three-display-image--third" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Top3DisplayNew;
