import React, { useState } from "react";
import { Typography, Box, Modal, Button, FormControlLabel, Checkbox } from "@mui/material";
import { useJoinPublicPool } from "../../PublicPools/hooks/useJoinPublicPools";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import CreatePartyInfo from "../../MyParty/CreatePartyInfo";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import QuickPickJoinedModal from "./QuickPickJoinedModal";
import TextAcceptanceModal from "./TextAcceptanceModal";


const JoinBarBattleModal = ({ 
    myUserId, 
    logo, 
    gameName, 
    gameType, 
    gameId, 
    gameAddress, 
    joinOpen, 
    setJoinOpen, 
    setSignUpState, 
    sponsorSecondaryLogo, 
    sponsorName, 
    sponsorLogo, 
    mainGameId, 
    propIdsToUse, 
    joinModalText, 
    referralId, 
    newSignUp,
    hideSponsorInPoolHall, 
    affiliatedGamesInfo,
    isTeamGame,
    isHostGame,
    hostGameData,
    sponsorCircleLogo,
    sponsorAbbreviation,
    countdownBackground,
}) => {
    //console.log("Prop Ids To Use: ", propIdsToUse, propIdsToUse.length);
    const navigateToTop = useNavigateToTop();

    const { mutate: joinPool } = useJoinPublicPool();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const [status, setStatus] = useState('confirmJoin');
    const [errorMessage, setErrorMessage] = useState([]);//UI Purposes
    const [selectedGameId, setSelectedGameId] = useState(null);
    const [selectedGameName, setSelectedGameName] = useState(null);
    const [selectedGameReward, setSelectedGameReward] = useState(null);
    const [selectedGameShirt, setSelectedGameShirt] = useState(null);
    console.log("Selected Game ID: ", selectedGameId, "Selected Game Name: ", selectedGameName);

    const handleCheckboxChange = (gameId, name, reward, tShirt) => {
        setSelectedGameId(selectedGameId === gameId ? null : gameId);
        setSelectedGameReward(selectedGameName === name ? null : reward);
        setSelectedGameName(selectedGameName === name ? null : name);
        setSelectedGameShirt(selectedGameName === name ? null : tShirt);
    };

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const joinGame = async (gameID, gameType) => {
        console.log("JOIN BAR BATTLE GAME HERE");
        let data = {
            gameID: selectedGameId,
            userID: myUserId,
            gameType: gameType,
            hostGameID: mainGameId
        };

        if (referralId) {
            data.referralId = referralId;
        }

        if (newSignUp) {
            data.newSignUp = newSignUp;
        }

        console.log("Data to Join Game: ", data);
            
        joinPool(data, {
            onSuccess: () => {
                setJoinOpen(true);
                setStatus("success");
                // Optionally, navigate to the game page or handle success logic
            },
            onError: (error) => {
                // Dispatch logout action for any error
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
        
                // No need to handle status or error message since the user is logged out
                console.error("Join pool failed:", error);
            }
        });
        
    };
    
    
    
    const handleJoinPool = (gameID, gameType) => {
        if(!myUserId) {
            //Base URL for navigation
            let url = `/login/${gameAddress}/${uriEncoded(gameID)}/HOME/HOME`;
            
            //If there is a referral ID, append it to the URL
            if (referralId) {
                url += `?referralId=${referralId}`;
            }
            
            navigateToTop(url);
        } else {
            joinGame(gameID, gameType);
            //console.log("JOIN POOL HERE")
        }
    };

    return (
        <div>
            <Modal
                open={joinOpen && status === "confirmJoin"}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box
                    sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '340px',
                        minHeight: isMobile ? '330px' : '350px',
                        height: 'auto',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2, // Padding of 16px
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                   <Typography
                        variant="h6"
                        sx={{ 
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 800,
                        }}
                    >
                        Select Your Bar Team
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        {affiliatedGamesInfo?.map((game) => (
                            <FormControlLabel
                                key={game.id}
                                control={
                                    <Checkbox
                                        checked={selectedGameId === game.id}
                                        onChange={() => handleCheckboxChange(game.id, game.name, game.reward, game.tShirt)}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: theme.palette.primary.main,
                                            },
                                        }}
                                    />
                                }
                                label={game.longName}
                                sx={{
                                    marginLeft: 1,
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '16px',
                                        fontWeight: 800,
                                    },
                                }}
                            />
                        ))}
                    </Box>

                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            color: '#CC293C',
                            textAlign: 'center',
                            marginBottom: '8px',
                        }}
                    >
                        Note: You cannot change your team once selected!
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2 // Gap of 20px
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                textDecoration: 'underline', // Underlined text
                                cursor: 'pointer', // Pointer cursor for interactivity
                                alignSelf: 'center', // Align with the button vertically
                                mb: 1 // MarginBottom of 8px
                            }}
                            onClick={() => setJoinOpen(false)} // Same functionality as the original button
                        >
                            Not Yet?
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                maxWidth: '300px',
                                height: '38px',
                                whiteSpace: 'nowrap',
                                mb: 1 // MarginBottom of 8px
                            }}
                            onClick={() => handleJoinPool(gameId, gameType)}
                            disabled={!selectedGameId}
                        >
                            {selectedGameName ? `JOIN ${selectedGameName.toUpperCase()}` : 'PICK YOUR TEAM'}
                        </Button>
                    </Box>

                </Box>
            </Modal>
            {gameType === 'QuickPick' && (
                <QuickPickJoinedModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    arePropsAvailable={propIdsToUse?.length > 0}
                    status={status}
                    sponsorLogo={sponsorLogo}
                    sponsorName={sponsorName}
                    gameId={mainGameId}
                    gameAddress={gameAddress}
                    joinModalText={joinModalText}
                />
            )}
            
            {gameType !== 'QuickPick' && (
                <TextAcceptanceModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    status={status}
                    logo={logo}
                    myUserId={myUserId}
                    gameId={gameId}
                    isHostGame={isHostGame}
                    isTeamGame={isTeamGame}
                    hostGameData={hostGameData}
                    sponsorCircleLogo={sponsorCircleLogo}
                    sponsorAbbreviation={sponsorAbbreviation}
                    selectedGameName={selectedGameName}
                    selectedGameReward={selectedGameReward}
                    selectedGameShirt={selectedGameShirt}
                    countdownBackground={countdownBackground}
                />
            )}

                <Modal
                    open={joinOpen && status === "fail"}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box 
                        sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '308px',
                        height: '210px',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2, // Padding of 16px
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2, // Gap of 20px
                        overflow: 'scroll'
                        }}
                    >
                        <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 700,
                            marginBottom: '24px'
                        }}
                        >
                        Oops!
                        </Typography>
                        <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '12px',
                            fontWeight: 700,
                            marginBottom: '16px'
                        }}
                        >
                        {errorMessage}
                        </Typography>
                        <Button
                        variant="contained"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            width: '152px',
                            height: '36px',
                            marginBottom: '8px'
                        }}
                        onClick={() => window.location.reload()} // Reload the page
                        >
                        Close
                        </Button>
                    </Box>
                </Modal>

        </div>
    );
}

export default JoinBarBattleModal;