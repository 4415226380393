import React from "react";
import { Typography, Box } from '@mui/material';
import PickStatsCard from "./PickStatsCard/PickStatsCard";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { filter } from "lodash";

const GameStats = ({
    leaderboard,
    teams,
    schedule,
    games,
    pageInterval,
    teamsOrNames,
    rounds,
    getPickStatsGameInfo,
    getPickStatsPickInfo,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //console.log("Games: ", games, "Schedule: ", schedule);
    //const gameIdsThisInterval =[]
    const gameIdsThisInterval = games?.filter(game => game.round === pageInterval && game.GlobalGameID).map(game => game.GlobalGameID);
    //console.log("Game IDs This Interval: ", gameIdsThisInterval);
    const filteredSchedule = schedule?.filter(game => gameIdsThisInterval?.includes(game.GlobalGameID));

    filteredSchedule?.sort((a, b) => {
        // Define the desired order for Status
        const statusOrder = ['InProgress', 'Final', 'F/OT'];
    
        // Normalize statuses: Treat 'Final' and 'F/OT' as equivalent
        const normalizeStatus = (status) => (status === 'F/OT' ? 'Final' : status);
    
        // Get the normalized index of each game's Status in the statusOrder array
        const statusA = statusOrder.indexOf(normalizeStatus(a.Status));
        const statusB = statusOrder.indexOf(normalizeStatus(b.Status));
    
        // Handle Status order first
        if (statusA !== statusB) {
            // If statusA or statusB isn't in the array, place them at the end (use Infinity)
            return (statusA === -1 ? Infinity : statusA) - (statusB === -1 ? Infinity : statusB);
        }
    
        // If Status is 'Final' or 'F/OT', sort by reverse DateTime (most recent first)
        if (normalizeStatus(a.Status) === 'Final') {
            return new Date(b.DateTime) - new Date(a.DateTime); // Reverse order for completed games
        }
    
        // For other statuses, sort by ascending DateTime
        return new Date(a.DateTime) - new Date(b.DateTime);
    });

    const gameIdsThisIntervalSorted = filteredSchedule?.map(game => game.GlobalGameID);

    const thisIntervalsName = rounds.find(round => round.round === pageInterval).name;
    //console.log("This Interval's Name: ", thisIntervalsName);

   

    return (
        <>
            {gameIdsThisIntervalSorted?.length === 0 && (
                <Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '20px' : '24px',
                            fontWeight: '700',
                            marginTop: '24px',
                            padding: '0 16px',
                        }}
                    >
                       {thisIntervalsName} Game Stats will be <br /> revealed when the matchups are set.
                    </Typography>
                </Box>
            )}
        
            {gameIdsThisIntervalSorted?.length > 0 && (  
                <>
                    <Box>
                        {gameIdsThisIntervalSorted?.map(gameId => {
                            const gameInfo = getPickStatsGameInfo(gameId, schedule, teams, teamsOrNames);
                            //console.log("This Has Game Started!!: ", gameInfo?.hasGameStarted);
                            const pickInfo = getPickStatsPickInfo(gameId, gameInfo.awayTeamId, gameInfo.homeTeamId, leaderboard);
                            return (
                                <PickStatsCard 
                                    key={gameId}
                                    gameInfo={gameInfo}
                                    pickInfo={pickInfo}
                                    thisIntervalsName={thisIntervalsName}
                                />
                            );
                        })}
                    </Box>
                </>
            )}
        </>
    );
}

export default GameStats;