import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

// Function to create a PaymentIntent
const createPaymentIntent = async ({ paymentMethodId, price, productId, customerInfo }) => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

  const response = await axios.post(`${apiUrl}/user/create-payment-intent`, {
    paymentMethodId,
    price,
    productId,
    customerInfo
  }, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json',
    }
  });

  return response.data;
};

// Custom hook for creating a payment intent
export const useCreatePaymentIntent = () => {
  return useMutation({
    mutationFn: createPaymentIntent,
    onError: (error) => {
      console.error('Error creating payment intent:', error);
    },
    onSuccess: (data) => {
      console.log('PaymentIntent created successfully');
    },
  });
};